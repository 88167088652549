.cmp-navigation-container {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 100%;
  }

  .navigationteaser {
    padding: var(--spacing-md);
    float: left;
  }

  /* 1 items in a row */
  &--column-template-1 .navigationteaser {
    width: 100%;
  }

  /* 2 items in a row */
  &--column-template-2 .navigationteaser {
    width: 50%;
  }

  /* 3 items in a row */
  &--column-template-3 .navigationteaser {
    width: 33.333%;
  }

  /* 4 items in row */
  &--column-template-4 .navigationteaser {
    width: 25%;
  }

  &--column-template-2,
  &--column-template-3,
  &--column-template-4 {
    .navigationteaser {
      @media #{$mq-2} {
        width: 50%;
      }

      @media #{$mq-1} {
        width: 100%;
      }
    }
  }
}
