@media (--breakpoint-down-md) {
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline-mini .teaser,
  .cmp-teasercontainer.cmp-teasercontainer--image-and-headline-mini .basicpage {
    margin-bottom: var(--spacing-xs);
  }
}

.cmp-teasercontainer--image-and-headline-mini {
  .cmp-teaser {
    flex-direction: row;

    &:hover .cmp-teaser__title-text {
      text-decoration: underline;
      color: var(--color-primary-700);
    }

    &:active .cmp-teaser__title-text {
      text-decoration: underline;
      color: var(--color-primary-800);
    }
  }

  .cmp-teaser__image {
    width: 20%;
    aspect-ratio: 1;
  }

  .cmp-teaser__content {
    padding: 0 var(--spacing-md);
    width: 70%;
  }

  .cmp-teaser__content-link {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .cmp-teaser__tag,
  .cmp-teaser__description {
    display: none;
  }

  .cmp-teaser__title {
    @include bold-lg();

    color: var(--color-primary-500);
    margin-top: 0;

    .cmp-teaser__title-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: unset;
    }
  }

  .cmp-teaser__action-container {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
  }

  .cmp-teaser__action-link {
    color: transparent;
    transform: initial;
  }

  .cmp-teaser__title-link::after,
  .cmp-teaser__action-link:first-child::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@include remove-grid-content('.teasercontainer');

.teasercontainer {
  &.cmp-teasercontainer--image-and-headline-mini {
    .cmp-teaser__title {
      display: inline-flex;
      align-items: center;
    }

    .cmp-button__icon--keyboard_arrow_right {
      display: inline-block;
      margin: 0;
    }
  }
}
