@import '@react/common';

.cmp-comment-replies {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-xs);
  border-top: var(--spacing-xxxs) solid var(--color-gray-100);
  padding-left: 0;
  margin: var(--spacing-xxs) 0 0;

  &__toggle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &-text {
      color: var(--color-primary-500);

      @include regular-md;
    }
  }

  &__toggle .cmp-button__icon {
    @include regular-xl;

    margin-right: var(--spacing-xs);
  }
}
