/**
 * Library -Component
 */
.abbDsWrapper .abbDsContainer {
  overflow: hidden;
}

@media (max-width: 40rem) {
  .abbDsWrapper .abbDsContainer .dsColumnView {
    height: auto;
  }

  /* stylelint-disable */
  .abbDsWrapper .abbDsContainer .dsColumnView .dsMobileWrapper .dsColumn {
    height: auto;
    position: relative;
  }

  .abbDsWrapper {
    overflow-x: hidden;
  }

  /* stylelint-enable */
}
