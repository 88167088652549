@mixin trapezeoid($backgroundColor) {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: -50%;
    right: 0%;
    bottom: 0;
    background-color: $backgroundColor;
    transform: skew(-35.4deg) translate3d(0, 0, 6.25rem); /* Translate3d here compensates for z-index issues in Safari */
    z-index: -1;

    @media (--breakpoint-down-sm) {
      width: 155%;
      left: -60%;
    }
  }
}

@mixin brandingPattern() {
  /**
 * This mixin is to be applied to a child of the box that we want to apply the branding pattern to.
 *
 * Example:
 *
 * <style>
 *  .my-component__branding-pattern {
 *    @include brandingPattern;
 *  }
 * </style>
 *
 * <div class="my-component">
 *   <div class="my-component__branding-pattern my-component__branding-pattern--tripleRight"></div>
 *   <div>
 *     Other box content
 *   </div>
 *   <div>
 *     More box content
 *   </div>
 * </div>
 *
 *
 */
  position: absolute;
  top: var(--spacing-5xl);
  max-width: 66%;
  width: 100%;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: right;
  }

  &--tripleRight {
    height: 35%;
    max-height: toRem(288px);
    right: 0;

    &::before {
      background-image: url('/src/main/webpack/resources/brandPatternTripleRight.svg');
    }

    @media (--breakpoint-down-md) {
      @include singleBrandingPattern;

      &::before {
        background-image: url('/src/main/webpack/resources/brandPatternRight.svg');
      }
    }
  }

  &--singleRight {
    @include singleBrandingPattern;

    right: 0;

    &::before {
      background-image: url('/src/main/webpack/resources/brandPatternRight.svg');
    }
  }

  &--singleLeft {
    @include singleBrandingPattern;

    left: 0;

    &::before {
      background-image: url('/src/main/webpack/resources/brandPatternLeft.svg');
      background-position: left;
    }
  }

  @media (--breakpoint-down-sm) {
    height: var(--spacing-xxxl);
    top: var(--spacing-xxxl);
  }
}

@mixin singleBrandingPattern() {
  height: 15%;
  max-height: toRem(96px);
}

@mixin setLineClamp($lineNumber) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineNumber;
  line-clamp: $lineNumber;
  overflow: hidden;
}

.trapezoid-tinted {
  @include trapezeoid(rgb(0 0 0 / 50%));
}

.trapezoid-red {
  @include trapezeoid(var(--color-primary-500));
}
