@import '@site/styles/settings/functions.scss';

.cmp-teams-notifications {
  &.teams-notifications-in-list {
    padding-bottom: var(--spacing-lg);
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;

    &.active-popup {
      & .teams-notification__content {
        opacity: 1;
        max-height: fit-content;
        padding: var(--spacing-md);

        > * {
          height: 100%;
          width: 100%;
          animation-name: width-height;
          animation-duration: 0.6s, 1s;
        }
      }
    }

    & .teams-notifications__title {
      background: var(--color-teams-450);
      max-width: fit-content;
      color: var(--color-white);
      padding: 0.25rem 0.375rem;
      cursor: pointer;

      &__text {
        font-size: 0.75rem;
        margin: 0 0 0 0.375rem;
      }

      & .teams-notifications__title__icon {
        color: var(--color-white);
      }
    }

    & .teams-notification__content {
      opacity: 0;
      position: absolute;
      margin-top: var(--spacing-md);
      border: var(--border-xs) solid var(--color-gray-300);
      text-decoration: none;
      border-radius: var(--border-s);
      display: flex;
      flex-direction: column;
      background: var(--color-white);
      box-shadow: var(--box-shadow);
      top: 68%;
      transition: opacity 0.3s ease-in;
      z-index: 10;
      min-width: max-content;

      > * {
        height: 0;
        width: 0;
        overflow: hidden;
        transition-delay: 0.3s;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 1.125rem solid transparent;
        border-bottom-color: var(--color-black);
        opacity: 0.2;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: toRem(2px);
        bottom: 100%;
        width: 0;
        height: 0;
        border: 1rem solid transparent;
        border-bottom-color: var(--color-white);
      }

      & .teams-notification__content__title {
        margin-top: 0.5rem;
        margin-bottom: var(--spacing-xs);
      }

      & .icon-close {
        text-align: right;

        & a {
          margin: 0;
        }
      }
    }

    & .teams-notifications__channels {
      flex-direction: column;
      margin: 0 0 var(--spacing-lg) 0;
    }

    & .teams-notifications__channels__channel {
      background-color: var(--color-white);
      color: var(--color-primary-500);
      font-size: 1rem;
      padding: var(--spacing-xxs) 0;

      & a {
        display: flex;

        &::before {
          font-family: 'custom_materialicons', sans-serif;
          content: var(--icon-keyboard_arrow_right);
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }

  @keyframes width-height {
    100% {
      height: 100%;
      width: 100%;
    }

    10% {
      height: 100%;
      width: 100%;
    }

    0% {
      height: 0;
      width: 0;
    }
  }
}
