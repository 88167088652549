/* stylelint-disable */
/* !************************************************************************
*
* ADOBE SYSTEMS INCORPORATED
*  Copyright 2013 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  Adobe permits you to use, modify, and distribute this file in
* accordance with the terms of the Adobe license agreement accompanying it.
* If you have received this file from a source other than Adobe, then your
* use, modification, or distribution of it requires the prior written
* permission of Adobe.
**************************************************************************/
/* .container .s7videoviewer {
	width:100%;
	height:100%;
}
.container .s7videoviewer .s7videoplayer {
	position: absolute;
	top: 0rem;
	left: 0rem;
}

.s7tooltip {
	-moz-border-radius: 0.1875rem 0.1875rem 0.1875rem 0.1875rem;
	-webkit-border-radius: 0.1875rem 0.1875rem 0.1875rem 0.1875rem;
	border-radius: 0.1875rem 0.1875rem 0.1875rem 0.1875rem;
	border-color:#999999;
	background-color: #000000;
	color: #FFFFFF;
	font-family:Arial, Helvetica, sans-serif;
	font-size:0.6875rem;
} */

/************************************************************************   */
/* VideoControlBar - common styles */
/* .container .s7videoviewer .s7controlbar {
	position: absolute;
	bottom: 0rem;
	left: 0;
	height: 2.25rem;
	background-image:url('Dot0x000000opacity75.png');
	background-color: transparent;
} */

/* VideoControlBar - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7controlbar {
	height: 2.25rem;
} */

/* VideoControlBar - touch device size and art */
.container .s7videoviewer.s7touchinput .s7controlbar {
  height: 2.25rem;
}

/************************************************************************   */
/* PlayPauseButton - common styles */
/* .container .s7videoviewer .s7playpausebutton {
	position:absolute;
	background-size:21rem;
} */

/* .container .s7videoviewer .s7playpausebutton[selected][state],.container .s7videoviewer .s7playpausebutton[selected][state][replay]{
	background-image: url('PlayPauseButton_sprite.png');
} */

/* PlayPauseButton - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7playpausebutton {
	top:var(--spacing-xxs);
	left:var(--spacing-xxs);
	width:1.75rem;
	height:1.75rem;
}
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][state='up'] { background-position: -1.75rem -75.25rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][state='over'] { background-position: -0rem -75.25rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][state='down'] { background-position: -1.75rem 73.5rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][state='disabled'] { background-position: -0rem -73.5rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='false'][state='up'] { background-position: -1.75rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='false'][state='over'] { background-position: -0rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='false'][state='down'] { background-position: -1.75rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='false'][state='disabled'] { background-position: -0rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][replay='true'][state='up'] { background-position: -1.75rem -78.75rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][replay='true'][state='over'] { background-position: -0rem -78.75rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][replay='true'][state='down'] { background-position: -1.75rem -77rem; }
.container .s7videoviewer.s7mouseinput .s7playpausebutton[selected='true'][replay='true'][state='disabled'] { background-position: -0rem -77rem; } */

/* PlayPauseButton - touch device size and art */
.container .s7videoviewer.s7touchinput .s7playpausebutton {
  top: var(--spacing-xxs);
  left: var(--spacing-xxs);
  width: 1.75rem;
  height: 1.75rem;
}

.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='up'] {
  background-position: -1.75rem -75.25rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='over'] {
  background-position: 0 -75.25rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='down'] {
  background-position: -1.75rem 73.5rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='disabled'] {
  background-position: 0 -73.5rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='up'] {
  background-position: -1.75rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='over'] {
  background-position: 0 -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='down'] {
  background-position: -1.75rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='disabled'] {
  background-position: 0 -70rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='up'] {
  background-position: -1.75rem -78.75rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='over'] {
  background-position: 0 -78.75rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='down'] {
  background-position: -1.75rem -77rem;
}
.container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='disabled'] {
  background-position: 0 -77rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .container .s7videoviewer.s7touchinput .s7playpausebutton {
    background-size: 21rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='up'] {
    background-position: -1.75rem -75.25rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='over'] {
    background-position: 0 -75.25rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='down'] {
    background-position: -1.75rem 73.5rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][state='disabled'] {
    background-position: 0 -73.5rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='up'] {
    background-position: -1.75rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='over'] {
    background-position: 0 -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='down'] {
    background-position: -1.75rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='false'][state='disabled'] {
    background-position: 0 -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='up'] {
    background-position: -1.75rem -78.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='over'] {
    background-position: 0 -78.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='down'] {
    background-position: -1.75rem -77rem;
  }
  .container .s7videoviewer.s7touchinput .s7playpausebutton[selected='true'][replay='true'][state='disabled'] {
    background-position: 0 -77rem;
  }
}

/************************************************************************   */
/* FullScreenButton - common styles */
/* .container .s7videoviewer .s7fullscreenbutton {
	background-size:7rem;
}

.container .s7videoviewer .s7fullscreenbutton[state][selected]{
	background-image: url('FullScreenButton_dark_sprite.png');
} */

/* FullScreenButton - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7fullscreenbutton {
	top:var(--spacing-xxs);
	right:0.3125rem;
	width:1.75rem;
	height:1.75rem;
}
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='true'][state='up'] { background-position: -5.25rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='true'][state='over'] { background-position: -3.5rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='true'][state='down'] { background-position: -1.75rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='true'][state='disabled'] { background-position: -0rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='false'][state='up'] { background-position: -5.25rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='false'][state='over'] { background-position: -3.5rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='false'][state='down'] { background-position: -1.75rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7fullscreenbutton[selected='false'][state='disabled'] { background-position: -0rem -70rem; } */

/* FullScreenButton - touch device size and art */
.container .s7videoviewer.s7touchinput .s7fullscreenbutton {
  top: var(--spacing-xxs);
  right: 0.3125rem;
  width: 1.75rem;
  height: 1.75rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='up'] {
  background-position: -5.25rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='over'] {
  background-position: -3.5rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='down'] {
  background-position: -1.75rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='disabled'] {
  background-position: 0 -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='up'] {
  background-position: -5.25rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='over'] {
  background-position: -3.5rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='down'] {
  background-position: -1.75rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='disabled'] {
  background-position: 0 -70rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton {
    background-size: 7rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='up'] {
    background-position: -5.25rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='over'] {
    background-position: -3.5rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='down'] {
    background-position: -1.75rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='true'][state='disabled'] {
    background-position: 0 -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='up'] {
    background-position: -5.25rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='over'] {
    background-position: -3.5rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='down'] {
    background-position: -1.75rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7fullscreenbutton[selected='false'][state='disabled'] {
    background-position: 0 -70rem;
  }
}

/************************************************************************   */
/* ClosedCaptionButton - common styles */
/* .container .s7videoviewer .s7closedcaptionbutton  {
	background-size:7rem;
} */

/* .container .s7videoviewer .s7closedcaptionbutton[state][selected] {
	background-image: url('ClosedCaptionButton_sprite.png');
} */

/* ClosedCaptionButton - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7closedcaptionbutton {
	top:var(--spacing-xxs);
	right:4.25rem;
	width:1.75rem;
	height:1.75rem;
}
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='true'][state='up'] { background-position: -5.25rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='true'][state='over'] { background-position: -3.5rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='true'][state='down'] { background-position: -1.75rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='true'][state='disabled'] { background-position: -0rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='false'][state='up'] { background-position: -0rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='false'][state='over'] { background-position: -3.5rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='false'][state='down'] { background-position: -1.75rem -35rem; }
.container .s7videoviewer.s7mouseinput .s7closedcaptionbutton[selected='false'][state='disabled'] { background-position: -0rem -35rem; } */

/* ClosedCaptionButton - touch device size and art */
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton {
  top: var(--spacing-xxs);
  right: 4.25rem;
  width: 1.75rem;
  height: 1.75rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='up'] {
  background-position: -5.25rem -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='over'] {
  background-position: -3.5rem -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='down'] {
  background-position: -1.75rem -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='disabled'] {
  background-position: 0 -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='up'] {
  background-position: 0 -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='over'] {
  background-position: -3.5rem -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='down'] {
  background-position: -1.75rem -35rem;
}
.container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='disabled'] {
  background-position: 0 -35rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton {
    background-size: 3.5rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='up'] {
    background-position: -5.25rem -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='over'] {
    background-position: -3.5rem -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='down'] {
    background-position: -1.75rem -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='true'][state='disabled'] {
    background-position: 0 -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='up'] {
    background-position: 0 -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='over'] {
    background-position: -3.5rem -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='down'] {
    background-position: -1.75rem -35rem;
  }
  .container .s7videoviewer.s7touchinput .s7closedcaptionbutton[selected='false'][state='disabled'] {
    background-position: 0 -35rem;
  }
}

/************************************************************************   */
/* VideoScrubber - common styles */
/* .container .s7videoviewer .s7videoscrubber  {
	position:absolute;
}
.container .s7videoviewer .s7videoscrubber .s7videotime, .container .s7videoviewer .s7videoscrubber .s7navigation[state], .container .s7videoviewer .s7videoscrubber .s7knob {
    background-image: url('VideoScrubber_sprite.png');
    background-size: 3.75rem;
} */
/* .container .s7videoviewer .s7videoscrubber .s7videotime {
	position:absolute;
	font-family:Arial, Helvetica, sans-serif;
	font-size:0.625rem;
	width:3.625rem;
	padding:0rem;
	background-position: -0rem -18rem;
	align:center;
	text-align:center;
	vertical-align:middle; */
/* centers the text vertically in the bubble */
/* line-height:var(--spacing-lg);
	color:#ffffff;
} */
/* .container .s7videoviewer .s7videoscrubber .s7track {
	position:absolute;
	top:0rem;
	left:0rem;
	height:var(--spacing-xs);
	background-color:#555555;
}
.container .s7videoviewer .s7videoscrubber .s7trackloaded {
	position:absolute;
	top:0rem;
	left:0rem;
	height:var(--spacing-xs);
	background-color:#666666;
}
.container .s7videoviewer .s7videoscrubber .s7trackplayed {
	position:absolute;
	top:0rem;
	left:0rem;
	height:var(--spacing-xs);
	background-color:#999999;
}
.container .s7videoviewer .s7videoscrubber .s7navigation {
	position:absolute;
	top:0rem;
	height:var(--spacing-xs);
} */

/* .container .s7videoviewer .s7videoscrubber .s7chapter {
	max-width:14.6875rem;
	bottom:var(--spacing-xs);
}
.container .s7videoviewer .s7videoscrubber .s7chapter .s7header {
	height:1.375rem;
	background-color:rgb(51,51,51,0.8);
}
.container .s7videoviewer .s7videoscrubber .s7chapter .s7header .s7starttime {
	font-family:Verdana, Arial, Helvetica, sans-serif;
	font-size:0.625rem;
	font-weight:normal;
	color:#dddddd;
	padding-right: 0.625rem;
}
.container .s7videoviewer .s7videoscrubber .s7chapter .s7header .s7title {
	font-family:Verdana, Arial, Helvetica, sans-serif;
	font-size:0.625rem;
	font-weight:bold;
	color:#ffffff;
}
.container .s7videoviewer .s7videoscrubber .s7chapter .s7description {
	font-family:Verdana, Arial, Helvetica, sans-serif;
	font-size:0.6875rem;
	font-weight:normal;
	background-color:rgba(221,221,221,0.9);
	padding:var(--spacing-sm) var(--spacing-sm) 0.5625rem var(--spacing-sm);
	line-height:0.9375rem;
}
.container .s7videoviewer .s7videoscrubber .s7knob {
	position:absolute;
	width:0.875rem;
	height:var(--spacing-lg);
	top:calc(-1 * var(--spacing-xs));
} */

/* VideoScrubber - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7videoscrubber  {
	top:0.875rem;
	left:var(--spacing-xxl);
	width:21.25rem;
	height:var(--spacing-xs);
}
.container .s7videoviewer.s7mouseinput .s7videoscrubber .s7videotime {
	height:2.1875rem;
}
.container .s7videoviewer.s7mouseinput .s7videoscrubber .s7knob {
	left:-0.1875rem;
	background-position: -0rem -24.3125rem;
}
.container .s7videoviewer.s7mouseinput .s7videoscrubber .s7navigation {
	width:0.3125rem;
}
.container .s7videoviewer.s7mouseinput .s7videoscrubber .s7navigation[state='default'] { background-position: -1.75rem -24.3125rem; }
.container .s7videoviewer.s7mouseinput .s7videoscrubber .s7navigation[state='over'] { background-position: -2.0625rem -24.3125rem; } */

/* VideoScrubber - touch device size and art */
.container .s7videoviewer.s7touchinput .s7videoscrubber {
  top: 0.875rem;
  left: var(--spacing-xxl);
  width: 21.25rem;
  height: var(--spacing-xs);
}

.container .s7videoviewer.s7touchinput .s7videoscrubber .s7videotime {
  /* height:1.8125rem; Make the height taller than the image so it floats above the scrubber knob by a few px */
  height: 2.1875rem;
}

.container .s7videoviewer.s7touchinput .s7videoscrubber .s7knob {
  left: -0.1875rem;
  background-position: 0 -24.3125rem;
}

.container .s7videoviewer.s7touchinput .s7videoscrubber .s7navigation {
  width: 0.3125rem;
  /* width:1.875rem;
	height:4.5rem;
	margin:calc( -1 * var(--spacing-xl)) 0rem 0rem 0rem; */
}
.container .s7videoviewer.s7touchinput .s7videoscrubber .s7navigation[state='default'] {
  background-position: -1.75rem -24.3125rem;
}
.container .s7videoviewer.s7touchinput .s7videoscrubber .s7navigation[state='over'] {
  background-position: -2.0625rem -24.3125rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .container .s7videoviewer.s7touchinput .s7videoscrubber .s7navigation {
    background-size: 3.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7videoscrubber .s7navigation[state='default'] {
    background-position: -1.75rem -24.3125rem;
  }
  .container .s7videoviewer.s7touchinput .s7videoscrubber .s7navigation[state='over'] {
    background-position: -2.0625rem -24.3125rem;
  }
}

/************************************************************************   */
/* VideoTime - common styles */
/* .container .s7videoviewer .s7videotime {
	position:absolute;
	font-family:Arial, Helvetica, sans-serif;
	color:#999999;
	line-height: normal;		 */
/* Need to add an explicit width to enable scrubber resizing due to getComputedStyle not working in IE8 (IE uses currentStyle, which returns "auto" and breaks our layout math) */
/* Info on this hack here: http://webdesignerwall.com/tutorials/css-specific-for-internet-explorer */
/* width:3.75rem\9;
} */

/* VideoTime - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7videotime {
	top:var(--spacing-xs);
	right:6.125rem;
	font-size:0.625rem;
} */

/* VideoTime - touch device size and art */
.container .s7videoviewer.s7touchinput .s7videotime {
  @include regular-xs();

  top: var(--spacing-xs);
  right: 6.125rem;
}

/************************************************************************   */
/* Caption - common styles */
/* .container .s7videoviewer .s7videoplayer .s7caption {
	background-color: rgba(0,0,0,0.75);
	color: #e6e6e6;
	font-weight: normal;
	font-size: 0.875rem;
	font-family: Arial,Helvetica,sans-serif;
} */

/************************************************************************   */
/* MutableVolume - common styles */
/* .container .s7videoviewer .s7mutablevolume {
	position:absolute;
}
.container .s7videoviewer .s7mutablevolume .s7mutebutton, .container .s7videoviewer .s7mutablevolume .s7verticalvolume .s7knob {
    background-size:7.5rem;
}

.container .s7videoviewer .s7mutablevolume .s7mutebutton[state][selected], .container .s7videoviewer .s7mutablevolume .s7verticalvolume .s7knob {
	background-image: url('MutableVolume_sprite.png');
}

.container .s7videoviewer .s7mutablevolume .s7verticalvolume {
	position:absolute;
	background-color:#dddddd;
	left:0rem;
}
.container .s7videoviewer .s7mutablevolume .s7verticalvolume .s7track {
	position:absolute;
	top:0.6875rem;
	width:var(--spacing-xs);
	height:3.8125rem;
	background-color:#666666;
}
.container .s7videoviewer .s7mutablevolume .s7verticalvolume .s7filledtrack {
	position:absolute;
	width:var(--spacing-xs);
	height:3.8125rem;
	background-color:#ababab;
}
.container .s7videoviewer .s7mutablevolume .s7verticalvolume .s7knob {
	top:0.6875rem;
	left:var(--spacing-xs);
	position:absolute;
}	 */

/* MutableVolume - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7mutablevolume {
	top:var(--spacing-xxs);
	right:2.1875rem;
	width:1.875rem;
	height:1.75rem;
} */

/* .container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='true'][state='up'] { background-position: -5.625rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='true'][state='over'] { background-position: -3.75rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='true'][state='down'] { background-position: -1.875rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='true'][state='disabled'] { background-position: -0rem -71.75rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='false'][state='up'] { background-position: -5.625rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='false'][state='over'] { background-position: -3.75rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='false'][state='down'] { background-position: -1.875rem -70rem; }
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7mutebutton[selected='false'][state='disabled'] { background-position: -0rem -70rem; }

.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7verticalvolume {
	width:1.875rem;
	height:5.1875rem;
}
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7verticalvolume .s7track {
	left:0.6875rem;
}
.container .s7videoviewer.s7mouseinput .s7mutablevolume .s7verticalvolume .s7knob {
	width:0.875rem;
	height:var(--spacing-xs);
	background-position: calc(-1 * var(--spacing-lg)) -73.5rem;
}	 */

/* MutableVolume - touch device size and art */
.container .s7videoviewer.s7touchinput .s7mutablevolume {
  top: var(--spacing-xxs);
  right: 2.1875rem;
  width: 1.875rem;
  height: 1.75rem;
}

.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='up'] {
  background-position: -5.625rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='over'] {
  background-position: -3.75rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='down'] {
  background-position: -1.875rem -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='disabled'] {
  background-position: 0 -71.75rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='up'] {
  background-position: -5.625rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='over'] {
  background-position: -3.75rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='down'] {
  background-position: -1.875rem -70rem;
}
.container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='disabled'] {
  background-position: 0 -70rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .container .s7videoviewer.s7touchinput .s7mutablevolume,
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton {
    background-size: 7.5rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='up'] {
    background-position: -5.625rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='over'] {
    background-position: -3.75rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='down'] {
    background-position: -1.875rem -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='true'][state='disabled'] {
    background-position: 0 -71.75rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='up'] {
    background-position: -5.625rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='over'] {
    background-position: -3.75rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='down'] {
    background-position: -1.875rem -70rem;
  }
  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7mutebutton[selected='false'][state='disabled'] {
    background-position: 0 -70rem;
  }

  .container .s7videoviewer.s7touchinput .s7mutablevolume .s7verticalvolume .s7knob {
    left: var(--spacing-xs);
  }
}

.container .s7videoviewer.s7touchinput .s7mutablevolume .s7verticalvolume {
  width: 1.875rem;
  height: 5.1875rem;
}

.container .s7videoviewer.s7touchinput .s7mutablevolume .s7verticalvolume .s7track {
  left: 0.6875rem;
}

.container .s7videoviewer.s7touchinput .s7mutablevolume .s7verticalvolume .s7knob {
  left: var(--spacing-xs);
  width: 0.875rem;
  height: var(--spacing-xs);
  background-position: calc(-1 * var(--spacing-lg)) -73.5rem;
}

/************************************************************************   */
/* IconEffect - common styles */
/* .container .s7videoviewer .s7videoplayer .s7iconeffect {
	width:5rem;
	height:5rem;
	background-size: 10rem;
}
.container .s7videoviewer .s7videoplayer .s7iconeffect[state] {
	background-image: url('IconEffect_video_sprite.png');
} */

/* IconEffect - desktop size and art */
/* .container .s7videoviewer.s7mouseinput .s7videoplayer .s7iconeffect[state='play']{ background-position: -0rem -20rem; }
.container .s7videoviewer.s7mouseinput .s7videoplayer .s7iconeffect[state='replay']{ background-position: -5rem -20rem; } */

/* IconEffect - touch device size and art */
.container .s7videoviewer.s7touchinput .s7videoplayer .s7iconeffect[state='play'] {
  background-position: 0 -20rem;
}
.container .s7videoviewer.s7touchinput .s7videoplayer .s7iconeffect[state='replay'] {
  background-position: -5rem -20rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .container .s7videoviewer.c .s7videoplayer .s7iconeffect {
    background-size: 10rem;
  }
  .container .s7videoviewer.s7touchinput .s7videoplayer .s7iconeffect[state='play'] {
    background-position: 0 -20rem;
  }
  .container .s7videoviewer.s7touchinput .s7videoplayer .s7iconeffect[state='replay'] {
    background-position: -5rem -20rem;
  }
}

/************************************************************************   */
/* SocialShare - disable default styles */
/* .container .s7videoviewer .s7socialshare {
	display: none;
} */

.cmp-dynamicmedia {
  position: relative;

  .cmp-caption__popup {
    display: none;
    @include bold-md;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-white);
    color: var(--color-gray-900);
    padding: var(--spacing-md);
    z-index: 1;
  }

  &:hover .cmp-caption__popup {
    display: block;
  }
}
