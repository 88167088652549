.cmp-the-wall-tab-list-item {
  padding: var(--spacing-xs) var(--spacing-lg);
  border: var(--spacing-xxxs) solid var(--color-primary-500);
  color: var(--color-primary-500);
  background-color: var(--color-white);
  list-style-type: none;
  cursor: pointer;
  min-width: fit-content;
  transition: all 0.1s ease-out;

  &:focus {
    border: 3px solid var(--color-focus-500);
    border-radius: 2px;
    color: var(--color-primary-700);
  }

  &:hover {
    color: var(--color-primary-700);
    background-color: var(--color-white);
    border: var(--spacing-xxxs) solid var(--color-primary-700);
  }

  &--active {
    color: var(--color-white);
    background: var(--color-primary-500);
    border: var(--spacing-xxxs) solid var(--color-primary-500);

    &:hover {
      color: var(--color-white);
      background: var(--color-primary-500);
      border: var(--spacing-xxxs) solid var(--color-primary-500);
    }
  }
}
