.cmp-pagestage-product {
  &__content {
    display: flex;
    column-gap: var(--spacing-xl);
  }

  &__container-heading {
    margin-bottom: 3.5rem;
  }

  &__container-description {
    padding-right: var(--spacing-lg);

    &::after {
      content: '';
      display: block;
      padding-top: var(--spacing-4xl);
    }

    a {
      color: var(--color-primary-500);
      text-decoration: underline;

      &:hover {
        color: var(--color-primary-700);
      }
    }
  }

  &__container-text {
    /* 6 columns in 12 columns container */
    flex: 50%;
  }

  &__container-image {
    /* 6 columns in 12 columns container */
    flex: 50%;
  }

  &__container-social {
    flex-basis: 100%;
  }

  &__social {
    float: right;
    margin: var(--spacing-lg) 0;
  }

  @media (--breakpoint-down-md) {
    &__content {
      flex-direction: column;
    }

    &__container-description::after {
      padding-top: var(--spacing-xl);
    }

    &__container-image {
      position: relative;
      min-width: 20rem;
      margin-bottom: var(--spacing-xl);
    }

    &__container-text {
      padding: 0;
      flex-basis: 100%;
      order: 3;
    }
  }

  @media (--breakpoint-up-md) {
    &__container-image {
      overflow: hidden;
    }
  }

  @media (--breakpoint-down-sm) {
    margin-bottom: 0;

    &__container-description {
      padding: 0;
    }

    &__container-image {
      margin-top: var(--spacing-md);
      padding: 0;
      flex-basis: 100%;
      order: 1;
    }

    &__container-social {
      order: 2;
    }
  }

  &__content-wrapper--is-wide-image-left,
  &__content-wrapper--is-centered-image-left {
    .cmp-pagestage-product__container-text {
      order: 2;
    }

    .cmp-pagestage-product__container-image {
      order: 1;
    }
  }

  &__content-wrapper--is-wide-image-right,
  &__content-wrapper--is-centered-image-right {
    .cmp-pagestage-product__container-text {
      order: 1;
    }

    .cmp-pagestage-product__container-image {
      order: 2;
    }
  }

  &__content-wrapper--is-centered-image-left,
  &__content-wrapper--is-centered-image-right {
    .cmp-pagestage-product__container-text {
      /* 4 columns in 10 columns container */
      flex: 40%;
    }

    .cmp-pagestage-product__container-image {
      /* 6 columns in 10 columns container */
      flex: 60%;
    }
  }

  &__content-wrapper--is-wide-image-left,
  &__content-wrapper--is-wide-image-right,
  &__content-wrapper--is-centered-image-left,
  &__content-wrapper--is-centered-image-right {
    .cmp-pagestage-product__container-heading {
      margin-bottom: 0;
    }

    &:not(:has(img)),
    &:has(.cmp-image--product-image--hidden) {
      column-gap: 0;

      .cmp-pagestage-product__container-text {
        flex: 100%;
      }

      .cmp-pagestage-product__container-image {
        flex: 0%;
      }
    }

    .cmp-pagestage-product__container-image-wrapper:has(.cmp-image--product-image--small img) {
      display: flex;
      justify-content: flex-end;

      .cmp-image--product-image--small {
        flex-basis: 75%;
        width: unset;
        float: unset;
      }

      &.cmp-image-carousel__fullscreen .cmp-image--product-image--small {
        flex-basis: 100%;
      }
    }

    .cmp-pagestage-product__container-preline,
    .cmp-pagestage-product__container-preline p {
      @include bold-lg;

      text-transform: uppercase;
    }
  }

  &__container-image-wrapper {
    position: relative;
    background-color: var(--color-white);
    overflow: hidden;

    .cmp-image--product-image,
    .cmp-responsive-image,
    .cmp-image--only-image-source {
      height: 100%;

      img {
        cursor: zoom-in;
      }

      .cursor-default {
        cursor: default;
      }
    }

    &.cmp-image-carousel__fullscreen {
      .cmp-responsive-image,
      .cmp-image--only-image-source {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: fit-content;
          height: auto;

          @media (--breakpoint-up-md) {
            min-height: 70vh;
          }
        }
      }
    }

    .cmp-responsive-image {
      img {
        aspect-ratio: 4 / 3;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }
}
