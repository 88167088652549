/* Color- Policies */
.cmp-banner-cta--color-black .cmp-banner-cta__content-inner {
  color: var(--color-black);
}

/* left aligned with opacity */
.cmp-banner-cta--bg-opacity .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape) {
  background-color: var(--color-black-transparent-lighter);
  height: 100%;
  left: 0;
  width: 100%;
}

.cmp-banner-cta--bg-opacity .cmp-banner-cta__content.cmp-banner-cta__content--left-shape,
.cmp-banner-cta--bg-opacity .cmp-banner-cta__content.cmp-banner-cta__content--darkTrapezoid {
  background-color: var(--color-black-transparent-lighter);
}

.cmp-banner-cta--bg-opacity .cmp-banner-cta__arrow {
  border-top-color: var(--color-black-transparent-lighter);
}

.cmp-banner-cta--bg-opacity .cmp-banner-cta__arrow::before {
  border-left-color: var(--color-black-transparent-lighter);
  border-right-color: var(--color-black-transparent-lighter);
}

.cmp-banner-cta--bg-opacity .cmp-banner-cta__content--right .cmp-banner-cta__content-inner {
  margin-left: auto;
}

/* Only-desktop */
@media (--breakpoint-up-sm) {
  .cmp-banner-cta--bg-opacity .cmp-banner-cta__content--left .cmp-banner-cta__content-inner,
  .cmp-banner-cta--bg-opacity .cmp-banner-cta__content--right .cmp-banner-cta__content-inner {
    width: 50%;
  }
}

/* Mobile */
@media (--breakpoint-down-sm) {
  .stage-hero-slider .cmp-banner-cta__inner.cmp-banner-cta---mobile-bg-opacity .cmp-banner-cta__content.cmp-banner-cta__content--side-aligned,
  .stage-hero-slider .cmp-banner-cta__inner.cmp-banner-cta---mobile-bg-opacity .cmp-banner-cta__content.cmp-banner-cta__content--side-aligned.cmp-banner-cta__content--left {
    background: var(--color-black-transparent-lighter);
    height: 100%;
  }

  .stage-hero-slider .cmp-banner-cta__inner.cmp-banner-cta---mobile-bg-opacity .cmp-banner-cta__wrapper-video .cmp-banner-cta__content.cmp-banner-cta__content--side-aligned,
  .stage-hero-slider .cmp-banner-cta__inner.cmp-banner-cta---mobile-bg-opacity .cmp-banner-cta__wrapper-video .cmp-banner-cta__content.cmp-banner-cta__content--side-aligned.cmp-banner-cta__content--left {
    background: transparent;
    color: var(--color-white);
  }
}
