/*
* pagestage large
*/
:root {
  --pagestage-large-height: 37.5rem;
  --gradient-grayscale-l-2-r: linear-gradient(270deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 0%) 0.01%, rgb(0 0 0 / 75%) 103.13%);
}

/* restore pointer events */
.cmp-pagestage-large .cmp-button {
  pointer-events: initial;
}

.cmp-pagestage-large .cmp-container--full-width .cmp-banner-cta__short {
  height: var(--pagestage-large-height);
}

.cmp-pagestage-large .cmp-container--full-width .cmp-banner-cta__short--side-aligned .cmp-responsive-image--eager {
  height: var(--pagestage-large-height);
}

.cmp-pagestage-large .cmp-banner-cta__content--side-aligned .cmp-banner-cta__title .cmp-title__text {
  @include bold-5xl();
}

/* change button color when its on the red background */
.cmp-pagestage-large--left-red-trapezoid .cmp-button--primary-positive .cmp-button {
  background: var(--color-white);
  color: var(--color-primary-500);
}

/* right alignment */
.cmp-pagestage-large .cmp-banner-cta__content--right .cmp-banner-cta__content-inner {
  position: absolute;
  right: 0;
}

/* Content width restriction */
.cmp-pagestage-large .cmp-banner-cta__center .cmp-banner-cta__content-inner,
.cmp-pagestage-large .cmp-banner-cta__content--left-shape .cmp-banner-cta__content-inner {
  width: 60%;
}

.cmp-pagestage-large__container-description::after {
  content: '';
  display: block;
  padding-top: var(--spacing-4xl);
}

/* Tablet portrait up */
@media (--breakpoint-up-sm) {
  /* Content width restriction */
  .cmp-pagestage-large .cmp-banner-cta__content-inner {
    width: 75%;
  }

  /* Gradient */
  .cmp-pagestage-large .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--left {
    background: linear-gradient(to right, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  }

  .cmp-pagestage-large .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--right {
    background: linear-gradient(to left, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  }
}

@media (--breakpoint-up-md) {
  /* Content width restriction */
  .cmp-pagestage-large .cmp-banner-cta__content-inner {
    width: 50%;
  }
}

@media (--breakpoint-down-lg) {
  .cmp-pagestage-large .cmp-container--full-width .cmp-banner-cta__tall--side-aligned .cmp-banner-cta__image {
    height: 100%;
  }
}

@media (--breakpoint-down-md) {
  .cmp-pagestage-large .cmp-banner-cta__content--side-aligned {
    width: 100%;
  }

  .cmp-pagestage-large__container-description::after {
    padding-top: var(--spacing-xl);
  }
}

@media (--breakpoint-down-sm) {
  /* Content width restriction */
  .cmp-pagestage-large .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
    width: 75%;
  }

  .cmp-pagestage-large .cmp-banner-cta__content--side-aligned .cmp-banner-cta__title .cmp-title__text {
    @include bold-3xl();
  }

  .cmp-pagestage-large .cmp-container--full-width .cmp-banner-cta__wrapper {
    height: var(--pagestage-large-height);
  }
}
