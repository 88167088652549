@import '@site/styles/foundations/typography.scss';

.cmp-the-wall {
  overflow: hidden;
  padding: var(--spacing-4xl) 0;
  max-width: calc(100vw - var(--spacing-xl));

  &__title {
    margin: 0 0 var(--spacing-xl);

    @include bold-5xl;
  }
}
