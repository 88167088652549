.cmp-languagenavigation__title {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  text-align: center;
  margin-bottom: var(--spacing-lg);
  margin-top: 0;
}

@media #{$mq-1} {
  .cmp-languagenavigation__title {
    @include bold-3xl();
  }
}

.cmp-languagenavigation__content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media #{$mq-mobile} {
  .cmp-languagenavigation__content {
    flex-direction: column;
    align-items: center;
  }
}

.cmp-languagenavigation .cmp-form-options {
  color: var(--color-gray-900);
  min-width: 12.5rem;
  margin-right: var(--spacing-lg);
  margin-bottom: calc(var(--spacing-xxxs) * -1);
}

@media #{$mq-mobile} {
  .cmp-languagenavigation .cmp-form-options {
    margin-right: 0;
    margin-bottom: var(--spacing-md);
  }

  .cmp-languagenavigation .cmp-form-options .c-custom-select-panel {
    bottom: 100%;
    top: auto;
    box-shadow: none;
    border: var(--spacing-xxxs) solid var(--color-gray-50);
  }
}

@media #{$mq-mobile} and (orientation: portrait) {
  .cmp-languagenavigation .cmp-form-options .c-custom-select-panel {
    max-height: calc(4.5 * 2.5rem); /* 180px (height of 4.5 items in the select) */
  }
}

@media #{$mq-mobile} and (orientation: landscape) {
  .cmp-languagenavigation .cmp-form-options .c-custom-select-panel {
    max-height: calc(2.5 * 2.5rem); /* 100px (height of 2 items in the select) */
  }
}
