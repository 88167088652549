.cmp-teasercontainer--pictogram .cmp-container {
  justify-content: center;
  margin: 0;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--1-columns .cmp-container {
  padding: 0 41.667%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--2-columns .cmp-container {
  padding: 0 33.333%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--3-columns .cmp-container {
  padding: 0 25%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--4-columns .cmp-container {
  padding: 0 16.667%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--5-columns .cmp-container {
  padding: 0 8.333%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--6-columns .cmp-container {
  padding: 0;
}

@media #{$mq-desktop} {
  .cmp-teasercontainer--pictogram.teasercontainer .teaser,
  .cmp-teasercontainer--pictogram.teasercontainer .basicpage {
    margin: 0 0 var(--spacing-lg);
  }
}

@media #{$mq-mobile} {
  .cmp-teasercontainer--pictogram .cmp-teasercontainer .teaser,
  .cmp-teasercontainer--pictogram .cmp-teasercontainer .basicpage {
    margin: 0;
  }
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--1-columns .teaser,
.cmp-teasercontainer--pictogram .cmp-teasercontainer--1-columns .basicpage {
  flex-basis: 100%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--2-columns .teaser,
.cmp-teasercontainer--pictogram .cmp-teasercontainer--2-columns .basicpage {
  flex-basis: 50%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--3-columns .teaser,
.cmp-teasercontainer--pictogram .cmp-teasercontainer--3-columns .basicpage {
  flex-basis: 33.333%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--4-columns .teaser,
.cmp-teasercontainer--pictogram .cmp-teasercontainer--4-columns .basicpage {
  flex-basis: 25%;
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--5-columns .teaser,
.cmp-teasercontainer--pictogram .cmp-teasercontainer--5-columns .basicpage {
  flex-basis: calc(20% - var(--spacing-xs));
  padding: var(--spacing-xxxs);
}

.cmp-teasercontainer--pictogram .cmp-teasercontainer--6-columns .teaser,
.cmp-teasercontainer--pictogram .cmp-teasercontainer--6-columns .basicpage {
  flex-basis: 16.666%;
}

@media #{$mq-mobile} {
  .cmp-teasercontainer--pictogram.teasercontainer .cmp-container {
    padding: 0;
    justify-content: flex-start;
    margin: 0 0 var(--spacing-md);
  }

  .cmp-teasercontainer--pictogram.teasercontainer .cmp-container .aem-Grid.aem-Grid--12 {
    margin: 0 auto;
  }

  .cmp-teasercontainer--pictogram.teasercontainer .teaser,
  .cmp-teasercontainer--pictogram.teasercontainer .basicpage {
    flex-basis: 25%;
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer--pictogram.teasercontainer {
    padding: 0 var(--spacing-xxs);
  }

  .cmp-teasercontainer--pictogram.teasercontainer .cmp-container {
    display: flex;
  }

  .cmp-teasercontainer--pictogram.teasercontainer .teaser,
  .cmp-teasercontainer--pictogram.teasercontainer .basicpage {
    flex-basis: 50%;
  }
}
