.cmp-newsinfo,
.cmp-time-to-read {
  @include regular-md();

  color: var(--color-gray-900);
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.cmp-newsinfo__item:not(:last-child)::after {
  content: '|';
  margin: 0 var(--spacing-xxs);
}
