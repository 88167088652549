/*
* Objects:
* Media object
*/

.o-media {
  align-items: flex-start;
  display: flex;
}

.o-media__left,
.o-media__right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.o-media__content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

/* modifiers */
.o-media--center { align-items: center; }


@media (--breakpoint-down-md) {
  .md\:o-media--stacked {
    display: block;
  }
}

@media (--breakpoint-down-sm) {
  .sm\:o-media--stacked {
    display: block;
  }
}
