.cmp-specialevent {
  &-time {
    padding-bottom: var(--spacing-lg);

    @include bold-xl;
  }

  &-agenda {
    display: flex;
    margin-bottom: var(--spacing-4xl);
    border-left: 0.125rem solid var(--color-primary-500);
    padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-lg) var(--spacing-xl);
    flex-direction: row;
    flex-basis: 100%;
    overflow: hidden;

    @media only screen and (--breakpoint-down-sm) {
      flex-direction: column;
    }

    &__content {
      flex-basis: 60%;

      @media only screen and (--breakpoint-down-sm) {
        flex-basis: 100%;
        padding-right: var(--spacing-md);
      }

      &--lecture-title {
        @include bold-lg;
      }

      &--lecture-description {
        margin-top: var(--spacing-xxs);

        @include regular-lg;

        a {
          color: var(--color-primary-500);
          text-decoration: underline;

          &:hover {
            color: var(--color-primary-700);
          }
        }
      }

      &--lecture-registration-link {
        margin-top: var(--spacing-xl);

        @include button-secondary;
      }

      &--lecture-speakers {
        margin: var(--spacing-lg) 0 var(--spacing-md);

        @media only screen and (--breakpoint-down-sm) {
          margin: var(--spacing-lg) 0;
        }
      }

      &--lecture-speaker {
        display: flex;
        margin-bottom: var(--spacing-xs);

        @include regular-md;

        & a {
          display: inline-flex;
          color: var(--color-primary-500);
          transition: transform 0.2s ease-in-out;

          span {
            animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
            text-decoration: underline;
          }

          &::before {
            font-family: 'custom_materialicons', sans-serif;
            content: var(--icon-keyboard_arrow_right);
            margin-right: var(--spacing-xxs);
            animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
          }

          &::after {
            content: '';
          }

          &:hover {
            color: var(--color-primary-700);

            &::before,
            span {
              animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
            }
          }

          &:active {
            color: var(--color-primary-800);
          }
        }

        &--has-no-link {
          font-style: italic;
          display: inline-flex;
          margin-bottom: var(--spacing-xs);

          @include regular-md;

          &::after {
            content: ',';
            color: var(--color-black);
          }

          &:last-child {
            &::after {
              content: '';
            }
          }
        }
      }
    }
  }
}

.timeslot {
  width: 100%;
}
