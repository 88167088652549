/*
* AEM Component
* Accordion
*/

.cmp-accordion {
  display: flex;
  width: 100%;
  flex-flow: column;
}

.cmp-accordion__item {
  position: relative;
  border-width: var(--spacing-xxxs) var(--spacing-xxxs) var(--spacing-xxxs) 0.375rem;
  border-style: solid;
  border-color: transparent;
  transition: border 0.25s ease-in;
}

.cmp-accordion__item:hover {
  cursor: pointer;
  border-color: var(--color-primary-500);
}

.cmp-accordion__header {
  color: var(--color-primary-500);
}

.cmp-accordion__item:not([data-cmp-expanded]) .cmp-accordion__header {
  position: relative;
}

.cmp-accordion__header .cmp-accordion__button {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-sm);
}

.cmp-accordion__title {
  @include bold-lg;

  width: 98%;
}

.cmp-accordion__icon {
  color: var(--color-black);
}

.cmp-accordion__button--expanded .cmp-accordion__icon.icon-keyboard_arrow_down {
  display: none;
}

.cmp-accordion__icon.icon-keyboard_arrow_up {
  display: none;
}

.cmp-accordion__button--expanded .cmp-accordion__icon.icon-keyboard_arrow_up {
  display: block;
}

.cmp-accordion__panel {
  padding: var(--spacing-lg);
}
