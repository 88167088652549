@import '@react/common.scss';

.cmp-comments__post {
  display: flex;
  flex-flow: column;
  padding: var(--spacing-lg) 0;
  border-top: var(--spacing-xxxs) solid var(--color-gray-100);
  border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
}

.cmp-comments__post-textarea {
  height: toRem(120px);
}

.cmp-comments__post-button {
  margin-right: auto;
  margin-top: var(--spacing-lg);
}
