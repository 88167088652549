.cmp-blogpagestage-container .cmp-blogpagestage__wrapper-image {
  height: 31.25rem;
}

.cmp-blogpagestage__wrapper-image::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, transparent, rgb(0 0 0 / 75%));
}

.cmp-blogpagestage-container .cmp-blogpagestage__image {
  margin-left: auto;
  margin-right: 0;
  width: 100%;
}

.cmp-blogpagestage-container .cmp-blogpagestage__image .cmp-responsive-image.cmp-responsive-image--eager {
  height: 100%;
}

.cmp-blogpagestage-container .cmp-banner-cta .cmp-banner-cta__content-inner .cmp-banner-cta__title {
  padding: 2.375rem 6.375rem 6.375rem 2.375rem;
}

.cmp-blogpagestage-container .cmp-banner-cta__content-inner .cmp-banner-cta__title .cmp-blogpagestage__publish-date {
  line-height: var(--line-height);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.cmp-blogpagestage-container .cmp-banner-cta__content-inner .cmp-banner-cta__title .cmp-title .cmp-title__text {
  @include bold-5xl();

  @media #{$mq-mobile} {
    @include bold-3xl();
  }
}

.cmp-blogpagestage-container .cmp-pagestage-text-only__social-container .cmp-pagestage-text-only__social.cmp-pagestage-text-only__social-full-width {
  padding-top: var(--spacing-xxxl);
}

.cmp-author__wrapper-small {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: var(--spacing-xl) 0;
}

.cmp-author__wrapper-small.cmp-author__wrapper-small--full-width {
  margin-left: var(--spacing-4xl);
}

.cmp-author__wrapper-small .cmp-author__small {
  margin: var(--spacing-xxs) 0.75rem;
}

.cmp-author__image.cmp-author__image-small {
  width: 3.375rem;
  /* Fix for ios */
  height: 3.375rem;
}

.cmp-author__image.cmp-author__image-small .image-wrapper,
.cmp-author__image.cmp-author__image-small .image-wrapper .cmp-responsive-image {
  height: 100%;
}

.cmp-author__wrapper-small .cmp-author__name-wrapper--small {
  margin-left: var(--spacing-sm);
  line-height: var(--line-height);
}

.cmp-blogpagestage-container .cmp-sharing {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: var(--spacing-xxl);
}

.cmp-blogpagestage-container .cmp-sharing .cmp-sharing__title {
  text-align: right;
  padding-right: var(--spacing-sm);
}

.cmp-blogpagestage-wrapper .cmp-bloggpagestage-desc-wrapper {
  padding: 0 0.313rem var(--spacing-xxxl) 0.313rem;
}

@media (--breakpoint-down-xl) {
  .cmp-author__wrapper-small.cmp-author__wrapper-small--full-width {
    margin-left: 1.3rem;
  }
}

@media (--breakpoint-down-md) {
  .cmp-blogpagestage-wrapper .cmp-bloggpagestage-desc-wrapper {
    padding: 0 0 var(--spacing-xxxl) 0;
  }

  .cmp-blogpagestage-container .cmp-sharing {
    padding-right: var(--spacing-md);
  }

  .cmp-author__wrapper-small.cmp-author__wrapper-small--full-width {
    margin-left: 1.8rem;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-author__wrapper-small.cmp-author__wrapper-small--full-width {
    margin-left: 1.3rem;
    padding: var(--spacing-xl) 0 var(--spacing-xxs) 0;
  }

  .cmp-blogpagestage-container .cmp-pagestage-text-only__social-container .cmp-pagestage-text-only__social.cmp-pagestage-text-only__social-full-width {
    padding-top: 0;
  }
}
