@import '@react/common.scss';

.cmp-comment {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-xs);
  border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
  transition: height 0.3s ease-in-out;
  transform-origin: top;
  overflow-x: hidden;

  &__content {
    a {
      color: var(--color-primary-500);

      &:hover,
      &:active {
        color: var(--color-primary-700);
        text-decoration: underline;
      }
    }
  }

  &__content:last-of-type {
    margin-bottom: toRem(16px);
  }

  &--removing {
    height: 0;
  }

  &__header {
    margin: var(--spacing-sm) 0;
    display: flex;
    align-items: center;
  }

  &__author {
    @include bold-lg;

    margin: 0;
  }

  &__date {
    color: var(--color-gray-900);
    padding: 0 var(--spacing-sm);

    @include regular-sm;
  }

  &__editable-content {
    display: flex;
    flex-flow: column;
  }

  &__dialog {
    display: flex;
    flex-flow: column;
    padding-bottom: var(--spacing-sm);

    &__textarea {
      padding: var(--spacing-xxxs) var(--spacing-sm);

      @include regular-md;
    }
  }

  &__controls {
    transition: all 0.2s;
  }
}
