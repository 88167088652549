@include remove-grid-content('.pagestage, .page-stage, .smallstage, .mediumstage');

.pagestage .cmp-responsive-image img,
.pagestage .cmp-responsive-bg-image img {
  object-position: center center;
  max-height: 100%;
}

.pagestage .cmp-video-wrapper {
  overflow: hidden;
}

.cmp-pagestage__dynamicmediaplayer {
  width: 100%;
  max-height: 75vh;
  object-fit: cover;
}

/**
 * Using !important to win over the inline styles injected by 3rd-party video-js
 *
 * DO NOT
 * I repeat
 * DO NOT try to build on top of this
 *
 * !important is the end of cascading, and if you need cascading to do something for you here,
 * you shall re-approach this style override via JavaScript (eg. write .style.* properties after scene7 does) or some other
 * way.
 */

/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-no-qualifying-type */

.cmp-pagestage__dynamicmediaplayer--top {
  object-position: top;
}

.cmp-pagestage__dynamicmediaplayer--middle {
  object-position: center;
}

.cmp-pagestage__dynamicmediaplayer--bottom {
  object-position: bottom;
}

@media (--breakpoint-up-md) {
  .cmp-pagestage__dynamicmediaplayer--top,
  .cmp-pagestage__dynamicmediaplayer--middle,
  .cmp-pagestage__dynamicmediaplayer--bottom {
    height: auto !important;
  }
}

/* stylelint-enable selector-no-qualifying-type */
/* stylelint-enable declaration-no-important */
