.cmp-teasercontainer--pictogram .cmp-teaser {
  justify-content: center;
  z-index: 1;
  background: var(--color-gray-500);
}

.cmp-teasercontainer--pictogram .cmp-teaser:hover {
  background: var(--color-primary-500);
  box-shadow: none;
  transform: scale(1.05);
  z-index: 2;
}

.cmp-teasercontainer--pictogram .cmp-teaser:hover .cmp-teaser__image {
  visibility: hidden;
}

.cmp-teasercontainer--pictogram .cmp-teaser__image .cmp-image::before {
  padding-top: 100%;
}

.cmp-teasercontainer--pictogram .cmp-teaser__content {
  color: var(--color-white);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: unset;
  text-align: center;
}

.cmp-teasercontainer--pictogram.cmp-teasercontainer--image-hidden .cmp-teaser__content {
  flex-direction: column;
}

.cmp-teasercontainer--pictogram .cmp-teaser .cmp-teaser__image + .cmp-teaser__content {
  position: absolute;
  background: linear-gradient(135deg, rgb(0 0 0 / 7.5%) 0%, rgb(0 0 0 / 32.5%) 98.23%);
}

.cmp-teasercontainer--pictogram.cmp-teasercontainer--image-hidden .cmp-teaser .cmp-teaser__image + .cmp-teaser__content {
  position: relative;
}

.cmp-teasercontainer--pictogram .cmp-teaser:hover .cmp-teaser__image + .cmp-teaser__content {
  background: var(--color-primary-500);
}

.cmp-teasercontainer--pictogram .cmp-teaser__title {
  font-size: var(--font-size);
  line-height: var(--line-height-sm);
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  display: block;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.cmp-teasercontainer--pictogram h3.cmp-teaser__title {
  padding-bottom: var(--spacing-sm);
}

.cmp-teasercontainer--pictogram .cmp-teaser__title-icon {
  padding-right: 0;
}

.cmp-teasercontainer--pictogram .cmp-button__icon {
  display: block;
  margin: 0 0 var(--spacing-sm);
  text-align: center;
  height: var(--spacing-xxl);
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight);
}

.cmp-teasercontainer--pictogram .cmp-button__icon::before {
  top: var(--spacing-md);
  position: relative;
}

.cmp-teasercontainer--pictogram .cmp-teaser__tag,
.cmp-teasercontainer--pictogram .cmp-teaser__description,
.cmp-teasercontainer--pictogram .cmp-teaser__action-container {
  display: none;
}
