/**
 * Download -Component
 */

.cmp-download__table {
  border: var(--spacing-xxxs) solid var(--color-gray-100);
  text-align: left;
}

.cmp-download__table-row {
  display: flex;
}

.cmp-download__table-column {
  padding: var(--spacing-md) var(--spacing-lg);
}

.cmp-download__table-row:not(:last-child) {
  border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
}

.cmp-download__table-row:hover {
  outline: var(--color-primary-500) solid var(--spacing-xxxs);
  cursor: pointer;
}

.cmp-download__link .icon-download {
  display: inline-block;
}

.cmp-download__table-row .cmp-download__table-column:first-child {
  position: relative;
}

.cmp-download__table-row .cmp-download__table-column:first-child::before {
  position: absolute;
  top: calc(var(--spacing-xxxs) * -1);
  bottom: calc(var(--spacing-xxxs) * -1);
  left: calc(var(--spacing-xxxs) * -1);
  width: var(--spacing-xxs);
  background: var(--color-primary-500);
  content: '';
  transition: transform .25s;
  transform: scaleX(0);
  transform-origin: 0 0;
}

.cmp-download__table-row:hover .cmp-download__table-column:first-child::before {
  transform: scaleX(1);
}

.cmp-download__link {
  @include bold-md();

  display: flex;
  width: 30%;
  color: var(--color-primary-500);
}

.cmp-download__meta {
  @include regular-md();

  display: flex;
  width: 70%;
}

.cmp-download__file-name {
  width: 70%;
}

.cmp-download__file-info {
  color: var(--color-gray-500);
  text-transform: uppercase;
  width: 15%;
  text-align: right;
}

@media (--breakpoint-down-sm) {
  .cmp-download__table-row {
    flex-flow: column wrap;
  }

  .cmp-download__link {
    padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-xs) var(--spacing-sm);
    width: 100%;
  }

  .cmp-download__meta {
    padding: 0 var(--spacing-sm) var(--spacing-xs) var(--spacing-sm);
    width: 100%;
  }

  .cmp-download__file-name {
    margin-left: var(--spacing-lg);
  }

  .cmp-download__file-info {
    text-align: center;
  }
}
