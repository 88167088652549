.cmp-navigation-teaser {
  position: relative;
  border-bottom: 1px solid var(--color-gray-100);
  box-shadow: 0 0 3px rgb(0 0 0 / 40%);
  height: auto;

  &__image {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
  }

  &__content {
    display: none;
    background-color: var(--color-white);
    max-height: 16.25rem;
    overflow: auto;
    padding-bottom: var(--spacing-md);
    width: 100%;
    box-shadow: 0 3px 3px rgb(0 0 0 / 40%);

    &.content--is-open {
      display: block;
    }
  }

  &__title {
    padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) var(--spacing-lg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &-label {
      @include bold-lg();

      margin-right: var(--spacing-xs);
      cursor: pointer;
    }

    &-icon::before {
      color: var(--color-primary-500);
      font-size: var(--spacing-lg);
      cursor: pointer;
    }

    .icon-keyboard_arrow_down {
      display: block;
    }

    .icon-keyboard_arrow_up {
      display: none;
    }

    &.title--is-open {
      .icon-keyboard_arrow_down {
        display: none;
      }

      .icon-keyboard_arrow_up {
        display: block;
      }
    }
  }

  &__description {
    @include regular-md();

    border-top: 1px solid var(--color-gray-100);
    padding: var(--spacing-sm) var(--spacing-lg) var(--spacing-md);

    a {
      color: var(--color-primary-500);
      text-decoration: underline;
    }
  }

  &__link-list {
    list-style-type: none;
    padding: 0 var(--spacing-lg);
    margin: 0;
  }

  &__link-item {
    display: flex;
    padding: var(--spacing-sm) 0;
  }

  &__link {
    @include regular-lg();

    color: var(--color-primary-500);
    display: flex;
    align-items: center;

    &::before {
      font-size: var(--spacing-lg);
    }
  }
}

@include remove-grid-content('.navigationteaser');

.navigationteaser::after {
  content: '';
  display: block;

  @media #{$mq-desktop} {
    padding-top: var(--spacing-4xl);
  }

  @media #{$mq-mobile} {
    padding-top: var(--spacing-xl);
  }
}

.navigationcontainer .navigationteaser::after {
  padding-top: 0;
}
