/**
 * Carousel - Component
 */

@include remove-grid-content('.carousel.eventList');

.cmp-carousel iframe {
  height: 100%;
  width: 100%;
}

.cmp-carousel .cmp-banner-cta__content--full-width,
.cmp-carousel :not(.cmp-quote__image) > .cmp-image {
  background: linear-gradient(270deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 40%) 100%);
}

.cmp-carousel .cmp-banner-cta__arrow,
.cmp-container--full-width .cmp-carousel .cmp-banner-cta__arrow {
  border-top-width: calc(37.5em + 20vh);
}

/* actions */
.cmp-carousel__actions {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
  padding: var(--spacing-md);
  flex-flow: column;
}

.cmp-carousel__action {
  outline: none;
  margin: 0;
  display: block;
  cursor: pointer;
  height: 1.875rem;
  width: 1.875rem;
  background-color: var(--color-primary-500);
  color: var(--color-white);
  padding: 0.188rem;
  border: var(--border-xs) solid var(--color-primary-500);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: splide-arrow-hover-reverse 0.15s ease-in-out 0s 1 forwards;

  .icon-keyboard_arrow_right {
    animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;

    &::before {
      margin-right: 0;
    }
  }

  .icon-keyboard_arrow_left {
    animation: link-icon-left-reverse 0.15s ease-in-out 0s 1 forwards;

    &::before {
      margin-right: 0;
    }
  }

  &:hover,
  &:active {
    .icon-keyboard_arrow_right {
      animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
    }

    .icon-keyboard_arrow_left {
      animation: link-icon-left 0.15s ease-in-out 0s 1 forwards;
    }
  }

  &:hover {
    background-color: var(--color-primary-700);
    animation: splide-arrow-hover 0.15s ease-in-out 0s 1 forwards;
  }

  &:active {
    background-color: var(--color-primary-800);
    animation: splide-arrow-hover-reverse 0.15s ease-in-out 0s 1 forwards;
  }
}

/* if only 1 slide is available, the action menu is not needed */
.cmp-carousel__content > div:nth-child(2):last-child {
  display: none;
}

.cmp-carousel__indicators {
  max-width: 38.25rem;
  width: 100%;
  margin: var(--spacing-lg) auto 0 auto;
  display: flex;
  background: grey;
}

.cmp-carousel__action-text {
  display: none;
}

.cmp-carousel__action-icon::before {
  color: var(--color-white);
  font-size: var(--spacing-lg);
  margin: 0;
}

@media (hover: none) {
  .cmp-carousel__action:hover {
    background-color: var(--color-primary-500);
  }

  .cmp-carousel__action-icon:hover::before {
    color: var(--color-white);
  }
}

.cmp-carousel__indicator {
  background-color: var(--color-gray-300);
  height: 0.188rem;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.cmp-carousel__arrows {
  margin: 0 auto;
  padding-top: var(--spacing-lg);
  display: flex;
}

.cmp-carousel__arrows .cmp-carousel__action--previous {
  margin-right: var(--spacing-sm);
}

.cmp-carousel__arrows .cmp-carousel__action--left {
  margin-left: var(--spacing-sm);
}

/* stage - when inside stage hero */
.stage .cmp-carousel__actions {
  position: absolute;
  bottom: 0;
}

.stage .cmp-carousel__indicator {
  background-color: white;
}

.stage .cmp-carousel__action-icon::before {
  color: white;
}

/* active state  */
.cmp-carousel .cmp-carousel__indicator--active,
.cmp-carousel .cmp-carousel__indicator--past {
  background-color: var(--color-primary-500);
}

/* Only desktop - styles */
@media (--breakpoint-up-sm) {
  .cmp-carousel .cmp-banner-cta__title {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .cmp-carousel .cmp-text {
    margin-top: var(--spacing-sm);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .cmp-carousel .cmp-banner-cta__content--side-aligned .cmp-banner-cta__button {
    margin-bottom: 5rem; /* 80 */
  }
}

/* Mobile - styles */
@media (--breakpoint-down-sm) {
  .cmp-carousel__actions {
    margin: 0 10%;
    padding-bottom: var(--spacing-md);
    width: 80%;
  }

  .cmp-container--full-width .cmp-carousel .cmp-banner-cta__button {
    margin-bottom: var(--spacing-md);
  }
}
