@import '@site/styles/foundations/media_queries.scss';
@import '@react/common.scss';

/* number of results */
.c-mapapp__list-results {
  @include regular-xl();

  text-align: center;
  margin-top: var(--spacing-md);
}

.c-mapapp__list-link {
  color: var(--color-primary-500);
  display: block;
}

/* table list */
.c-mapapp__list-table {
  @include regular-md();
}

.c-mapapp__list-table tbody tr {
  border-top: var(--spacing-xxxs) solid var(--color-gray-100);
}

.c-mapapp__list-table th {
  @include regular-xs();

  padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) 0;
  width: 25%;
  text-transform: uppercase;
}

.c-mapapp__list-table td {
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) 0;
  width: 25%;
}

.c-mapapp__list-table th:last-child,
.c-mapapp__list-table td:last-child {
  padding-right: 0;
}

/*
* Locations list - specific styles
*/
/* show on map button */
.c-mapapp__list-show-on-map.c-mapapp__list-show-on-map,
.c-mapapp__list-show-on-map .cmp-button__icon,
.c-mapapp__list-show-on-map .cmp-button__text {
  @include regular-md();

  display: inline-flex;
  align-items: center;
  margin-top: var(--spacing-xxs);
}

.c-mapapp__list-global-headquarters {
  max-width: 50rem;
  margin: 0 auto var(--spacing-md);
}

.c-mapapp__list--locations-hq {
  @include regular-xs();

  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 100%;
  text-align: center;
  background: var(--color-primary-500);
  color: white;
}

/* responsive */
@media #{$mq-mobile} {
  .c-mapapp__list-table tr {
    display: block;
    padding: var(--spacing-md) 0;
  }

  .c-mapapp__list-table td {
    display: block;
    width: 100%;
    padding: var(--spacing-xxs) 0;
  }
}
