.cmp-sectorteasercontainer .cmp-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: flex-start;
  margin: 0;
  /* margin-bottom: calc(-1 * var(--teaser-horizontal-spacing)); */
}

.cmp-sectorteasercontainer .cmp-container > .aem-Grid {
  min-width: 100%;
}

.cmp-sectorteasercontainer.container.responsivegrid.aem-GridColumn.aem-GridColumn--default--12 {
  padding: 0;
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer .cmp-container {
    margin-bottom: calc(-1 * var(--teaser-horizontal-spacing-md));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer .cmp-container {
    display: block;
    margin-bottom: calc(-1 * var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-sectorteasercontainer .sectorteaser {
  display: flex;
  margin: 0;
}

.cmp-sectorteasercontainer--1-columns .sectorteaser {
  flex-basis: 100%;
}

.cmp-sectorteasercontainer--2-columns .sectorteaser,
.cmp-sectorteasercontainer--2-columns .basicpage {
  flex-basis: calc(50% - var(--teaser-horizontal-spacing));
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer--2-columns .sectorteaser,
  .cmp-sectorteasercontainer--2-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer--2-columns .sectorteaser,
  .cmp-sectorteasercontainer--2-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-sectorteasercontainer--3-columns .sectorteaser,
.cmp-sectorteasercontainer--3-columns .basicpage {
  flex-basis: calc(33.3333% - var(--teaser-horizontal-spacing));
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer--3-columns .sectorteaser,
  .cmp-sectorteasercontainer--3-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer--3-columns .sectorteaser,
  .cmp-sectorteasercontainer--3-columns .basicpage {
    flex-basis: calc(33.3333% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-sectorteasercontainer--4-columns .sectorteaser,
.cmp-sectorteasercontainer--4-columns .basicpage {
  flex-basis: calc(25% - var(--teaser-horizontal-spacing));
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer--4-columns .sectorteaser,
  .cmp-sectorteasercontainer--4-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer--4-columns .sectorteaser,
  .cmp-sectorteasercontainer--4-columns .basicpage {
    flex-basis: calc(25% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-sectorteasercontainer--5-columns .sectorteaser,
.cmp-sectorteasercontainer--5-columns .basicpage {
  flex-basis: calc(20% - var(--teaser-horizontal-spacing));
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer--5-columns .sectorteaser,
  .cmp-sectorteasercontainer--5-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer--5-columns .sectorteaser,
  .cmp-sectorteasercontainer--5-columns .basicpage {
    flex-basis: calc(20% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-sectorteasercontainer--6-columns .sectorteaser,
.cmp-sectorteasercontainer--6-columns .basicpage {
  flex-basis: calc(16.666% - var(--teaser-horizontal-spacing));
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer--6-columns .sectorteaser,
  .cmp-sectorteasercontainer--6-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer--6-columns .sectorteaser,
  .cmp-sectorteasercontainer--6-columns .basicpage {
    flex-basis: calc(16.666% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-sectorteasercontainer__button {
  margin-left: 50%;
  margin-top: var(--spacing-lg);
  transform: translateX(-50%);
  align-items: center;
  display: inline-flex;
  transition: transform 0.1s;
}

.cmp-sectorteasercontainer__button:hover {
  color: var(--color-gray-900);
}

.cmp-sectorteasercontainer__button:hover .cmp-sectorteasercontainer__button-text {
  text-decoration: underline;
}

.cmp-sectorteasercontainer__button .icon-keyboard_arrow_down {
  animation: link-icon-down-reverse 0.15s ease-in-out 0s 1 forwards;
}

.cmp-sectorteasercontainer__button:hover .icon-keyboard_arrow_down {
  animation: link-icon-down 0.15s ease-in-out 0s 1 forwards;
}

.cmp-sectorteasercontainer__button--hidden {
  display: none;
}

.cmp-sectorteasercontainer__button:active {
  transform: scale(0.95) translateX(-50%);
}

/*
* fullwidth grey stylepolicy
*/
.cmp-sectorteasercontainer--fullwidth-grey {
  margin-bottom: var(--spacing-4xl);
  padding-top: var(--spacing-xl);
  position: relative;
}

.cmp-sectorteasercontainer--fullwidth-grey::before {
  content: '';
  position: absolute;
  display: block;
  z-index: -1;
  height: 100%;
  margin-top: calc(-1 * var(--spacing-xl));
  background: var(--color-gray-50);
  left: -6.75rem;
  width: calc(100% + (6.75rem * 2));
}

.cmp-container > .aem-Grid > .cmp-sectorteasercontainer--fullwidth-grey:last-child {
  margin-bottom: 0;
}

@media (--breakpoint-down-xl) {
  .cmp-sectorteasercontainer--fullwidth-grey::before {
    left: -1.75rem;
    width: calc(100% + (1.75rem * 2));
  }
}

@media (--breakpoint-down-lg) {
  .cmp-sectorteasercontainer--fullwidth-grey::before {
    left: -1.125rem;
    width: calc(100% + (1.125rem * 2));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer--fullwidth-grey::before {
    left: calc(-1 * (var(--spacing-lg) - var(--spacing-sm)));
    width: calc(100% + ((var(--spacing-lg) - var(--spacing-sm)) * 2));
  }
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--1-columns .cmp-container {
  padding: 0 41.667%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--2-columns .cmp-container {
  padding: 0 33.333%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--3-columns .cmp-container {
  padding: 0 25%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--4-columns .cmp-container {
  padding: 0 16.667%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--5-columns .cmp-container {
  padding: 0 8.333%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--6-columns .cmp-container {
  padding: 0;
}

.cmp-sectorteasercontainer .sectorteaser,
.cmp-sectorteasercontainer .basicpage {
  margin: 0;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--1-columns .sectorteaser,
.cmp-sectorteasercontainer .cmp-sectorteasercontainer--1-columns .basicpage {
  flex-basis: 100%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--2-columns .sectorteaser,
.cmp-sectorteasercontainer .cmp-sectorteasercontainer--2-columns .basicpage {
  flex-basis: 50%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--3-columns .sectorteaser,
.cmp-sectorteasercontainer .cmp-sectorteasercontainer--3-columns .basicpage {
  flex-basis: 33.333%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--4-columns .sectorteaser,
.cmp-sectorteasercontainer .cmp-sectorteasercontainer--4-columns .basicpage {
  flex-basis: 25%;
  padding: var(--spacing-xxxs);
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--5-columns .sectorteaser,
.cmp-sectorteasercontainer .cmp-sectorteasercontainer--5-columns .basicpage {
  flex-basis: 20%;
}

.cmp-sectorteasercontainer .cmp-sectorteasercontainer--6-columns .sectorteaser,
.cmp-sectorteasercontainer .cmp-sectorteasercontainer--6-columns .basicpage {
  flex-basis: 16.666%;
}

@media (--breakpoint-down-md) {
  .cmp-sectorteasercontainer .cmp-container {
    padding: 0;
    justify-content: flex-start;
  }

  .cmp-sectorteasercontainer [class*='cmp-sectorteasercontainer--'] .sectorteaser,
  .cmp-sectorteasercontainer [class*='cmp-sectorteasercontainer--'] .basicpage {
    flex-basis: 25%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-sectorteasercontainer .cmp-container {
    display: flex;
  }

  .cmp-sectorteasercontainer .cmp-sectorteasercontainer--4-columns .cmp-container {
    padding: 0 var(--spacing-xxs);
  }

  .cmp-sectorteasercontainer [class*='cmp-sectorteasercontainer--'] .sectorteaser,
  .cmp-sectorteasercontainer [class*='cmp-sectorteasercontainer--'] .basicpage {
    flex-basis: 50%;
  }
}
