.c-mapapp__filters-container {
  max-width: 50rem;
  margin: auto;
}

.c-mapapp__filters-filters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(-1 * var(--spacing-xs));
}

/* filter */
.c-mapapp__filter {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 var(--spacing-xs);
  margin-bottom: var(--spacing-md);
  flex: 1;
  flex-basis: 10.5rem;
}

.c-mapapp__filter-select {
  width: 100%;
  appearance: none;
  position: relative;
  background: white;
  font-family: inherit;
  font-size: inherit;
  padding: calc(var(--spacing-xs) - var(--spacing-xxxs)) var(--spacing-md);
  padding-right: var(--spacing-xl);
  line-height: var(--line-height);
  border-radius: 0;
  border: var(--spacing-xxxs) solid var(--color-gray-700);
  height: 2.5rem;
}

.c-mapapp__filter.country {
  flex-basis: 15rem;
}

.c-mapapp__filter::after {
  font-family: 'custom_materialicons', sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* include icon font  */
  width: var(--spacing-xl);
  font-size: var(--spacing-lg);
  content: var(--icon-keyboard_arrow_down);
  right: 0;
  top: 0;
  line-height: var(--spacing-xxl);
  pointer-events: none;
  position: absolute;
}

.c-mapapp__filter-select:disabled {
  background: var(--color-gray-50);
  border-color: var(--color-gray-300);
  color: var(--neutral-darkest);
}

.c-mapapp__reset {
  text-align: right;
}

@media (--breakpoint-down-md) {
  .c-mapapp__filters {
    display: block;
  }

  .c-mapapp__filter {
    width: calc(50% - var(--spacing-sm));
    flex-basis: calc(50% - var(--spacing-sm));
  }
}

@media (--breakpoint-down-md) {
  .c-mapapp__filter {
    width: 100%;
    flex-basis: 100%;
  }
}
