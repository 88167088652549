.news-teasers {
  overflow: hidden;
  border-bottom: 1px solid var(--color-gray-100);
}

.news-teaser {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  transition: all 1s;

  .vjs-has-started {
    .vjs-control-bar {
      display: none;
    }
  }

  &__banner {
    position: relative;
    width: 100%;
    transition: all 0.5s;
    aspect-ratio: 3 / 2;

    .cmp-video-wrapper ~ .news-teaser__img {
      display: none;
    }

    .cmp-video-wrapper {
      overflow: hidden;

      .cmp-video-player {
        height: 100%;

        @media #{$mq-desktop} {
          width: auto;
          overflow: hidden;
          max-height: 100vh;
        }
      }
    }
  }

  &__container__title {
    text-align: center;
    font-size: 1.875rem;
    line-height: 2.75rem;
    margin: var(--spacing-xxxl) var(--spacing-lg) var(--spacing-xl);

    @media #{$mq-desktop} {
      font-size: 2.625rem;
      line-height: 3.5rem;
      margin: var(--spacing-xxxl) var(--spacing-lg) var(--spacing-xxxl);
    }
  }

  > * {
    width: 100%;
  }

  &__img,
  .cmp-video-wrapper {
    position: relative;
    transition: all 0.5s;
    height: 100%;

    &::before {
      content: '';
      opacity: 0;
      width: 100%;
      height: 100%;
      background: var(--color-primary-500);
      position: absolute;
      transition: opacity 0.3s;
      z-index: 1;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: var(--color-black);
      position: absolute;
      transition: opacity 0.3s;
      z-index: 1;
      opacity: 0;
      top: 0;
    }

    .cmp-responsive-image {
      height: 100%;
    }

    img {
      width: 100%;
      /* stylelint-disable-next-line declaration-no-important */
      position: relative !important;
      filter: grayscale(0%);
      transition: all 0.8s;
    }
  }

  &__video {
    > iframe {
      height: 100%;
      width: 100%;
      display: block;
    }
  }

  &__link {
    padding: var(--spacing-lg);
    transition: all 0.5s;

    &__text {
      &__preline {
        @include regular-lg();

        margin-bottom: var(--spacing-xs);
        display: block;
      }

      &__title {
        @include bold-3xl();

        margin-bottom: var(--spacing-xl);
        min-height: 3rem;
        display: block;

        @media #{$mq-desktop} {
          line-height: 3rem;
          font-size: var(--font-size-h2);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          position: relative;

          &--has-tooltip {
            &:hover {
              & + .news-teaser__link__text__tooltip {
                visibility: visible;
              }
            }
          }
        }
      }

      &__tooltip {
        @include bold-md();

        visibility: hidden;
        width: 17.438rem;
        background-color: var(--color-white);
        text-align: left;
        padding: 1.875rem var(--spacing-md) var(--spacing-md) var(--spacing-md);
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 25%;
        color: var(--color-gray-900);

        &::after {
          content: '';
          position: absolute;
          bottom: 100%;
          left: var(--spacing-sm);
          margin-left: -var(--spacing-sm);
          border-width: var(--spacing-sm);
          border-style: solid;
          border-color: transparent transparent var(--color-white) transparent;
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      float: right;
      color: var(--color-primary-500);
      border: var(--border-s) solid var(--color-primary-500);
      background-color: var(--color-white);
      font-weight: var(--font-weight-bold);
      transition: all 0.3s;
      animation: link-icon-hover-reverse 0.15s ease-in-out 0s 1 forwards;
      min-width: var(--spacing-xxxl);
      height: var(--spacing-xxxl);

      .cmp-button__icon--keyboard_arrow_right {
        animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
      }

      @media #{$mq-desktop} {
        @include bold-xl();
      }

      @media #{$mq-mobile} {
        @include bold-lg();
      }
    }
  }

  &__button {
    text-align: center;
    padding-top: var(--spacing-xl);
    margin: 0 var(--spacing-xxl) var(--spacing-4xl);

    &__link {
      background-color: var(--color-white);
      color: var(--color-primary-500);
      outline: var(--border-xs) solid var(--color-primary-500);
      font-weight: var(--font-weight-bold);
      display: inline-block;
      position: relative;

      &:hover {
        color: var(--color-primary-700);
        outline: var(--border-s) solid var(--color-primary-700);
        z-index: 1;
      }

      &:active {
        color: var(--color-primary-800);
        outline: var(--border-xs) solid var(--color-primary-800);
        background-color: var(--color-primary-100);
      }

      @media #{$mq-mobile} {
        @include bold-xl();

        padding: var(--spacing-md) var(--spacing-lg);

        &:last-child {
          margin-top: var(--spacing-md);
        }
      }

      @media #{$mq-desktop} {
        @include bold-2xl();

        padding: var(--spacing-md) var(--spacing-xxl);

        &:last-child {
          margin-left: var(--spacing-lg);
        }
      }
    }
  }

  &:hover {
    .news-teaser {
      &__img {
        &::before {
          opacity: 0.35;
        }

        &::after {
          opacity: 0.35;
        }

        img {
          filter: grayscale(100%);
        }
      }

      &__link {
        background: var(--color-primary-500);

        &__button {
          animation: link-icon-hover 0.15s ease-in-out 0s 1 forwards;
          border: var(--border-s) solid var(--color-primary-700);
          color: var(--color-primary-700);

          .cmp-button__icon--keyboard_arrow_right {
            animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
          }
        }

        &__text {
          &__preline,
          &__title {
            color: var(--color-white);
          }
        }
      }
    }

    .cmp-video-wrapper {
      &::before {
        opacity: 0.35;
      }

      &::after {
        opacity: 0.35;
      }
    }
  }

  &:active {
    .news-teaser__link__button {
      background-color: var(--color-primary-100);
      border: var(--border-s) solid var(--color-primary-800);
      color: var(--color-primary-800);
    }
  }

  @media #{$mq-desktop} {
    flex-direction: row;

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &[data-animation-active='true'] {
      opacity: 0;

      &:not(:nth-child(2n)) {
        .news-teaser {
          &__link {
            transform: translateX(90%);
          }

          &__banner {
            transform: translateX(-90%);
          }
        }
      }

      &:nth-child(2n) {
        .news-teaser {
          &__link {
            transform: translateX(-90%);
          }

          &__banner {
            transform: translateX(90%);
          }
        }
      }

      &.active {
        opacity: 1;

        .news-teaser {
          &__link,
          &__banner {
            transform: translateX(0);
          }
        }
      }
    }

    &[data-animation-active='false'] {
      opacity: 1;
      transform: translateX(0%);
    }

    > * {
      width: 50%;
    }

    &__link {
      aspect-ratio: 3 / 2;
      padding: var(--spacing-xl) var(--spacing-4xl);
      display: flex;
      justify-content: center;
      flex-direction: column;

      &__text {
        align-self: center;

        &__preline {
          margin-bottom: var(--spacing-lg);
        }
      }
    }
  }

  @media (--breakpoint-up-lg) {
    &__link {
      padding: var(--spacing-xxxl) var(--spacing-5xl);
    }
  }

  @media (--breakpoint-up-xl) {
    &__link {
      padding: var(--spacing-6xl) var(--spacing-5xl);
    }
  }
}
