@import '@react/common';

.cmp-comment-actions {
  display: flex;
  flex-flow: row;
  transition: transform 0.3s ease-in-out;

  &__wrapper {
    width: 100%;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    height: toRem(40px);

    &--confirmation {
      height: toRem(90px);

      .cmp-comments__comment__actions,
      .cmp-comments__comment__dialog-actions {
        transform: translateY(toRem(-30px));
      }
    }

    &--edit {
      height: toRem(214px);
    }
  }

  &__separator {
    padding: 0 var(--spacing-xs);
    display: flex;
    align-items: center;
  }

  &__dialog-actions {
    transition: transform 0.3s ease-in-out;
  }

  &__dialog-textarea {
    width: 100%;
    max-width: 100%;
    height: toRem(120px);
    padding-top: var(--spacing-md);
    color: var(--color-black);

    @include regular-md;
  }

  &__reply,
  &__edit,
  &__like,
  &__delete {
    color: var(--color-primary-500);
    display: flex;
    cursor: pointer;
    align-items: center;

    @include regular-lg;

    .cmp-button__icon {
      margin-right: var(--spacing-xs);

      @include regular-3xl;
    }
  }
}
