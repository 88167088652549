/*
* Slider
* - overwrite swiper plugin styles
*/

/* Gallery background wrapper */
.cmp-media-gallery--background-grey {
  margin-bottom: var(--spacing-4xl);
  padding-top: var(--spacing-xl);
  position: relative;
}

.cmp-media-gallery--background-grey::before {
  content: '';
  position: absolute;
  display: block;
  z-index: -1;
  height: 100%;
  margin-top: calc(-1 * var(--spacing-xl));
  background: var(--color-gray-50);
  left: -6.75rem;
  width: calc(100% + (6.75rem * 2));
}

/* SplideJS is adding .splide__slide__row with inline styles and cant rewrite without important */
.splide__slide__row {
  /* stylelint-disable-next-line declaration-no-important */
  height: auto !important;
}

@media (--breakpoint-down-xl) {
  .cmp-media-gallery--background-grey::before {
    left: -1.75rem;
    width: calc(100% + (1.75rem * 2));
  }
}

@media (--breakpoint-down-lg) {
  .cmp-media-gallery--background-grey::before {
    left: -1.125rem;
    width: calc(100% + (1.125rem * 2));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-media-gallery--background-grey::before {
    left: calc(-1 * (var(--spacing-lg) - var(--spacing-sm)));
    width: calc(100% + ((var(--spacing-lg) - var(--spacing-sm)) * 2));
  }
}

/* show all items in the editor mode */
.aem-AuthorLayer-Edit .swiper-wrapper {
  display: block;
}

.slider {
  width: 100%;
  overflow: hidden;
}

.swiper-controls {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-lg);
}

.swiper-pagination {
  position: static;
  display: flex;
  align-items: center;
}

.swiper-pagination-bullet {
  background: var(--color-gray-900);
  margin: 0 var(--spacing-xxs);
  opacity: 1;
  width: 0.5rem;
  height: 0.5rem;
}

.swiper-pagination-bullet-active {
  background: var(--color-primary-500);
  opacity: 1;
  transform: scale(1.2);
}

.swiper-pagination-bullet:hover {
  transform: scale(1.2);
}

.swiper-button-prev,
.swiper-button-next {
  position: static;
  color: var(--color-gray-900);
  height: auto;
  width: auto;
  margin: 0 var(--spacing-lg);
  top: 0;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-family: var(--icon-font);
  font-size: var(--spacing-lg);
}

.swiper-button-prev::after {
  content: var(--icon-keyboard_arrow_left);
}

.swiper-button-next::after {
  content: var(--icon-keyboard_arrow_right);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: var(--color-primary-500);
}

/*
* Style policies / Slider types
*/

/*
* Defaut slider
*/

/* .cmp-slider--default {} */

/* image/embed aspect ratio 16:9 */
.cmp-slider--ratio\:16\/9 .cmp-embed,
.cmp-slider--ratio\:16\/9 .cmp-image,
.cmp-slider--gallery .cmp-embed,
.cmp-slider--gallery .cmp-image,
.cmp-slider--carousel .cmp-embed,
.cmp-slider--carousel .cmp-image {
  position: relative;
}

.cmp-slider--ratio\:16\/9 .cmp-embed::before,
.cmp-slider--ratio\:16\/9 .cmp-image::before,
.cmp-slider--gallery .cmp-embed::before,
.cmp-slider--gallery .cmp-image::before,
.cmp-slider--carousel .cmp-embed::before,
.cmp-slider--carousel .cmp-image::before {
  content: '';
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.cmp-slider--ratio\:16\/9 .cmp-embed iframe,
.cmp-slider--ratio\:16\/9 .cmp-image__image,
.cmp-slider--gallery .cmp-image__image,
.cmp-slider--gallery .cmp-embed iframe,
.cmp-slider--carousel .cmp-embed iframe,
.cmp-slider--carousel .cmp-image__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;
  object-fit: cover;
  object-position: center;
}

/*
* Coverflow slider
* - e.g. job postings
*/
.cmp-slider--coverflow .swiper-slide {
  width: 50%;
  margin: 0 var(--spacing-lg);
}

@media (--breakpoint-down-md) {
  .cmp-slider--coverflow .swiper-slide {
    width: 60%;
    margin: 0 var(--spacing-md);
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--coverflow .swiper-slide {
    width: 80%;
    margin: 0 var(--spacing-sm);
  }
}

/*
* Multiple items
*/
.cmp-slider--multiple .swiper-container {
  margin: 0 calc(var(--spacing-sm) * -1);
}

.cmp-slider--multiple .swiper-slide {
  padding: 0 var(--spacing-sm);
}

/* 4 items in row */
.cmp-slider--multiple-4 .swiper-slide {
  width: 25%;
}

@media (--breakpoint-down-md) {
  .cmp-slider--multiple-4 .swiper-slide {
    width: 33.333%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--multiple-4 .swiper-slide {
    width: 90%;
  }
}

/* 3 items in a row */
.cmp-slider--multiple-3 .swiper-slide {
  width: 33.333%;
}

/* media gallery */
.cmp-media-gallery .swiper-slide {
  width: 100%;
}

.cmp-media-gallery.cmp-slider--multiple-3 .swiper-slide {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-lg) var(--spacing-lg);
}

.cmp-media-gallery.cmp-slider--multiple-4 .swiper-slide {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--spacing-lg) var(--spacing-lg);
}

.cmp-media-gallery.cmp-slider--multiple-6 .swiper-slide {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: var(--spacing-lg) var(--spacing-lg);
}

.cmp-media-gallery .swiper-slide .image {
  position: relative;
}

.cmp-media-gallery .swiper-slide .image .cmp-media-gallery__fullscreen-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 0.375rem;
  background: var(--color-white);
  color: var(--color-gray-500);
  font-size: 2.25rem;
  text-align: center;
  z-index: 2;
  pointer-events: initial;
}

.cmp-media-gallery .swiper-slide .image .cmp-media-gallery__fullscreen-btn:hover {
  color: var(--color-primary-500);
  cursor: pointer;
}

.cmp-media-gallery .swiper-slide .cmp-media-gallery__description {
  padding: var(--spacing-xs);
  background: var(--color-white);
  border: var(--spacing-xxxs) solid var(--color-gray-50);
}

@media (--breakpoint-down-md) {
  .cmp-slider--multiple-3 .swiper-slide {
    width: 50%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--multiple-3 .swiper-slide {
    width: 90%;
  }

  .cmp-media-gallery.cmp-slider--multiple-3 .swiper-slide,
  .cmp-media-gallery.cmp-slider--multiple-4 .swiper-slide,
  .cmp-media-gallery.cmp-slider--multiple-6 .swiper-slide {
    grid-template-columns: 1fr 1fr;
  }
}
