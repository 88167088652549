/*
* Tags
*/

.c-tag {
  @include regular-xs();

  white-space: nowrap;
  padding: var(--spacing-xs) var(--spacing-sm);
  display: inline-block;
  vertical-align: middle;
  border: var(--spacing-xxxs) solid transparent;
}


/* neutral dark */
.c-tag--neutral-dark {
  border-color: var(--color-gray-500);
  color: var(--color-gray-500);
}

.c-tag--neutral-dark-full {
  border-color: var(--color-gray-500);
  background: var(--color-gray-500);
  color: white;
}


/* primary */
.c-tag--primary {
  color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}

.c-tag--primary-full {
  border-color: var(--color-primary-500);
  background: var(--color-primary-500);
  color: white;
}


/* secondary */
.c-tag--secondary {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.c-tag--secondary-full {
  border-color: var(--color-secondary);
  background: var(--color-secondary);
  color: white;
}


/* tags list */
.c-tags__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}

.c-tags__list .c-tag {
  margin-bottom: var(--spacing-sm);
  margin-right: var(--spacing-sm);
}

.c-tags__list .c-tag:last-child {
  margin-right: 0;
}
