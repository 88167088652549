:root {
  /* Banner CTA height - Mobile */
  --sm-banner-fullwidth-height: 31.25rem; /* 500 */
  --sm-banner-trapez-height: 25rem; /* 400 */
  --sm-banner-notrapez: 15rem; /* 240 */
}

/* Mobile - font */
@media (--breakpoint-down-md) {
  .cmp-banner-cta__text,
  .cmp-banner-cta__icon {
    line-height: var(--line-height);
  }

  .cmp-banner-cta__title,
  .cmp-banner-cta__tall .cmp-banner-cta__title,
  .cmp-banner-cta__content--side-aligned .cmp-banner-cta__title {
    font-size: var(--sm-font-size-h4);
    line-height: var(--sm-line-height-h4);
  }

  .cmp-container--full-width .cmp-banner-cta__title {
    font-size: var(--sm-font-size-h1);
    line-height: var(--sm-line-height-h1);
  }

  .cmp-container--full-width .cmp-banner-cta__text,
  .cmp-container--full-width .cmp-banner-cta__icon {
    @include regular-xl();
  }
}

/* Mobile - styles */
@media (--breakpoint-down-sm) {
  .cmp-banner-cta__text .cmp-text,
  .cmp-banner-cta__text p {
    margin-top: 0;
  }

  .cmp-banner-cta__content--full-width .cmp-banner-cta__button {
    margin-top: 1.625rem;
  }

  .cmp-banner-cta__wrapper {
    height: var(--sm-banner-notrapez);
  }

  .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__wrapper-video:not(.cmp-banner-cta__left-shape) {
    background-color: var(--color-gray-50);
    color: var(--color-black);
  }

  .cmp-banner-cta__short--side-aligned,
  .cmp-banner-cta__tall--side-aligned {
    height: var(--sm-banner-trapez-height);
  }

  .slider-banner .cmp-banner-cta__tall {
    height: 70vh;
    max-height: none;
  }

  .cmp-banner-cta__content--side-aligned {
    bottom: 0;
    height: 0;
    padding: 0;
  }

  .cmp-banner-cta__content-inner {
    padding: 0 var(--spacing-lg);
  }

  .cmp-banner-cta__content--left-shape .cmp-banner-cta__content-inner,
  .cmp-banner-cta__content--redTrapezoid .cmp-banner-cta__content-inner {
    background-color: var(--color-primary-500);
  }

  .cmp-container--full-width .cmp-banner-cta__content-inner.cmp-banner-cta__content-inner {
    background-color: transparent;
  }

  .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 10.6875rem; /* 171 */
    position: absolute;
    width: 100%;
  }

  .cmp-banner-cta__content--full-width {
    padding: 0;
    width: 90%;
  }

  .cmp-banner-cta__wrapper .cmp-banner-cta__image,
  .cmp-banner-cta__wrapper .cmp-banner-cta__image * {
    height: 100%;
  }

  .cmp-banner-cta__tall--side-aligned .cmp-banner-cta__video,
  .cmp-banner-cta__short--side-aligned .cmp-banner-cta__video,
  .cmp-banner-cta__short--side-aligned .cmp-banner-cta__content,
  .cmp-banner-cta__tall--side-aligned .cmp-banner-cta__content {
    min-width: 100%;
  }

  .cmp-banner-cta__short .cmp-banner-cta__video .cmp-videocontainer {
    height: var(--sm-banner-fullwidth-height);
  }

  .cmp-banner-cta__tall .cmp-banner-cta__video .cmp-videocontainer {
    height: var(--sm-banner-fullwidth-height);
  }

  /* stylelint-disable declaration-no-important */
  .cmp-banner-cta .cmp-videocontainer__dynamicmediaplayer .s7container,
  .cmp-banner-cta .cmp-videocontainer__dynamicmediaplayer video,
  .cmp-banner-cta .cmp-pagestage__dynamicmediaplayer .s7container,
  .cmp-banner-cta .cmp-pagestage__dynamicmediaplayer video {
    width: 0 !important;
    min-width: 100% !important;
    object-fit: cover;
    object-position: center;
  }

  .cmp-banner-cta__tall .cmp-videocontainer__dynamicmediaplayer .s7container,
  .cmp-banner-cta__tall .cmp-pagestage__dynamicmediaplayer .s7container {
    height: var(--sm-banner-fullwidth-height) !important;
  }

  .cmp-banner-cta__short--side-aligned .cmp-videocontainer__dynamicmediaplayer .s7container,
  .cmp-banner-cta__tall--side-aligned .cmp-videocontainer__dynamicmediaplayer .s7container {
    height: var(--sm-banner-trapez-height) !important;
  }

  .cmp-banner-cta__tall .cmp-pagestage__dynamicmediaplayer video,
  .cmp-banner-cta__tall .cmp-videocontainer__dynamicmediaplayer video,
  .cmp-banner-cta__short--side-aligned .cmp-videocontainer__dynamicmediaplayer video,
  .cmp-banner-cta__tall--side-aligned .cmp-videocontainer__dynamicmediaplayer video {
    height: 100%;
  }
  /* stylelint-enable declaration-no-important */

  .cmp-banner-cta__content--side-aligned .cmp-banner-cta__button {
    margin: auto 0 var(--spacing-lg);
  }

  .cmp-banner-cta__short .cmp-banner-cta__arrow,
  .cmp-banner-cta__tall .cmp-banner-cta__arrow {
    border: none;
    border-top-style: none;
    bottom: 11.5rem; /* 184 */
    left: 0;
  }

  .cmp-banner-cta__arrow::before {
    content: '';
    border-top: 15vw solid transparent;
    border-right: 100vw solid var(--color-primary-500);
  }

  /* Set as principal - when mobile & full width */
  .cmp-container--full-width .cmp-text {
    margin-top: var(--spacing-xs);
  }

  .cmp-container--full-width .cmp-button {
    margin-top: var(--spacing-md);
  }

  .cmp-container--full-width .cmp-banner-cta__content--side-aligned .cmp-banner-cta__button {
    margin: 0;
  }

  .cmp-container--full-width .cmp-banner-cta__wrapper {
    height: var(--sm-banner-fullwidth-height);
  }

  .cmp-container--full-width .cmp-banner-cta__left-shape .cmp-banner-cta__video {
    height: 70%;
  }

  .cmp-container--full-width .cmp-banner-cta__arrow {
    bottom: 0;
  }

  .cmp-container--full-width .cmp-banner-cta__arrow::before {
    border-left-style: solid;
    border-left-width: 200vw;
    border-top: 55vw solid transparent;
  }

  .cmp-banner-cta__short .cmp-banner-cta__title .cmp-title__text {
    font-size: 1.375rem;
    line-height: 2rem;
    margin-bottom: var(--spacing-sm);
  }
}
