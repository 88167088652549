/*
* Testimonial
*/
.cmp-teaser--testimonial {
  padding-top: var(--spacing-xxxs);
}

.cmp-teaser--testimonial .cmp-teaser {
  box-shadow: none;
  margin-top: var(--spacing-xxxl);
}

.cmp-teaser--testimonial .cmp-image {
  width: 11rem; /* 176 */
  height: 11rem;
  border-radius: 100%;
  overflow: hidden;
  background: var(--color-gray-50);
  border: var(--spacing-xs) solid white;
  margin: calc(var(--spacing-xxxl) * -1) auto 0;
}

.cmp-teaser--testimonial .cmp-image__image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.cmp-teaser--testimonial .cmp-teaser__content {
  text-align: center;
}
