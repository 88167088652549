.cmp-comment {
  &__dialog {
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-lg) 0;
    flex-direction: row;

    .button {
      margin-left: var(--spacing-sm);
    }
  }
}
