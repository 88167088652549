@import '@react/common';

.cmp-comment-reply {
  display: flex;
  padding: var(--spacing-sm) 0 0 5%;

  &:not(:last-child) &__details {
    border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
    padding-bottom: var(--spacing-xs);
  }

  &__details {
    margin: 0 var(--spacing-sm);
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    margin: var(--spacing-xxs) 0 var(--spacing-sm);
  }

  &__author {
    @include bold-lg;

    margin: 0;
  }

  &__date {
    color: var(--color-gray-500);
    padding: 0 var(--spacing-sm);

    @include regular-xs;
  }

  &__text {
    padding: var(--spacing-xs) 0 0;
  }
}
