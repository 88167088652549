/*
* AEM Component:
* Tabs
*/

.cmp-tabs {
  width: 100%;
  position: relative;

  .cmp-tab-active--heading {
    display: none;
  }
}

.cmp-tabs::after {
  clear: both;
}

.cmp-tabs__tablist {
  display: block;
  padding-left: 0;
  list-style: none;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.cmp-tabs__tablist::after {
  content: '';
  position: absolute;
  border-bottom: 0.25rem solid var(--color-primary-500);
  width: 100%;
  left: 0;
  bottom: 0;
}

.cmp-tabs__tab {
  margin: var(--spacing-md) var(--spacing-xs) 0 0;
  border: var(--spacing-xxxs) solid var(--color-primary-500);
  border-bottom-width: 0.25rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  padding: var(--spacing-xs) var(--spacing-xl);
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
}

.cmp-tabs__tab:only-child {
  cursor: auto;
  outline: none;
}

.cmp-tabs__tab--active {
  padding: var(--spacing-sm) var(--spacing-xl);
  background: var(--color-primary-500);
  color: var(--color-white);
}

.cmp-tabs__tabpanel {
  padding-top: var(--spacing-md);
}

/*
* style policies
*/
.cmp-tabs--center .cmp-tabs__tablist {
  text-align: center;
}

@media (--breakpoint-up-md) {
  .cmp-tabs__tab:not(:only-child):hover {
    background: var(--color-primary-700);
    color: var(--color-white);
  }

  .cmp-tabs__tab:hover {
    background-color: var(--color-primary-700);
  }

  .cmp-tabs__tab::after {
    content: '';
    position: absolute;
    border-bottom: var(--spacing-xxs) solid var(--color-primary-500);
    width: 100%;
    left: 0;
    bottom: -0.25rem;
    z-index: 1;
  }

  .cmp-tabs__tab:hover::after {
    border-bottom: var(--spacing-xxs) solid var(--color-primary-700);
  }
}

@media (--breakpoint-up-md) and (hover: none) {
  /* fix ipad hover style issue */
  .cmp-tabs__tab--active:not(:only-child):hover {
    background: var(--color-primary-500);
    color: var(--color-white);
  }
}

.cmp-tabs-vertical {
  &.cmp-tabs {
    display: flex;
    flex-wrap: wrap;

    .title-v2 {
      width: 100%;
    }

    .cmp-tab-active--heading {
      background: var(--color-primary-500);
      color: var(--color-white);
      font-weight: var(--font-weight-bold);
      padding: var(--spacing-sm) var(--spacing-lg);
      margin: 0;
      border: var(--spacing-xxxs) solid var(--color-primary-500);
      width: 100%;
      height: 100%;
      height: toRem(60px);
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
      cursor: pointer;

      @media #{$mq-mobile} {
        display: flex;

        &::after {
          @include regular-4xl();

          font-family: var(--icon-font);
          content: var(--icon-keyboard_arrow_down);
          right: var(--spacing-sm);
          pointer-events: none;
          position: absolute;
        }
      }
    }

    .cmp-tabs__tablist {
      display: flex;
      flex-wrap: wrap;
      width: 25%;
      height: 100%;

      @media #{$mq-mobile} {
        width: 100%;
        height: unset;
        position: absolute;
        z-index: 3;
        background-color: var(--color-white);
        display: none;
      }

      &::after {
        content: none;
      }

      .cmp-tabs__tab {
        padding: var(--spacing-sm) var(--spacing-lg);
        margin: 0;
        border: var(--spacing-xxxs) solid var(--color-primary-500);
        border-right-width: 0.25rem;
        width: 100%;
        height: 100%;
        white-space: wrap;
        display: flex;
        height: toRem(60px);
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        align-self: stretch;
        cursor: pointer;

        &::after {
          content: none;
        }

        @media #{$mq-mobile} {
          border: var(--spacing-xxxs) solid var(--color-primary-500);

          span {
            &::after {
              @include regular-3xl();

              font-family: var(--icon-font);
              content: var(--icon-close);
              right: var(--spacing-sm);
              top: var(--spacing-sm);
              position: absolute;
              color: var(--color-black);
            }
          }
        }

        &.cmp-tabs__tab--active {
          @media #{$mq-mobile} {
            span {
              &::after {
                color: var(--color-white);
              }
            }
          }
        }
      }

      &.cmp-tabs__tab--expanded {
        @media #{$mq-mobile} {
          display: flex;
        }
      }
    }

    &:has(.title-v2) .cmp-tabs__tablist {
      @media #{$mq-mobile} {
        top: toRem(45px);
      }
    }

    .cmp-tabs__tabpanel {
      padding-top: 0;
      padding-left: var(--spacing-md);
      width: 75%;
      height: 100%;

      @media #{$mq-mobile} {
        padding: var(--spacing-lg) var(--spacing-xs);
        width: 100%;
        height: 100%;

        .cmp-teasercontainer {
          .cmp-container {
            justify-content: center;
          }
        }
      }
    }
  }
}
