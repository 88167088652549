:root {
  --specialevent-color-dark: #b8cbcf;
  --specialevent-color-medium: #c4d4d6;
  --specialevent-color-light: #edf2f2;
}

.cmp-specialevent-container {
  position: relative;
  display: flex;

  @media #{$mq-1} {
    margin-top: var(--spacing-xl);
  }

  & > .cmp-container {
    flex: 1;
    width: 100%;
  }
}

.cmp-specialevent {
  position: relative;
  display: flex;
  padding: var(--spacing-xl);
  overflow: hidden;

  @media only screen and (--breakpoint-down-sm) {
    background-color: var(--color-white);
    padding: 0 0 var(--spacing-xl);
  }

  &-day {
    display: flex;
    align-self: flex-start;
    flex-basis: 0;
    margin: 0 0 var(--spacing-lg) var(--grid-anti-margin);

    @media only screen and (--breakpoint-down-sm) {
      flex-basis: 100%;
    }

    &__container {
      background-color: var(--color-primary-500);
      color: var(--color-white);
      padding: var(--spacing-xxs) var(--spacing-md);
      margin-right: var(--spacing-xl);
      margin-left: var(--spacing-lg);

      @include regular-lg;

      display: flex;
      align-items: center;

      @media only screen and (--breakpoint-down-sm) {
        margin-left: 0;
      }

      &--label {
        margin-right: var(--spacing-xs);
        text-transform: capitalize;
        width: max-content;
      }

      &--day {
        @include bold-5xl;
      }
    }

    &__triangle-placeholder {
      display: flex;
      width: 100%;
      padding: 0 var(--grid-margin);
      flex-direction: column;
    }

    &__agenda-date {
      @include regular-xl;

      display: flex;
      align-items: flex-end;
      flex-basis: 100%;
    }
  }

  &--fullwidth-gradient {
    &::before {
      background-image: url('/src/main/webpack/resources/hitachiEventAgendaBackground.svg');
      background-position: center;
      background-size: cover;
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      height: 100%;
      left: var(--grid-anti-margin);
      width: calc(100% + var(--grid-margin) * 2);
      bottom: 0;

      @media only screen and (--breakpoint-down-xl) {
        left: var(--grid-anti-margin);
        width: calc(100% + var(--grid-margin) * 2);
      }

      @media only screen and (--breakpoint-down-lg) {
        left: var(--grid-anti-margin);
        width: calc(100% + var(--grid-margin) * 2);
      }

      @media only screen and (--breakpoint-down-sm) {
        left: var(--grid-anti-margin);
        width: calc(100% + var(--grid-margin) * 2);
      }
    }
  }

  &-agenda__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.cmp-specialevent-container__triangle-watermark {
  position: sticky;
  left: 0;
  margin: 3.125rem 0;
  width: 0;

  @media (--breakpoint-down-md) {
    display: none;
  }

  &--title {
    color: var(--color-gray-300);

    @include bold-7xl;

    writing-mode: tb-rl;
    transform: rotate(-180deg);
    position: absolute;
    right: 0;
    white-space: nowrap;
  }

  &--hidden {
    display: none;
  }
}
