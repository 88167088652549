/* search  */

.c-mapapp__search {
  align-items: flex-start;
  display: flex;
  margin-bottom: var(--spacing-md);
  position: relative;
}

.c-mapapp__search .icon-search {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacing-xxl);
  height: var(--spacing-xxl);
}

.c-mapapp__search-field {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: var(--spacing-md);
  position: relative;
}

.c-mapapp__search-input {
  border-radius: none;
  border-radius: 0; /* fix ios rounded search input */
  width: 100%;
  border: var(--spacing-xxxs) solid var(--color-gray-700);
  padding: calc(var(--spacing-xs) - var(--spacing-xxxs)) var(--spacing-xxl) calc(var(--spacing-xs) - var(--spacing-xxxs)) var(--spacing-md);
  line-height: var(--line-height);
  height: 2.5rem;
}

.c-mapapp__search-input:active {
  outline: var(--focus-outline);
}

/* button */
.c-mapapp__search-button {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
  margin: 0;
  width: calc(25% - var(--spacing-sm));
  overflow: visible;
  background: var(--color-primary-500);
  font: inherit;
  font-weight: var(--font-weight-bold);
  color: white;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
  padding: var(--spacing-xs) 0;
  min-width: 8.5rem;
}

.c-mapapp__search-button:hover {
  cursor: pointer;
}
