@import '../../../site/styles/foundations/media_queries.scss';

.cmp-the-wall-tab-list {
  padding-left: 0;
  max-width: 100%;
  display: flex;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  scroll-behavior: smooth;
}

@media #{$mq-1} {
  .cmp-the-wall-tab-list::-webkit-scrollbar {
    display: none;
  }
}
