.cmp-like-action {
  display: flex;
  gap: var(--spacing-sm);

  .cmp-button__icon {
    margin-left: var(--spacing-xs);
    color: var(--color-gray-900);
    pointer-events: initial;
    cursor: pointer;

    &:hover {
      color: var(--color-primary-700);
    }
  }

  &__text {
    color: var(--color-gray-900);
    cursor: initial;
  }

  &--active {
    .cmp-button__icon {
      color: var(--color-primary-500);
    }
  }
}
