/**
* Overrides for the plecs Angular APP
* Our input styles are currently overriden in the styles we include for the 3rd party plecs app
* these style properties, copied from form-option.css, provide for the checkbox display
**/

/* stylelint-disable selector-no-qualifying-type */
.plecs input[type='checkbox'],
.plecs input[type='radio'] {
  border: 0.125rem solid var(--color-gray-700);
  box-sizing: border-box;
  color: var(--color-gray-700);
  cursor: pointer;
  flex-shrink: 0;
  height: 1.125rem; /* 18 */
  margin-right: var(--spacing-sm);
  position: relative;
  vertical-align: -0.3125rem;
  width: 1.125rem; /* 18 */
  padding: 0;
  bottom: 0.25rem;
}

.plecs input[type='radio'] {
  border-radius: 100%;
}

.plecs input[type='checkbox']::before {
  @include bold-md();

  content: var(--icon-check);
  font-family: 'custom_materialicons', sans-serif;
  position: absolute;
  top: calc(var(--spacing-xxxs) * -1);
  visibility: hidden;
}

.plecs input[type='radio']::before {
  content: '';
  width: toRem(10px);
  height: toRem(10px);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: toRem(-5px);
  margin-left: toRem(-5px);
  display: block;
  border-radius: 100%;
  background-color: var(--color-gray-700);
  visibility: hidden;
}

.plecs input[type='checkbox']:checked::before,
.plecs input[type='radio']:checked::before {
  visibility: visible;
}

.plecs input[type='radio']:focus {
  outline: none;
  border-color: rgb(0 95 204);
}

.plecs .list-table tr {
  line-height: 1.5rem;
}
/* stylelint-enable selector-no-qualifying-type */
