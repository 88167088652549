.cmp-text-only-stage .cmp-newsinfo {
  margin-bottom: var(--spacing-md);
  justify-content: flex-start;
}

.cmp-text-only-stage .cmp-title__text {
  text-align: left;
}

/* policies */

/* center align */
.cmp-text-only-stage--center-align .cmp-text-only-stage .cmp-title__text {
  text-align: center;
}

.cmp-text-only-stage--center-align .cmp-text-only-stage .cmp-newsinfo {
  justify-content: center;
}
