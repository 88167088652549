.cmp-sectorteaser {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  position: relative;
  justify-content: center;
  z-index: 1;
  background: var(--color-gray-500);
}

.cmp-sectorteaser:hover {
  background: var(--color-primary-500);
  box-shadow: none;
  transform: scale(1.05);
  z-index: 2;
}

.cmp-sectorteaser:hover .cmp-sectorteaser__image {
  visibility: hidden;
}

.cmp-sectorteaser__image .cmp-image {
  position: relative;
}

.cmp-sectorteaser__image .cmp-image__title {
  display: none;
}

.cmp-sectorteaser__image .cmp-image:not(.cmp-image--responsive)::before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}

.cmp-sectorteaser__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.cmp-sectorteaser__content {
  padding: var(--spacing-md);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-xs);
  text-align: center;
}

.cmp-sectorteaser__image + .cmp-sectorteaser__content {
  position: absolute;
}

.cmp-sectorteaser__title {
  @include bold-md();

  align-items: flex-start;
  word-break: break-word;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  display: block;
}

.cmp-sectorteaser__title-icon {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0;
}

.cmp-sectorteaser__title-text {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

/** Teaser Aspect Ratio for Standard Teaser **/
.cmp-sectorteaser .cmp-responsive-image {
  position: relative;
}

.cmp-sectorteaser .cmp-responsive-image::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.cmp-sectorteaser .cmp-responsive-image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/** teaser sizing in edit mode **/
.cq-placeholder.cmp-sectorteaser {
  height: 100%;
}

.cmp-sectorteasercontainer .cmp-sectorteaser__image .cmp-image::before {
  padding-top: 100%;
}

.cmp-sectorteaser .cmp-button__icon {
  display: block;
  margin: 0 0 var(--spacing-xs);
  text-align: center;
  height: var(--spacing-xxxl);
  font-size: var(--spacing-xxxl);
  font-weight: var(--font-weight);
}

.cmp-sectorteaser .cmp-button__icon::before {
  top: var(--spacing-md);
  position: relative;
}
