@import '@react/common.scss';

.cmp-the-wall-teasers-item {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs);
  animation: wall-teaser-left 0.5s ease-in-out 0s 1 forwards;
  list-style: none;

  .icon-keyboard_arrow_right {
    color: var(--color-primary-500);

    &::before {
      animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: var(--box-shadow-md);

    .cmp-the-wall-teasers-item__text {
      color: var(--color-primary-700);
      text-decoration: underline;
    }

    .icon-keyboard_arrow_right {
      color: var(--color-primary-700);

      &::before {
        animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
      }
    }
  }

  &:active {
    .cmp-the-wall-teasers-item__text {
      color: var(--color-primary-800);
      text-decoration: underline;
    }

    .icon-keyboard_arrow_right {
      color: var(--color-primary-800);
    }
  }

  & .cmp-image img {
    height: fit-content;
    aspect-ratio: 1;
  }

  & .cmp-responsive-image.replaced img {
    position: static;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    max-width: 39rem;
    max-height: 39rem;
  }

  &__header {
    padding: var(--spacing-lg);
    display: flex;
  }

  &__text {
    color: var(--color-primary-500);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    word-break: break-word;

    @include bold-2xl();
  }
}

@media #{$mq-1} {
  .cmp-the-wall-teasers-item {
    min-width: 90%;
    flex-basis: 90%;
    scroll-snap-align: start;
  }

  .cmp-the-wall-teasers-item::-webkit-scrollbar {
    display: none;
  }
}
