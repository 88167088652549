@import '@react/common.scss';

.cmp-gallery-lightbox__slides {
  display: none;
}

.cmp-gallery-lightbox__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: var(--color-black-transparent);
  overflow: hidden;
  padding: var(--spacing-5xl);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cmp-gallery-lightbox__image-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
}

.cmp-gallery-lightbox__slides,
.cmp-gallery-lightbox__image-wrapper .cmp-responsive-image {
  height: 100%;
}

.cmp-gallery-lightbox__image-wrapper .cmp-responsive-image img {
  object-fit: contain;
}

.cmp-gallery-lightbox__image-wrapper .cmp-responsive-image.replaced img {
  position: unset;
}

.cmp-gallery-lightbox {
  position: relative;
  width: 100%;
  height: 100%;
}

.cmp-gallery-lightbox__image {
  width: 100%;
  height: 100%;
}

.cmp-gallery-lightbox__copyright {
  @include regular-xs();

  background: var(--color-white-transparent);
  padding: var(--spacing-xs) var(--spacing-sm);
  position: absolute;
  bottom: var(--spacing-xxs);
  right: var(--spacing-xxs);
}

.cmp-gallery-lightbox__image--info {
  position: absolute;
  top: 100%;
  background: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--spacing-sm);
}

.cmp-gallery-lightbox__caption {
  color: var(--color-gray-900);
}

.cmp-gallery-lightbox__download-link {
  color: var(--color-primary-500);
}

.cmp-gallery-lightbox__separator {
  margin: 0 var(--spacing-sm);
  color: var(--color-gray-300);
  opacity: 0.3;
}

.cmp-gallery-lightbox__link-counter {
  display: flex;
  align-items: center;
}

.cmp-gallery-lightbox__navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cmp-gallery-lightbox__arrow--is-previous,
.cmp-gallery-lightbox__arrow--is-next {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-sm);
  background: var(--color-black-transparent-lighter);
  cursor: pointer;
  width: var(--spacing-xxxl);
  height: var(--spacing-xxxl);
}

.cmp-gallery-lightbox__arrow--is-previous:hover,
.cmp-gallery-lightbox__arrow--is-next:hover {
  background-color: var(--color-primary-500);
}

.cmp-gallery-lightbox__arrow--is-previous .icon-keyboard_arrow_left::before,
.cmp-gallery-lightbox__arrow--is-next .icon-keyboard_arrow_right::before {
  @include regular-6xl;

  color: var(--color-white);
  margin-right: 0;
}

.cmp-gallery-lightbox__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-white);
  background: var(--color-black-transparent-lighter);
  padding: var(--spacing-sm);
  height: var(--spacing-xxxl);
  width: var(--spacing-xxxl);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cmp-gallery-lightbox__close-btn:hover {
  background: var(--color-primary-500);
}

.cmp-gallery-lightbox__close-btn [class*=' icon-']::before {
  margin-right: 0;

  @include regular-6xl;
}

.cmp-gallery-lightbox__slides--active {
  display: block;
}

@media #{$mq-2} and (orientation: landscape) {
  .cmp-gallery-lightbox__wrapper {
    padding: 0 var(--spacing-5xl);
  }
}

@media #{$mq-2} and (orientation: portrait) {
  .cmp-gallery-lightbox {
    height: fit-content;
    max-height: 100%;
  }

  .cmp-gallery-lightbox__image-wrapper {
    max-height: 23.4375rem;
    height: 23.4375rem;
  }

  .cmp-gallery-lightbox__copyright {
    bottom: var(--spacing-xxxs);
  }

  .cmp-gallery-lightbox__caption {
    margin-bottom: var(--spacing-sm);
  }

  .cmp-gallery-lightbox__image--info {
    bottom: unset;
  }
}

@media #{$mq-1} {
  .cmp-gallery-lightbox__wrapper {
    padding: 0;
  }

  .cmp-gallery-lightbox {
    height: fit-content;
    max-height: 100%;
  }

  .cmp-gallery-lightbox__image-wrapper {
    max-height: 13.125rem;
    height: 13.125rem;
  }

  .cmp-gallery-lightbox__copyright {
    bottom: var(--spacing-xxxs);
  }

  .cmp-gallery-lightbox__caption {
    margin-bottom: var(--spacing-sm);
  }

  .cmp-gallery-lightbox__image--info {
    bottom: unset;
    flex-direction: column;
  }
}
