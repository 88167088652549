/*
* AEM Component
* Embed
* used for instance by iframes for videos, google maps in tabs, angular apps, etc..
*/

@include remove-grid-content('.embed');

.cmp-embed {
  position: relative;
  display: flex;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
  }
}

/* stylepolicy to remove to aspect ratio dimentions */
.cmp-embed--aspect-ratio-none .cmp-embed::before {
  content: none;
}

.cmp-embed--aspect-ratio-none .cmp-embed iframe {
  position: static;
}
