@import '@react/common.scss';

.cmp-agenda-slider {
  &__transition-transform {
    transition: transform 0.5s;
  }

  @media only screen and (--breakpoint-down-md) {
    &__item--is-hidden {
      visibility: hidden;
      transition: visibility 0.3s;
    }
  }

  &__pagination {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    &-placeholder {
      width: 100%;
      padding: 0 var(--spacing-xxl);
    }

    &-page {
      opacity: 1;
      cursor: pointer;
      margin: 0;
      width: 100%;
      height: var(--spacing-xxs);
      background: var(--color-gray-300);

      &--is-active {
        background: var(--color-primary-500);
        opacity: 1;
      }
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--spacing-lg);
  }

  &__arrow {
    margin: 0;
    display: flex;
    cursor: pointer;
    background-color: var(--color-primary-500);
    color: var(--color-white);
    padding: var(--spacing-xxxs) var(--spacing-xxs);

    &::after {
      font-family: var(--icon-font);

      @include regular-3xl;
    }

    &:hover {
      color: var(--color-primary-500);
      background-color: var(--color-gray-50);
      cursor: pointer;
    }

    &--is-previous {
      margin-right: var(--spacing-sm);

      &::after {
        content: var(--icon-keyboard_arrow_left);
      }
    }

    &--is-next {
      margin-left: var(--spacing-sm);

      &::after {
        content: var(--icon-keyboard_arrow_right);
      }
    }
  }
}

@media (hover: none) {
  .cmp-agenda-slider__arrow:hover {
    background-color: var(--color-primary-500);
    color: var(--color-white);
  }
}
