.lightbox .cmp-embed::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  cursor: pointer;
}

.lightbox__overlay {
  display: none;
}

.lightbox__overlay--visible {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-black-transparent);
  z-index: 1000;
}

.lightbox__overlay::after {
  font-family: var(--icon-font);
  content: var(--icon-close);
  position: absolute;
  top: var(--spacing-lg);
  right: var(--spacing-lg);
  color: var(--color-white);
  cursor: pointer;
  font-size: var(--spacing-lg);
}

@media (--breakpoint-down-sm) {
  .lightbox__overlay::after {
    top: var(--spacing-sm);
    right: var(--spacing-sm);
    font-size: 1.375rem;
  }
}

.lightbox__content {
  display: flex;
  flex-direction: column;
  width: min-content;
}

.lightbox__content .cmp-responsive-image img {
  width: auto;
  object-fit: contain;
  max-width: 82vw;
  max-height: 82vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
}

.lightbox__content .cmp-image__title {
  @include regular-md();

  background: var(--color-white);
  color: var(--color-gray-900);
  padding: var(--spacing-lg);
  margin: 0;
}

@media (--breakpoint-down-sm) {
  .lightbox__content .cmp-image__title {
    padding: var(--spacing-sm);
  }
}

.lightbox__content iframe {
  height: calc((100vw - 2 * 8.6vw) * 0.5625);
  width: calc(100vw - 2 * 8.6vw);
}

.lightbox :not(.cmp-teaser__image) > .cmp-image {
  cursor: pointer;
}
