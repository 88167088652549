.cmp-slide-up-teaser__container {
  @media #{$mq-6} {
    margin: 0 var(--spacing-xxl);
  }

  &--is-regular {
    .cmp-slide-up-teaser__list {
      .cmp-slide-up-teaser__item {
      @media #{$mq-6} {
          width: 20%;
      }

      @media #{$mq-5} {
          width: 20%;
      }

      @media #{$mq-4} {
          width: 25%;
      }

      @media #{$mq-3} {
          width: 33.33%;
      }

      @media #{$mq-2} {
          width: 50%;
      }

      @media #{$mq-1} {
          width: 100%;
        }
      }
    }
  }

  &--is-large {
    .cmp-slide-up-teaser__list {
      .cmp-slide-up-teaser__item {
      @media #{$mq-6} {
          width: 33.33%;
      }

      @media #{$mq-5} {
          width: 33.33%;
      }

      @media #{$mq-4} {
          width: 33.33%;
      }

      @media #{$mq-3} {
          width: 50%;
      }

      @media #{$mq-2} {
          width: 100%;
      }

      @media #{$mq-1} {
          width: 100%;
        }
      }
    }
  }
}

.cmp-slide-up-teaser__button-wrapper {
  text-align: center;
  height: toRem(40px);

  .cmp-slide-up-teaser__button {
    @include bold-lg();

    border: var(--spacing-xxxs) solid var(--color-primary-500);
    color: var(--color-primary-500);
    background: var(--color-white);
    padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-xs) toRem(20px);
    transition: all 0.3s;

    .cmp-button__icon::before {
      transition: all 0.3s;
      position: relative;
      left: 0;
    }

    &:hover {
      background: var(--color-primary-500);
      color: var(--color-white);

      .icon-keyboard_arrow_right::before {
        color: var(--color-white);
        left: toRem(7px);
      }
    }
  }
}

.cmp-slide-up-teaser__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  margin: 0 calc(var(--spacing-sm) * -1) var(--spacing-lg);

  .cmp-slide-up-teaser__item {
    overflow: hidden;
    position: relative;
    height: 100%;

    @media #{$mq-desktop} {
      aspect-ratio: 1;
    }
  }

  .cmp-slide-up-teaser__item-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;

    &.image-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .cmp-slide-up-teaser__item-title {
    @include bold-lg();

    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 35%);
    transition: all 1s;
    color: var(--color-white);
    text-align: center;
    padding: toRem(20px);
  }

  .cmp-slide-up-teaser__item-info {
    background: var(--color-primary-500);
    color: var(--color-white);
    position: absolute;
    top: 100%;
    height: 100%;
    width: 100%;
    padding: toRem(20px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .cmp-slide-up-teaser__item-info-title {
      @include bold-lg();

      margin-bottom: var(--spacing-xs);
    }

    .cmp-slide-up-teaser__item-info-text {
      @include regular-md();

      margin-bottom: var(--spacing-xs);
    }

    .cmp-slide-up-teaser__item-info-link-text {
      @include bold-lg();
    }

    .cmp-slide-up-teaser__item-info-link:hover {
      transition: all 0.3s;

      .cmp-slide-up-teaser__item-info-link-text {
        border-bottom: var(--spacing-xxxs) solid var(--color-white);
      }

      .icon-keyboard_arrow_right::before {
        color: var(--color-white);
        position: relative;
        left: toRem(7px);
      }
    }
  }
}

// Media Queries
@media #{$mq-mobile} {
  .cmp-slide-up-teaser__list {
    .cmp-slide-up-teaser__item {
      aspect-ratio: 1;
    }
  }
}

// Media Queries
@media #{$mq-1} {
  .cmp-slide-up-teaser__list {
    display: flex;
    flex-direction: column;

    .cmp-slide-up-teaser__item {
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }

  .cmp-slide-up-teaser__button-wrapper .cmp-slide-up-teaser__button {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: left;
  }
}

/* Animation */
@media #{$mq-desktop} {
  .cmp-slide-up-teaser__item:hover > .cmp-slide-up-teaser__item-image {
    transition: all 1s;
    top: -100%;
  }

  .cmp-slide-up-teaser__item:hover > .cmp-slide-up-teaser__item-info {
    transition: all 1s;
    top: 0;
  }
}

// Going up
.cmp-slide-up-teaser__item--slide-up {
  .cmp-slide-up-teaser__item-image {
    transition: all 1s;
    top: -100%;
  }

  .cmp-slide-up-teaser__item-info {
    transition: all 1s;
    top: 0;
  }
}

// Going down

.cmp-slide-up-teaser__item--slide-down {
  .cmp-slide-up-teaser__item-image {
    transition: all 1s;
    top: 0;
  }

  .cmp-slide-up-teaser__item-info {
    transition: all 1s;
    top: 100%;
  }
}
