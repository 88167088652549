.cmp-interaction-toolbar {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: fixed;
  top: 30%;
  right: 0;
}

.cmp-interaction-toolbar__share,
.cmp-interaction-toolbar__like {
  width: 3rem;
  height: 3rem;
  background-color: var(--color-gray-700);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.cmp-interaction-toolbar__share {
  margin-bottom: var(--spacing-xxxs);
  min-width: 3rem;
  width: auto;
  display: flex;
  flex-flow: row;
  color: var(--color-white);
}

.cmp-interaction-toolbar__share .icon {
  height: 100%;
  padding-left: var(--spacing-sm);
  display: flex;
  align-items: center;
}

.cmp-interaction-toolbar__like--active {
  background: var(--color-primary-500);
}

.cmp-interaction-toolbar .icon {
  color: var(--color-white);
  font-size: var(--font-size-icon-md);
}

.cmp-interaction-toolbar .icon::before {
  margin-right: 0;
}

.cmp-interaction-toolbar__like-number {
  font-size: 0.75rem;
  line-height: var(--line-height-xs);
  color: var(--color-white);
  margin-bottom: var(--spacing-xxs);
}

.cmp-interaction-toolbar__share-slideout {
  display: flex;
  overflow: hidden;
  width: 0;
  align-items: center;
  transition: width 1s;
  padding-right: var(--spacing-sm);
  height: 100%;
}

.cmp-interaction-toolbar__share-slideout--visible .cmp-interaction-toolbar__share-slideout {
  background: var(--color-primary-500);
}

.cmp-interaction-toolbar__share--large.cmp-interaction-toolbar__share-slideout--visible .cmp-interaction-toolbar__share-slideout {
  width: 11.75rem;
}

.cmp-interaction-toolbar__share--small.cmp-interaction-toolbar__share-slideout--visible .cmp-interaction-toolbar__share-slideout {
  width: 8.75rem;
}

.cmp-interaction-toolbar__share-slideout--visible,
.cmp-interaction-toolbar__share-slideout--visible:hover {
  background: var(--color-primary-500);
}

.cmp-interaction-toolbar__share-slideout .icon {
  padding: var(--spacing-xxs) var(--spacing-sm);
  cursor: pointer;
}

.cmp-interaction-toolbar__share-slideout .cmp-interaction-toolbar__share-email + .cmp-interaction-toolbar__share-yammer {
  border-left: var(--spacing-xxxs) solid var(--color-white);
}

.cmp-interaction-toolbar__share-label {
  padding: 0 var(--spacing-sm);
  white-space: nowrap;
}

@media (--breakpoint-up-md) {
  .cmp-interaction-toolbar__like:hover,
  .cmp-interaction-toolbar__like:focus {
    background: var(--color-primary-700);
    cursor: pointer;
  }
}

@media (--breakpoint-down-md) {
  .cmp-interaction-toolbar {
    flex-flow: row;
    align-items: flex-start;
    bottom: 0;
    margin: 0 0 var(--spacing-lg) var(--spacing-lg);
    right: unset;
    top: unset;
    z-index: 4;
  }

  .cmp-interaction-toolbar__share {
    border-right: var(--spacing-xxxs) solid var(--color-white);
    z-index: 2;
  }

  .cmp-interaction-toolbar__like {
    z-index: 1;
  }

  .cmp-interaction-toolbar__share-slideout--visible,
  .cmp-interaction-toolbar__like--active {
    background: var(--color-primary-500);
  }
}
