@include remove-grid-content('.textonlystage');

.cmp-pagestage-text-only__video {
  height: 31.25rem;
  margin-bottom: var(--spacing-xxl);
  max-height: 56vw;
  overflow: hidden;
}

.cmp-pagestage-text-only__video.cmp-videocontainer__video--parallax-vertical_scrolling {
  overflow: hidden;
}

.cmp-pagestage-text-only .cmp-newsinfo {
  margin-bottom: var(--spacing-md);
}

.cmp-pagestage-text-only .cmp-title__text {
  text-align: center;
}

.cmp-pagestage-text-only .container.aem-GridColumn.cmp-pagestage-text-only__social-container {
  float: right;
  margin-bottom: calc(var(--spacing-lg) * -1);
}

.cmp-pagestage-text-only .cmp-responsive-image.cmp-responsive-image--eager {
  position: relative;
  height: var(--stagehero-homepage-height);
  max-height: var(--stagehero-homepage-max-height);
}

.cmp-pagestage-text-only .cmp-image__image {
  max-height: 66vh;
  object-fit: cover;
}

.cmp-pagestage-text-only .cmp-responsive-bg-image--eager.parallax.parallax--vertical_scroll,
.cmp-pagestage-text-only .cmp-responsive-bg-image--lazy.parallax.parallax--vertical_scroll.replaced {
  max-height: 66vh;
}

.cmp-pagestage-text-only .cmp-responsive-image,
.cmp-pagestage-text-only .cmp-responsive-bg-image {
  margin-bottom: var(--spacing-4xl);
  max-height: 66vh;
  overflow: hidden;
}

.cmp-pagestage-text-only .cmp-pagestage-text-only__description-container::after {
  content: '';
  display: block;
  padding-top: var(--spacing-4xl);
}

@media (--breakpoint-down-md) {
  .cmp-pagestage-text-only .container.aem-GridColumn.cmp-pagestage-text-only__social-container {
    float: left;
    margin-top: calc(var(--spacing-lg) * -1);
    margin-bottom: var(--spacing-lg);
  }

  .cmp-pagestage-text-only .cmp-pagestage-text-only__description-container::after {
    padding-top: var(--spacing-xl);
  }

  .cmp-pagestage-text-only .cmp-responsive-image.cmp-responsive-image--eager {
    height: 100%;
    margin-bottom: var(--spacing-xxxl);
  }

  .cmp-pagestage-text-only .cmp-title__text {
    @include regular-xl();

    text-align: left;
  }

  .cmp-pagestage-text-only .cmp-newsinfo {
    justify-content: flex-start;
    margin-bottom: var(--spacing-sm);
  }
}

/* policies */

/* layout left */
.cmp-pagestage-text-only.cmp-pagestage-text-only--content-alignment-left .cmp-title__text {
  text-align: left;
}

.cmp-pagestage-text-only.cmp-pagestage-text-only--content-alignment-left .container.cmp-pagestage-text-only__heading-container {
  width: calc(100% / 12 * 10);
  margin-left: 0;
}

.cmp-pagestage-text-only.cmp-pagestage-text-only--content-alignment-left .cmp-newsinfo {
  margin-left: 0;
  justify-content: flex-start;
}

.cmp-pagestage-text-only.cmp-pagestage-text-only--content-alignment-left .container.cmp-pagestage-text-only__description-container {
  width: calc(100% / 12 * 8);
  margin-left: 0;
}

.cmp-pagestage-text-only.cmp-pagestage-text-only--content-alignment-left .container.cmp-pagestage-text-only__social-container {
  margin-left: calc(100% / 12 * 2);
}

/* align center */
.cmp-pagestage-text-only--align-center .cmp-pagestage-text-only .cmp-title__text {
  text-align: center;
}

.cmp-pagestage-text-only--align-center .cmp-pagestage-text-only .cmp-newsinfo {
  justify-content: center;
}

.cmp-pagestage-text-only {
  position: relative;
}

.cmp-pagestage-text-only__watermark-title {
  @include bold-8xl();

  color: var(--color-gray-100);
  text-transform: uppercase;
  writing-mode: vertical-rl;
  position: absolute;
  top: 100%;
  left: 0;
  white-space: nowrap;
}

@media (--breakpoint-down-md) {
  .cmp-pagestage-text-only__watermark-title {
    display: none;
  }
}
