/* shortlist toggle button */
.c-shortlist {
  display: block;
  padding: 0 0 var(--spacing-xxl);
}

.c-shortlist__toggle {
  margin-top: var(--spacing-xxs);
}

.c-shortlist__toggle span {
  display: inline-flex;
  align-items: center;
}

.c-shortlist__toggle > span {
  display: none;
}

.c-shortlist__toggle[value='add'] .c-shortlist__toggle-add {
  display: inline-flex;
}

.c-shortlist__toggle[value='remove'] .c-shortlist__toggle-remove {
  display: inline-flex;
}
