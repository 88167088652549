@include remove-grid-content('.videocontainer');

.cmp-videocontainer {
  overflow: hidden;
  position: relative;
  max-height: 66vh;
  margin-bottom: var(--spacing-4xl);

  &::before {
    content: '';
    display: block;
    width: var(--spacing-xxxs);
    margin-left: calc(-1 * var(--spacing-xxxs));
    float: left;
    padding-top: 56.25%;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  &__video {
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    object-fit: cover;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &__video--crop-top {
    top: 0;
  }

  &__video--crop-middle {
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__video--crop-bottom {
    bottom: 0;
  }

  &__dynamicmediaplayer {
    width: 100%;
    height: auto;
    max-height: 75vh;
    object-fit: cover;
  }

  &__dynamicmediaplayer video {
    transform: scale(1.05, 1.05);
  }

  &__dynamicmediaplayer.s7videoviewer .s7socialshare,
  &__dynamicmediaplayer.s7videoviewer .s7controlbar {
    display: none;
  }
}

@media (--breakpoint-down-md) {
  .cmp-videocontainer {
    margin-bottom: var(--spacing-xl);
  }

  .cmp-videocontainer__video--parallax-scroll_mask .cmp-videocontainer__dynamicmediaplayer {
    min-height: 100vh;
    min-width: 100vw;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.cq-Editable-dom--container .videocontainer {
  max-height: 34.375rem;
}

.cq-Editable-dom--container .cmp-videocontainer,
.cq-Editable-dom--container .cmp-videocontainer .s7container {
  max-height: 31.25rem;
}

video {
  width: 100%;
}
