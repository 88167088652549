/**
 * List - Component
 */

.cmp-list {
  list-style: none;
}

.cmp-list__item {
  display: flex;
  color: var(--color-primary-500);
}

.cmp-list__item:not(:last-child) {
  margin-bottom: var(--spacing-md);
}

.cmp-list__item-link {
  display: inline-flex;
  color: var(--color-primary-500);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  transition: transform 0.2s ease-in-out;

  &::before {
    font-family: 'custom_materialicons', sans-serif;
    content: var(--icon-keyboard_arrow_right);
    font-weight: var(--font-weight-bold);
    margin-right: var(--spacing-xxs);
    animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
  }

  &:hover,
  &:active {
    text-decoration: none;

    .cmp-list__item-title {
      text-decoration: underline;
    }

    &::before {
      animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
    }
  }

  &:hover {
    color: var(--color-gray-900);
  }

  .link_external::before {
    content: var(--icon-link);
  }
}
