/**
 * Linklist -Component
 */

.cmp-linklist__heading {
  @include regular-lg();

  align-items: flex-end;
  border-bottom: 0.25rem solid var(--color-primary-500);
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-sm);
}

.cmp-linklist__title {
  @include bold-lg();

  color: var(--color-gray-900);
  display: flex;
  align-items: center;
  margin: 0;
}

.cmp-linklist__global {
  display: inline-block;
  height: 100%;
  text-align: right;
}

.cmp-linklist__global-link {
  color: var(--color-primary-500);
  white-space: nowrap;
  display: inline-flex;
  transition: transform 0.1s ease-in-out;

  .cmp-linklist__global-icon,
  .cmp-linklist__item-link-icon {
    animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
  }

  &:hover {
    color: var(--color-gray-900);

    .cmp-linklist__global-icon,
    .cmp-linklist__item-link-icon {
      animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
    }
  }
}

.cmp-linklist__global-link .cmp-linklist__global-icon,
.cmp-linklist__item-link .cmp-linklist__item-link-icon {
  display: inline-block;
}

.cmp-linklist__items--card .cmp-linklist__item-link-icon,
.cmp-linklist__items--card .cmp-linklist__item-link .icon-keyboard_arrow_right,
.cmp-linklist__items--card .cmp-linklist__item-link .icon-description {
  display: none;
}

.cmp-linklist__items--featured .cmp-linklist__item-link-text {
  align-self: flex-end;
  margin-bottom: 0.125rem;
}

.cmp-linklist__global-link:hover .cmp-linklist__global-link-text,
.cmp-linklist__item-link:hover .cmp-linklist__item-link-text {
  text-decoration: underline;
}

.cmp-linklist__global-link .cmp-linklist__global-icon::before {
  font-weight: var(--font-weight-bold);
}

.cmp-linklist__grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: calc(-1 * var(--spacing-lg));
}

.cmp-linklist__col {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: var(--spacing-lg) var(--spacing-lg) var(--spacing-lg) 0;
  width: 100%;
  box-shadow: var(--box-shadow);
}

/* link-item */
.cmp-linklist__item {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  position: relative;
}

.cmp-linklist__item + .cmp-linklist__item {
  margin-top: var(--spacing-lg);
}

.cmp-linklist__item-image {
  overflow: auto;
}

.cmp-linklist__item-image .cmp-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.cmp-linklist__item-image .cmp-responsive-image img,
.cmp-linklist__item-image .cmp-image__title {
  position: absolute;
}

.cmp-linklist__item-image .cmp-image::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.cmp-linklist__item-image .cmp-responsive-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cmp-linklist__item-meta {
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  width: 100%;
}

.cmp-linklist__item-date,
.cmp-linklist__item-location,
.cmp-linklist__item-readingTime {
  @include regular-xs();

  color: var(--color-gray-900);
  letter-spacing: 0;
}

.cmp-linklist__item-location {
  text-transform: uppercase;
}

.cmp-linklist__item-link {
  @include bold-lg();

  color: var(--color-primary-500);
  display: inline-flex;
  transition: transform 0.1s ease-in-out;

  .icon-keyboard_arrow_right {
    animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
  }

  &:hover {
    color: var(--color-primary-700);

    .icon-keyboard_arrow_right {
      animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
    }
  }

  &:active {
    color: var(--color-primary-800);
  }
}

.cmp-linklist__item-desc {
  @include regular-lg();

  color: var(--color-gray-900);
}

.cmp-linklist__item-title {
  @include bold-lg();

  margin: 0;
}

/* Image position - Policies */
.cmp-linklist__item-image.cq-Editable-dom,
.cmp-linklist__item-image .cq-dd-image {
  min-height: 3.125rem; /* 50 */
}

.cmp-linklist__items--reverse .cmp-linklist__item-meta {
  display: flex;
  flex-direction: column-reverse;
}

.cmp-linklist__items--reverse .cmp-linklist__item-link {
  margin-top: var(--spacing-xs);
}

.cmp-linklist__items--reverse .cmp-linklist__item-link .cmp-linklist__item-link-icon {
  display: block;
}

.cmp-linklist__items--with-shadow .cmp-linklist__item {
  box-shadow: var(--box-shadow);
}

.cmp-linklist__item:hover {
  box-shadow: var(--box-shadow-md);
}

.cmp-linklist__items--card .cmp-linklist__item {
  min-height: 6.25rem; /* 100 */
  overflow: hidden;
}

.cmp-linklist__items--card .cmp-image {
  position: initial;
}

.cmp-linklist__items--card .cmp-image::before {
  display: none;
}

.cmp-linklist__items--card .cmp-linklist__item-meta {
  align-items: center;
  background: linear-gradient(to right, rgb(0 0 0 / 15%), rgb(0 0 0 / 65%));
  display: flex;
  flex-grow: 1;
  justify-content: center;
  z-index: 1;
  text-align: center;
}

.cmp-linklist__items--card .cmp-linklist__item-link {
  color: var(--color-white);
}

.cmp-linklist__items--card .cmp-linklist__item-link .cmp-linklist__item-link-icon,
.cmp-linklist__items--card .cmp-linklist__item-desc,
.cmp-linklist__items--card .cmp-linklist__item-date {
  display: none;
}

.cmp-linklist__image--left:not(.cmp-linklist__items--card) .cmp-linklist__item {
  flex-direction: row;
}

.cmp-linklist__image--left:not(.cmp-linklist__items--card) .cmp-linklist__item-image {
  padding: var(--spacing-md) 0;
  flex: 1 0 auto;
  width: 40%;
}

.cmp-linklist__image--left:not(.cmp-linklist__items--card) .cmp-image {
  height: initial;
}

.cmp-linklist__items--reverse.cmp-linklist__items--with-shadow .cmp-linklist__item-date {
  margin-bottom: var(--spacing-xs);
}

.linklist:not(.cmp-linklist__items--card) .cmp-linklist__item-image-badge {
  @include regular-md();

  position: absolute;
  top: 0;
  right: 0;
  background: var(--color-gray-700);
  color: var(--color-white);
  padding: var(--spacing-xxs) var(--spacing-sm);
  display: inline-block;
  z-index: 1;
}

.cmp-linklist__items--featured .cmp-linklist__item-date:last-of-type,
.cmp-linklist__item-link .cmp-linklist__item-link-icon:first-of-type,
.cmp-linklist__items--featured .cmp-linklist__item-link .cmp-linklist__item-link-icon:last-of-type,
.cmp-linklist__item-details,
.cmp-linklist__items--featured .cmp-linklist__item-desc {
  display: none;
}

.cmp-linklist__items--featured .cmp-linklist__item-link .cmp-linklist__item-link-icon:first-of-type {
  @include regular-3xl();
}

.linklist:not(.cmp-linklist__items--card).cmp-linklist__items--featured .cmp-linklist__item-details,
.linklist:not(.cmp-linklist__items--card).cmp-linklist__items--featured .cmp-linklist__item-link .cmp-linklist__item-link-icon:first-of-type {
  display: block;
}

.cmp-linklist__items--featured .cmp-linklist__item-details p {
  margin-bottom: var(--spacing-xxs);
  display: inline-block;
}

.cmp-teaser__meta-separator {
  vertical-align: middle;
}

/* Gutters */
@media (--breakpoint-down-lg) {
  .cmp-linklist__grid {
    margin-right: calc(-1 * var(--spacing-sm));
  }

  .cmp-linklist__col {
    margin: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) 0;
  }
}

/* Mobile - styles */
@media (--breakpoint-down-md) {
  .cmp-linklist__grid {
    flex-direction: column;
    margin: 0;
  }

  .cmp-linklist__col {
    margin-bottom: 0;
    margin-right: 0;
  }
}
