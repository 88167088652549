@import '@react/common.scss';

.countdown-timer {
  display: flex;
  justify-content: center;
  background-color: var(--color-primary-500);
  width: calc(100% + var(--grid-margin) * 2);
  margin-left: var(--grid-anti-margin);
}

.countdown-timer__title {
  text-align: center;
}

.countdown-timer__block {
  justify-content: center;
  margin: var(--spacing-xl) 0;
  padding: 0 var(--spacing-xl);
  text-align: center;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  border-right: var(--spacing-xxxs) solid var(--color-white);
}

.countdown-timer__block:last-of-type {
  border-right: 0;
}

.countdown-timer__digit {
  font-size: 4rem;
  line-height: 0.75;
  margin-bottom: var(--spacing-xs);
}

.countdown-timer__text {
  line-height: 1;
}

@media (--breakpoint-down-xl) {
  .countdown-timer {
    width: calc(100% + var(--grid-margin) * 2);
    margin-left: var(--grid-anti-margin);
  }
}

@media (--breakpoint-down-lg) {
  .countdown-timer {
    width: calc(100% + var(--grid-margin) * 2);
    margin-left: var(--grid-anti-margin);
  }
}

@media (--breakpoint-down-sm) {
  .countdown-timer {
    width: calc(100% + var(--grid-margin) * 2);
    margin-left: var(--grid-anti-margin);
  }

  .countdown-timer__block {
    padding: 0 var(--spacing-md);
  }

  .countdown-timer__digit {
    @include bold-6xl();
  }

  .countdown-timer__text {
    @include bold-lg();
  }
}

.countdown-wrapper {
  display: none;

  &--is-visible {
    display: block;

    .countdown-wrapper {
      display: block;
    }
  }
}
