/* AEM author mode specific styles */
.cq-Editable-dom.marketingbanner {
  display: flex;
  width: 100%;
}

.cq-Editable-dom .cmp-marketing-banners {
  max-height: 40rem;
  min-height: auto;
  width: 100%;
  height: 100%;
}

.cq-Editable-dom .cmp-marketing-banners.cmp-marketing-banners--three {
  max-height: 100rem;
}

.cq-Editable-dom .cmp-marketing-banner,
.cq-Editable-dom .cmp-marketing-banners--one .cmp-marketing-banner,
.cq-Editable-dom .cmp-marketing-banners--three .cmp-marketing-banner {
  height: 100%;
}

/* --- */

.cmp-marketing-banners {
  height: auto;
  overflow: hidden;
}

.cmp-marketing-banners--two {
  display: inline-flex;
  width: 100%;
}

.cmp-marketing-banners--three {
  display: flex;
  flex-wrap: wrap;
}

.cmp-marketing-banner {
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  min-height: 30rem;

  &:hover {
    cursor: pointer;
  }

  &:not(.cmp-marketing-banner--left-aligned) {
    &:hover {
      .cmp-marketing-banner-button {
        cursor: pointer;
        color: var(--color-primary-700);
        outline: var(--border-s) solid var(--color-primary-700);
      }
    }

    &:active {
      .cmp-marketing-banner-button {
        background-color: var(--color-primary-100);
        color: var(--color-primary-800);
        outline: var(--border-xs) solid var(--color-primary-800);
      }
    }

    .cmp-marketing-banner-title {
      margin-bottom: var(--spacing-lg);
    }
  }
}

.cmp-marketing-banners--one .cmp-marketing-banner {
  height: calc(100vh - 2.5rem);
  min-height: 40.625rem;
}

.cmp-marketing-banners--two .cmp-marketing-banner {
  width: calc(50% - (var(--spacing-xxs) / 2));
  min-height: 40.625rem;
}

.cmp-marketing-banners--three .cmp-marketing-banner {
  height: calc((50vh - 1.25rem) - (var(--spacing-sm)));
}

.cmp-marketing-banners--three .cmp-marketing-banner:nth-child(-n + 2) {
  width: calc(50% - (var(--spacing-xxs) / 2));
  margin-bottom: var(--spacing-xxs);
}

.cmp-marketing-banners--three .cmp-marketing-banner:last-child {
  width: 100%;
}

.cmp-marketing-banners--two .cmp-marketing-banner:first-child,
.cmp-marketing-banners--three .cmp-marketing-banner:first-child {
  margin-right: var(--spacing-xxs);
}

.cmp-marketing-banner-image {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.cmp-marketing-banner:hover .cmp-marketing-banner-image {
  cursor: pointer;
  transform: scale(1.05);
}

.cmp-marketing-banner-clickable {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cmp-marketing-banner-content {
  z-index: 2;
  margin: 0 auto;
  text-align: center;
  padding: var(--spacing-6xl) var(--spacing-6xl) var(--spacing-5xl) var(--spacing-6xl);
  width: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 0.01%) 0%, rgb(0 0 0 / 0%) 0.01%, rgb(0 0 0 / 75%) 100%);
}

.cmp-marketing-banners--two > .cmp-marketing-banner > .cmp-marketing-banner-content,
.cmp-marketing-banners--three > .cmp-marketing-banner:not(:last-child) > .cmp-marketing-banner-content {
  padding: var(--spacing-6xl) var(--spacing-xxxl) var(--spacing-5xl) var(--spacing-xxxl);
}

.cmp-marketing-banner-title {
  color: var(--color-white);

  @include setLineClamp(4);
  @include heading-1();
}

.cmp-marketing-banner-button {
  background-color: var(--color-white);
  padding: var(--spacing-xs) toRem(20px);
  display: inline-flex;
  color: var(--color-primary-500);
  outline: var(--border-xs) solid var(--color-primary-500);
  font-weight: var(--font-weight-bold);
}

.cmp-marketing-banner__brandingPattern {
  @include brandingPattern;
}

@media (--breakpoint-down-md) {
  .cmp-marketing-banners {
    min-height: calc(100vh - 3rem);
  }

  .cmp-marketing-banners--two,
  .cmp-marketing-banners--three {
    display: flex;
    flex-flow: column;
    height: auto;
    padding-top: 0;
  }

  .cmp-marketing-banners--two .cmp-marketing-banner,
  .cmp-marketing-banners--three .cmp-marketing-banner:nth-child(-n + 2) {
    width: 100%;
  }

  .cmp-marketing-banners--two .cmp-marketing-banner:first-child,
  .cmp-marketing-banners--three .cmp-marketing-banner:nth-child(-n + 2) {
    margin-bottom: var(--spacing-xxs);
  }

  .cmp-marketing-banners--one .cmp-marketing-banner,
  .cmp-marketing-banners--two .cmp-marketing-banner,
  .cmp-marketing-banners--three .cmp-marketing-banner {
    height: calc(100vh - 3rem);
  }

  .cmp-marketing-banner:hover .cmp-marketing-banner-image {
    transform: unset;
  }

  .cmp-marketing-banner-content {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    height: calc(100% - (2 * var(--spacing-xxxl)));
    padding: var(--spacing-4xl) var(--spacing-lg) var(--spacing-xxxl) var(--spacing-lg);
  }

  .cmp-marketing-banners--two > .cmp-marketing-banner > .cmp-marketing-banner-content,
  .cmp-marketing-banners--three > .cmp-marketing-banner:not(:last-child) > .cmp-marketing-banner-content {
    padding: var(--spacing-4xl) var(--spacing-lg) var(--spacing-xxxl) var(--spacing-lg);
  }

  .cmp-marketing-banner-title {
    align-self: flex-end;
    width: 100%;

    @include setLineClamp(11);
  }

  .cmp-marketing-banner:not(.cmp-marketing-banner--left-aligned) .cmp-marketing-banner-button {
    align-self: flex-end;
  }

  .cmp-marketing-banner-clickable {
    z-index: 1;
  }
}

.cmp-marketing-banner--left-aligned {
  margin-top: var(--spacing-xxxl);
  margin-bottom: var(--spacing-xxxl);

  .cmp-margeting-banner-content--text {
    margin-bottom: var(--spacing-xxl);
    text-align: left;
  }

  .cmp-marketing-banner-content-wrapper {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 20%);
    padding: 0 var(--spacing-xxl);

    @media #{$mq-mobile} {
      padding: var(--spacing-lg);
    }
  }

  .cmp-marketing-banner-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    background: none;
    color: var(--color-white);
    margin: 0;
    padding: 0;

    @media #{$mq-desktop} {
      width: 50%;
    }

    @media #{$mq-mobile} {
      flex-wrap: nowrap;
      justify-content: space-between;
      height: 100%;
    }

    .cmp-marketing-banner-title {
      margin-bottom: var(--spacing-sm);

      @include setLineClamp(4);
    }

    p {
      padding-bottom: var(--spacing-md);

      @include setLineClamp(4);
      @include regular-xl();
    }
  }

  .cmp-margeting-banner-content--buttons-wrapper {
    display: flex;
    gap: var(--spacing-lg);

    .cmp-marketing-banner-button {
      width: max-content;

      &:hover {
        color: var(--color-primary-700);
        outline: var(--border-s) solid var(--color-primary-700);
      }

      &:active {
        background-color: var(--color-primary-100);
        color: var(--color-primary-800);
        outline: var(--border-xs) solid var(--color-primary-800);
      }
    }

    @media #{$mq-mobile} {
      flex-direction: column;
      align-items: center;
    }
  }
}

.cmp-container .u-full-width:last-child:has(.cmp-marketing-banners) {
  margin-bottom: var(--spacing-xxxl);
}

.cmp-container .cmp-left-aligned-marketing-banner:has(+ .carousel) .cmp-marketing-banner--left-aligned {
  margin-bottom: var(--spacing-lg);
}

.cmp-container .carousel + .cmp-left-aligned-marketing-banner .cmp-marketing-banner--left-aligned {
  margin-top: 0;
}
