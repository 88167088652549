@import '@react/common.scss';

.cmp-comments {
  padding: var(--spacing-md) 0;

  &__title {
    @include bold-xl;

    margin-bottom: var(--spacing-sm);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__counter {
      margin-top: var(--spacing-xxxs);
    }
  }

  &__load-more {
    border: var(--spacing-xxxs) solid var(--color-primary-500);
    color: var(--color-primary-500);
    padding: var(--spacing-xs) var(--spacing-lg);
    align-items: center;
    display: inline-flex;
    margin-left: 50%;
    margin-top: var(--spacing-lg);

    @include bold-lg;
  }
}
