.cmp-sharing {
  margin-bottom: var(--spacing-lg);
}

@media #{$mq-mobile} {
  .cmp-sharing {
    margin-bottom: var(--spacing-md);
  }
}

.cmp-sharing .cmp-sharing__title {
  @include regular-md();

  color: var(--color-gray-900);
  margin-bottom: var(--spacing-xs);
}

.cmp-sharing__links,
.cmp-sharing__link {
  display: flex;
  height: var(--icon-height);
}

.cmp-sharing__link {
  height: var(--icon-height);
  width: toRem(36px);

  @include transition-transform;

  &:hover {
    @include link-icon-hover;
  }
}

.cmp-sharing__icon.cmp-button__icon {
  margin-right: var(--spacing-sm);
  color: var(--color-black);
  height: var(--icon-height);
  width: var(--icon-width);
}
