@import '@react/common.scss';

.cmp-comments {
  &__sorting {
    position: relative;
    width: var(--spacing-10xl);

    &__name {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__label {
      display: flex;
      width: 100%;
      padding: var(--spacing-xs) 0 var(--spacing-xxs);
      color: var(--color-gray-900);

      @include regular-lg;
    }

    &__options {
      list-style-type: none;
      padding: 0;
      margin: 0;
      border: var(--spacing-xxxs) solid var(--color-gray-50);
      position: absolute;
      z-index: 1;
      background: var(--color-white);

      @include regular-lg;
    }

    &__option {
      cursor: pointer;
      padding: var(--spacing-sm);

      &--current {
        background: var(--color-gray-50);
      }
    }
  }
}
