:root {
  --big-numbers-border: var(--border-xl);
}

@include remove-grid-content('.bignumbers');

.cmp-bignumbers {
  text-align: center;
  margin: 0 0 var(--spacing-4xl) 0;

  &--initialised,
  &__number--no-animation {
    opacity: 1;
  }

  &__number {
    color: var(--color-primary-500);

    @include heading-1;
  }

  &--left-column,
  &--right-column,
  &--center {
    @include bold-md;
  }
}

@media #{$mq-desktop} {
  .cmp-bignumbers {
    &--left-column,
    &--right-column {
      padding: var(--spacing-md) 0;
    }

    &--left-column {
      border-right: var(--big-numbers-border) solid var(--color-primary-500);
      padding-right: var(--spacing-sm);
      text-align: right;
    }

    &--right-column {
      border-left: var(--big-numbers-border) solid var(--color-primary-500);
      padding-left: var(--spacing-sm);
      text-align: left;
    }
  }
}
