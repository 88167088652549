.cmp-pagestage-small .sharing {
  float: right;
}

.cmp-pagestage-small .cmp-pagestage-small__container-social.aem-GridColumn.container {
  float: right;
}

.cmp-pagestage-small__container-description::after {
  content: '';
  display: block;
  padding-top: var(--spacing-4xl);
}

@media (--breakpoint-down-md) {
  .cmp-pagestage-small .cmp-pagestage-small__container-social.aem-GridColumn.container {
    float: none;
  }

  .cmp-pagestage-small .sharing {
    float: none;
  }

  .cmp-pagestage-small__container-description::after {
    padding-top: var(--spacing-xl);
  }
}

@media (--breakpoint-down-sm) {
  .cmp-pagestage-small .cmp-container--full-width .cmp-banner-cta__wrapper {
    height: var(--pagestage-medium-height);
  }
}
