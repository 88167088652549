.cmp-pictogram-text {
  display: flex;
  margin-bottom: var(--spacing-lg);

  @media #{$mq-mobile} {
    margin-bottom: var(--spacing-md);
  }

  &--is-large-size {
    .cmp-pictogram-text__content {
      @media #{$mq-6} {
        width: grid-spacing(6, 6, 5);
      }

      @media #{$mq-5} {
        width: grid-spacing(5, 6, 5);
      }

      @media #{$mq-4} {
        width: grid-spacing(4, 6, 5);
      }

      @media #{$mq-3} {
        width: grid-spacing(3, 6, 5);
      }

      @media #{$mq-2} {
        width: grid-spacing(2, 6, 5);
      }

      @media #{$mq-1} {
        width: grid-spacing(1, 3, 2);
      }
    }

    .cmp-pictogram-text__image {
      @media #{$mq-6} {
        width: grid-spacing(6, 2, 1);
      }

      @media #{$mq-5} {
        width: grid-spacing(5, 2, 1);
      }

      @media #{$mq-4} {
        width: grid-spacing(4, 2, 1);
      }

      @media #{$mq-3} {
        width: grid-spacing(3, 2, 1);
      }

      @media #{$mq-2} {
        width: grid-spacing(2, 2, 1);
      }

      @media #{$mq-1} {
        width: grid-spacing(1, 1);
      }

      .cmp-responsive-image.replaced img {
        @media #{$mq-mobile} {
          height: toRem(44px);
        }

        @media #{$mq-desktop} {
          height: toRem(102px);
        }
      }
    }
  }

  &--is-small-size {
    .cmp-pictogram-text__content {
      @media #{$mq-6} {
        width: grid-spacing(6, 7, 6);
      }

      @media #{$mq-5} {
        width: grid-spacing(5, 7, 6);
      }

      @media #{$mq-4} {
        width: grid-spacing(4, 7, 6);
      }

      @media #{$mq-3} {
        width: grid-spacing(3, 7, 6);
      }

      @media #{$mq-2} {
        width: grid-spacing(2, 7, 6);
      }

      @media #{$mq-1} {
        width: grid-spacing(1, 3, 2);
      }
    }

    .cmp-pictogram-text__image {
      .cmp-responsive-image.replaced img {
        object-fit: initial;
        height: toRem(44px);
      }

      @media #{$mq-6} {
        width: grid-spacing(6, 1);
      }

      @media #{$mq-5} {
        width: grid-spacing(5, 1);
      }

      @media #{$mq-4} {
        width: grid-spacing(4, 1);
      }

      @media #{$mq-3} {
        width: grid-spacing(3, 1);
      }

      @media #{$mq-2} {
        width: grid-spacing(2, 1);
      }

      @media #{$mq-1} {
        width: grid-spacing(1, 1);
      }
    }
  }

  .cmp-pictogram-text__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-sm);

    .cmp-pictogram-text__headline {
      @include bold-xl();

      @media #{$mq-mobile} {
        @include bold-lg();
      }
    }

    .cmp-pictogram-text__text {
      @include regular-lg();

      @media #{$mq-mobile} {
        @include regular-md();
      }
    }
  }

  .cmp-pictogram-text__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-lg);
  }

  .cmp-responsive-image.replaced img {
    width: initial;
    position: initial;
  }
}
