/**
 * Stage-Hero - Component
 */
:root {
  /* Carousel height - Desktop */
  --stage-height: 33vh;
  --stage-max-height: 22.87rem;
  --stage-min-height: 22.87rem;

  --stage-homepage-height: 66.6vh;
  --stage-homepage-max-height: 46.875rem; /* 750 */
  --stage-homepage-min-height: 22.87rem; /* 366 */
  --stage-img-max-height: 66vh;
}

.stage > .cmp-carousel {
  height: var(--stage-height);
  max-height: var(--stage-max-height);
  min-height: var(--stage-min-height);
}

/* Only desktop - styles */
@media (--breakpoint-up-sm) {
  .stage .cmp-carousel__content {
    height: var(--stage-height);
    max-height: var(--stage-max-height);
    min-height: var(--stage-min-height);
  }

  .stage .cmp-banner-cta__tall,
  .stage .cmp-banner-cta__tall .image,
  .stage .cmp-banner-cta__tall .cmp-image,
  .stage .cmp-banner-cta__tall .cmp-banner-cta__image,
  .stage .cmp-banner-cta__tall .cmp-banner-cta__video {
    height: var(--stage-height);
    max-height: var(--stage-max-height);
    min-height: var(--stage-min-height);
  }

  .stage .cmp-banner-cta__short,
  .stage .cmp-banner-cta__short .image,
  .stage .cmp-banner-cta__short .cmp-image,
  .stage .cmp-banner-cta__short .cmp-banner-cta__image,
  .stage .cmp-banner-cta__short .cmp-banner-cta__video {
    height: var(--stage-height);
    max-height: var(--stage-max-height);
    min-height: var(--stage-min-height);
  }

  .stage .cmp-carousel__item {
    max-height: var(--stage-max-height);
    min-height: var(--stage-min-height);
  }
}


/*
* home page style policy
*/
.cmp-stage--homepage .stage > .cmp-carousel {
  height: var(--stage-homepage-height);
  max-height: var(--stage-homepage-max-height);
  min-height: var(--stage-homepage-min-height);
}

/* Only desktop - styles */
@media (--breakpoint-up-sm) {
  .cmp-stage--homepage .cmp-carousel__content {
    height: var(--stage-homepage-height);
    max-height: var(--stage-homepage-max-height);
    min-height: var(--stage-homepage-min-height);
  }

  .cmp-stage--homepage .cmp-banner-cta__tall,
  .cmp-stage--homepage .cmp-banner-cta__tall .image,
  .cmp-stage--homepage .cmp-banner-cta__tall .cmp-image,
  .cmp-stage--homepage .cmp-banner-cta__tall .cmp-banner-cta__image,
  .cmp-stage--homepage .cmp-banner-cta__tall .cmp-banner-cta__video {
    height: var(--stage-homepage-height);
    max-height: var(--stage-homepage-max-height);
    min-height: var(--stage-homepage-min-height);
  }

  .cmp-stage--homepage .cmp-banner-cta__short,
  .cmp-stage--homepage .cmp-banner-cta__short .image,
  .cmp-stage--homepage .cmp-banner-cta__short .cmp-image,
  .cmp-stage--homepage .cmp-banner-cta__short .cmp-banner-cta__image,
  .cmp-stage--homepage .cmp-banner-cta__short .cmp-banner-cta__video {
    height: var(--stage-homepage-height);
    max-height: var(--stage-homepage-max-height);
    min-height: var(--stage-homepage-min-height);
  }

  .cmp-stage--homepage .cmp-carousel__item {
    max-height: var(--stage-homepage-max-height);
    min-height: var(--stage-homepage-min-height);
  }
}
