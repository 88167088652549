.cmp-taglist {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);

  &--is-clickable,
  &--is-non-clickable,
  &--is-teams-notifications {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    padding-left: 0;
  }

  &--is-teams-notifications {
    margin: var(--spacing-lg) 0;
    list-style: none;
  }

  &--is-abbreviated {
    max-height: calc(var(--spacing-5xl) - var(--spacing-xs));
    overflow: hidden;
  }
}
