.partnerdetails-page {
  padding: var(--spacing-xxxl) 0;
}

.partnerdetails-page .partner-contact__title {
  font-size: 1.625rem;
  line-height: var(--line-height-h3);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  margin: var(--spacing-lg) 0;
}

.partner-name {
  font-weight: var(--font-weight);
}

.partner-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partner-images img {
  width: 100%;
}

.partner-images__badge {
  max-width: 8.14rem;
  width: 100%;
  height: auto;
}

.partner-images__logo {
  max-width: 17.62rem;
  width: 100%;
  height: auto;
  margin-left: auto;
}

.partner-contact__info {
  display: flex;
  flex-flow: column;
  padding: var(--spacing-xs) 0;
}

.partner-contact__info span {
  font-weight: var(--font-weight-bold);
}

.partner-contact__info-url {
  color: var(--color-primary-500);
  text-decoration: underline;
}

.partner-description {
  max-width: 60%;
}

.partner-authorized-area {
  max-width: 70%;
}

.partner-authorized-area table td,
.partner-authorized-area table th {
  padding: var(--spacing-md) var(--spacing-lg);
  border: var(--spacing-xxxs) solid var(--color-gray-100);
}

.partner-authorized-area table th {
  background-color: var(--color-gray-50);
  font-weight: var(--font-weight);
}

@media (--breakpoint-down-md) {
  .partner-description {
    max-width: 80%;
  }

  .partner-authorized-area {
    max-width: 100%;
  }

  .partner-images__badge {
    max-width: 7.14rem;
  }

  .partner-images__logo {
    max-width: 13.62rem;
  }
}


@media (--breakpoint-down-sm) {
  .partner-description {
    max-width: 100%;
  }

  .partner-images__badge {
    max-width: 6.14rem;
  }

  .partner-images__logo {
    max-width: 10.62rem;
  }
}
