/*
  Override container ::after pseudo class
  to remove padding as unnecessary space
*/
/* stylelint-disable declaration-no-important */
.cmp-mediatextcontainer::after {
  padding: 0 !important;
}

.cmp-mediatextcontainer {
  padding: var(--spacing-lg) 0;
  margin-bottom: var(--spacing-4xl);
}

.cmp-mediatextcontainer .cmp-responsive-image {
  aspect-ratio: 16/9;
}

.media-text-block__container {
  width: 100%;
}

.media-text--bg-white {
  background: var(--color-white);
}

.media-text--bg-gray {
  margin-left: var(--grid-anti-margin);
  margin-right: var(--grid-anti-margin);
  background: var(--color-gray-50);
}

.media-text--bg-gray .media-text__container {
  margin-left: var(--grid-margin);
  margin-right: var(--grid-margin);
}

.cmp-mediatextcontainer .media-text-block__column-title,
.cmp-mediatextcontainer .media-text-block__column-text {
  margin-bottom: var(--spacing-sm);
  word-break: break-word;
  color: var(--color-gray-900);
}

.media-text-block__column-text a {
  display: inline-block;
  color: var(--color-primary-500);
  text-decoration: underline;
}

.cmp-media-text-container__title {
  @include regular-4xl();

  text-align: center;
}

.media-text-block__column-links {
  padding: 0;
  margin-bottom: 0;
}

.media-text-block__column-links .cmp-list__item:not(:last-of-type) {
  margin-bottom: var(--spacing-sm);
}

.media-text-blocks__container > .mediatext-block--large:last-child > .media-text-block__wrapper {
  margin-bottom: 0;
}

.media-text--large:not(.media-text-block--first-media-right) .mediatext-block--large:nth-child(even) .media-text-block__column:first-child,
.media-text--large:not(.media-text-block--first-media-right) .block:nth-child(even) .media-text-block__column:first-child,
.media-text--large.media-text-block--first-media-right .media-text-blocks__container > .mediatext-block--large:nth-child(odd) .media-text-block__column:first-child,
.media-text--large.media-text-block--first-media-right .block:nth-child(odd) .media-text-block__column:first-child {
  order: 1;
}

.media-text--medium .media-text-block__column--left,
.media-text--small .media-text-block__column--left {
  margin-bottom: var(--spacing-sm);
}

.media-text--xsmall .media-text-block__column--left > .image,
.media-text--xsmall .media-text-block__column--left > .videoembed,
.media-text--xsmall .media-text-block__column--left > .dynamicmedia {
  margin-bottom: var(--spacing-sm);
}

.mediatext-block--small {
  width: 100%;
}

.media-text--xsmall .media-text-blocks__container,
.media-text--small .media-text-blocks__container,
.media-text--medium .media-text-blocks__container,
.media-text--large .media-text-blocks__container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-lg);
}

.media-text-blocks__container > .media-text-block__container.mediatext-block--large {
  display: flex;
}

.media-text-block__container.mediatext-block--large > .media-text-block__wrapper {
  display: flex;
  gap: var(--spacing-lg);
  width: 100%;
}

.media-text-block__container.mediatext-block--large > .media-text-block__wrapper > * {
  flex-basis: calc(50% - var(--spacing-lg));
  gap: var(--spacing-lg);
}

.media-text-blocks__container > .media-text-block__container.mediatext-block--medium {
  flex-basis: calc(33% - var(--spacing-lg));
}

.media-text-blocks__container > .media-text-block__container.mediatext-block--small {
  flex-basis: calc(25% - var(--spacing-lg));
}

.media-text-blocks__container > .media-text-block__container.mediatext-block--xsmall {
  flex-basis: calc(20% - var(--spacing-lg));
}

.media-text-block--center-content .media-text-blocks__container {
  text-align: center;
}

.media-text--xsmall.media-text-block--center-blocks .media-text-blocks__container,
.media-text--small.media-text-block--center-blocks .media-text-blocks__container,
.media-text--medium.media-text-block--center-blocks .media-text-blocks__container {
  justify-content: center;
}

/* Mobile */

@media #{$mq-mobile} {
  .cmp-mediatextcontainer {
    padding: var(--spacing-lg) 0;
    margin-bottom: 0;
  }

  .media-text-block--center-blocks .media-text-blocks__container {
    width: 100%;
  }

  .media-text-blocks__container > .media-text-block__container.mediatext-block--medium,
  .media-text-blocks__container > .media-text-block__container.mediatext-block--small,
  .media-text-blocks__container > .media-text-block__container.mediatext-block--xsmall,
  .media-text-blocks__container > .media-text-block__container.mediatext-block--large > .media-text-block__wrapper > * {
    flex-basis: auto;
  }

  .media-text-blocks__container > .media-text-block__container.mediatext-block--large > .media-text-block__wrapper {
    display: block;
  }

  .media-text--large .mediatext-block--large:nth-child(even) .media-text-block__wrapper .media-text-block__column:first-child {
    order: inherit;
  }

  .media-text-block__column--left {
    margin-bottom: var(--spacing-sm);
  }
}

/* //TODO Move below code in ui.apps */
/* Author fixes */
.media-text-blocks__container {
  display: flex;
}

.cmp-mediatextcontainer.media-text--xsmall > .media-text__container > .media-text-blocks__container > .block.cq-Editable-dom {
  flex-basis: calc(20% - var(--spacing-lg));
}

.cmp-mediatextcontainer.media-text--small > .media-text__container > .media-text-blocks__container > .block.cq-Editable-dom {
  flex-basis: calc(25% - var(--spacing-lg));
}

.cmp-mediatextcontainer.media-text--medium > .media-text__container > .media-text-blocks__container > .block.cq-Editable-dom {
  flex-basis: calc(33% - var(--spacing-lg));
}

.media-text-blocks__container > .block.cq-Editable-dom > .media-text-block__container.mediatext-block--large > .media-text-block__wrapper {
  display: flex;
  gap: var(--spacing-lg);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.media-text-blocks__container > .block.cq-Editable-dom > .media-text-block__container.mediatext-block--large > .media-text-block__wrapper > * {
  flex-basis: calc(50% - var(--spacing-lg));
  gap: var(--spacing-lg);
}

.media-text--is-zebra {
  .media-text-blocks__container {
    gap: 0;
  }

  .mediatext-block {
    &--xsmall,
    &--small,
    &--medium {
      padding: var(--spacing-sm);
    }
  }

  .media-text-block__column--left {
    .image,
    .videoembed,
    .dynamicmedia {
      display: none;
    }
  }

  .mediatext-block--medium:nth-child(even),
  .mediatext-block--small:nth-child(even),
  .mediatext-block--xsmall:nth-child(even) {
    background-color: var(--color-gray-50);
  }

  .mediatext-block--medium:nth-child(odd),
  .mediatext-block--small:nth-child(odd),
  .mediatext-block--xsmall:nth-child(odd) {
    background-color: var(--color-white);
  }
}
