/*
* pagestage medium
*/
:root {
  --pagestage-medium-height: 25rem; /* 400 */
}

/* restore pointer events */
.cmp-pagestage-medium .cmp-button {
  pointer-events: initial;
}

/* change button color when its on the red background */
.cmp-pagestage-medium--left-red-trapezoid .cmp-button--primary-positive .cmp-button {
  background: white;
  color: var(--color-primary-500);
}

/* right alignment */
.cmp-pagestage-medium .cmp-banner-cta__content--right .cmp-banner-cta__content-inner {
  position: absolute;
  right: 0;
}

/* Content width restriction */
.cmp-pagestage-medium .cmp-banner-cta__center .cmp-banner-cta__content-inner,
.cmp-pagestage-medium .cmp-banner-cta__content--left-shape .cmp-banner-cta__content-inner {
  width: 100%;
}

.cmp-pagestage-medium__container-description::after {
  content: '';
  display: block;
  padding-top: var(--spacing-4xl);
}

/* Tablet portrait up */
@media (--breakpoint-up-sm) {
  /* Content width restriction */
  .cmp-pagestage-medium .cmp-banner-cta__content-inner {
    width: 75%;
  }

  /* Gradient */
  .cmp-pagestage-medium .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--left {
    background: linear-gradient(to right, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  }

  .cmp-pagestage-medium .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--right {
    background: linear-gradient(to left, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  }
}

@media (--breakpoint-up-md) {
  .cmp-pagestage-medium .cmp-container--full-width .cmp-banner-cta__short {
    height: var(--pagestage-medium-height);
  }

  .cmp-pagestage-medium .cmp-container--full-width .cmp-banner-cta__short--side-aligned .cmp-responsive-image--eager {
    height: var(--pagestage-medium-height);
  }

  /* Content width restriction */
  .cmp-pagestage-medium .cmp-banner-cta__content-inner {
    width: 66%;
  }
}

@media (--breakpoint-down-lg) {
  .cmp-pagestage-medium .cmp-container--full-width .cmp-banner-cta__tall--side-aligned .cmp-banner-cta__image {
    height: 100%;
  }
}

@media (--breakpoint-down-md) {
  .cmp-pagestage-medium__container-description::after {
    padding-top: var(--spacing-xl);
  }
}

@media (--breakpoint-down-sm) {
  .cmp-pagestage-medium .cmp-container--full-width .cmp-banner-cta__wrapper {
    height: var(--pagestage-medium-height);
  }
}
