:root {

  /* Banner CTA height - Desktop */
  --banner-fullwidth-tall-height: 22.5rem; /* 360 */
  --banner-fullwidth-short-height: 16.25rem; /* 260 */
}

/* Set as principal - when desktop & full width */
.cmp-container--full-width .cmp-banner-cta .title {
  margin: 0;
}

.cmp-container--full-width .cmp-banner-cta__content--left,
.cmp-container--full-width .cmp-banner-cta__content--right,
.cmp-container--full-width .cmp-banner-cta__content--left-shape,
.cmp-container--full-width .cmp-banner-cta__content--redTrapezoid,
.cmp-container--full-width .cmp-banner-cta__content--darkTrapezoid {
  padding: 0;
}

.cmp-container--full-width .cmp-banner-cta__left-shape .cmp-banner-cta__content-inner,
.cmp-container--full-width .cmp-banner-cta__left .cmp-banner-cta__content-inner {
  padding-left: 7.5rem;
}

.cmp-container--full-width .cmp-banner-cta__right .cmp-banner-cta__content-inner {
  padding-right: 7.5rem;
}

@media (--breakpoint-down-xl) {
  .cmp-container--full-width .cmp-banner-cta__left-shape .cmp-banner-cta__content-inner,
  .cmp-container--full-width .cmp-banner-cta__left .cmp-banner-cta__content-inner {
    padding-left: var(--spacing-xxl);
  }

  .cmp-container--full-width .cmp-banner-cta__right .cmp-banner-cta__content-inner {
    padding-right: var(--spacing-xxl);
  }
}

@media (--breakpoint-down-lg) {
  .cmp-container--full-width .cmp-banner-cta__left-shape .cmp-banner-cta__content-inner,
  .cmp-container--full-width .cmp-banner-cta__left .cmp-banner-cta__content-inner {
    padding-left: 1.875rem; /* 30 */
  }

  .cmp-container--full-width .cmp-banner-cta__right .cmp-banner-cta__content-inner {
    padding-right: 1.875rem; /* 30 */
  }
}

@media (--breakpoint-down-md) {
  .cmp-container--full-width .cmp-banner-cta__left-shape .cmp-banner-cta__content-inner,
  .cmp-container--full-width .cmp-banner-cta__left .cmp-banner-cta__content-inner {
    padding-left: 1.875rem; /* 30 */
  }

  .cmp-container--full-width .cmp-banner-cta__right .cmp-banner-cta__content-inner {
    padding-right: 1.875rem; /* 30 */
  }
}

@media (--breakpoint-down-sm) {
  .cmp-container--full-width .cmp-banner-cta__left-shape .cmp-banner-cta__content-inner,
  .cmp-container--full-width .cmp-banner-cta__left .cmp-banner-cta__content-inner {
    padding-left: var(--spacing-lg);
  }

  .cmp-container--full-width .cmp-banner-cta__right .cmp-banner-cta__content-inner {
    padding-right: var(--spacing-lg);
  }

  .cmp-container--full-width .cmp-banner-cta__content--full-width {
    padding: 0 var(--spacing-lg);
    width: 100%;
  }
}

@media (--breakpoint-up-sm) {
  /* tall banner & full-width */
  .cmp-container--full-width .cmp-banner-cta__tall,
  .cmp-container--full-width .cmp-banner-cta__tall .image,
  .cmp-container--full-width .cmp-banner-cta__tall .cmp-image,
  .cmp-container--full-width .cmp-banner-cta__tall--side-aligned .cmp-banner-cta__image,
  .cmp-container--full-width .cmp-banner-cta__tall--side-aligned .cmp-banner-cta__video {
    height: var(--banner-fullwidth-tall-height);
  }

  /* short banner & full-width */
  .cmp-container--full-width .cmp-banner-cta__short,
  .cmp-container--full-width .cmp-banner-cta__short .image,
  .cmp-container--full-width .cmp-banner-cta__short .cmp-image,
  .cmp-container--full-width .cmp-banner-cta__short--side-aligned .cmp-banner-cta__image,
  .cmp-container--full-width .cmp-banner-cta__short--side-aligned .cmp-banner-cta__image .cmp-responsive-image,
  .cmp-container--full-width .cmp-banner-cta__short--side-aligned .cmp-banner-cta__video {
    height: var(--banner-fullwidth-short-height);
  }

  .cmp-container--full-width .cmp-banner-cta__short .cmp-banner-cta__arrow {
    border-top-width: var(--banner-fullwidth-short-height);
  }
}
