.c-mapapp {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
}

.c-mapapp__container {
  position: relative;
}

.c-mapapp__list,
.c-mapapp__pagination {
  margin-bottom: var(--spacing-lg);
}

.c-mapapp__map.c-mapapp__map-hide {
  display: none;
}
