/**
 * Slider-Hero - Component
 */
:root {
  /* Carousel height - Desktop */
  --stagehero-height: 33vh;
  --stagehero-max-height: 22.87rem;
  --stagehero-min-height: 22.87rem;

  --stagehero-homepage-height: 66.6vh;
  --stagehero-homepage-max-height: 46.875rem; /* 750 */
  --stagehero-homepage-min-height: 22.87rem; /* 366 */
}

@include remove-grid-content('.cmp-stage-hero-slider--nospacing');

.stage-hero-slider > .splide {
  height: var(--stagehero-height);
  max-height: var(--stagehero-max-height);
  min-height: var(--stagehero-min-height);
}

.stage-hero-slider .splide__track {
  padding: 0;
}

/* reset components spacing */
.stage-hero-slider .cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner.cmp-banner-cta__inner::after {
  padding-top: 0;
}

/* cta adjustments  */
.stage-hero-slider .cmp-banner-cta__content {
  pointer-events: none;
}

.stage-hero-slider .cmp-banner-cta__button .cmp-button {
  pointer-events: all;
}

.stage-hero-slider .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__wrapper:not(.cmp-banner-cta__wrapper-video) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape) {
  background: linear-gradient(to top, hsl(0deg 0% 0% / 35%) 0%, hsl(0deg 0% 0% / 0%) 35%);
  width: 100%;
  height: 100%;
}

.stage-hero-slider .cmp-banner-cta__content--right .cmp-banner-cta__content-inner {
  align-self: flex-end;
}

.stage-hero-slider .cmp-banner-cta__center .cmp-banner-cta__content-inner,
.stage-hero-slider .cmp-banner-cta__content--left-shape .cmp-banner-cta__content-inner {
  width: 100%;
}

/* pagination controls */
.stage-hero-slider .splide__arrow {
  color: var(--color-white);
  background: none;
}

.stage-hero-slider .splide__arrow:hover {
  background: var(--color-primary-500);
}

@media (hover: none) {
  .stage-hero-slider .splide__arrow:hover {
    background: var(--color-black-transparent);
  }
}

.stage-hero-slider .splide__pagination__page {
  background-color: var(--color-white);
}

.stage-hero-slider .splide__pagination__page.is-active {
  background-color: var(--color-primary-500);
}

.stage-hero-slider .splide__arrows {
  width: 100%;
  position: absolute;
  bottom: var(--spacing-lg);
  max-width: 14rem;
  display: flex;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: var(--color-black-transparent);
  padding: 0;
}

.stage-hero-slider .splide__arrows.splide__arrows--hidden {
  display: none;
}

.stage-hero-slider .splide__pagination-placeholder {
  margin: 0;
  padding: 0;
}

.stage-hero-slider .splide__pagination li {
  display: inline-flex;
}

@media (--breakpoint-down-sm) {
  .stage-hero-slider .splide__pagination-placeholder {
    display: none;
  }

  .stage-hero-slider .splide__arrows {
    background: transparent;
    left: auto;
    right: 2.25rem;
    max-width: 5.25rem;
  }

  .stage-hero-slider .splide__arrow {
    background-color: var(--color-black-transparent);
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.375rem;
  }

  .stage-hero-slider .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape) {
    background: var(--color-black-transparent-lighter);
    height: 100%;
  }
}

/* Only desktop - styles */
@media (--breakpoint-up-sm) {
  /* Content width restriction */
  .stage-hero-slider .cmp-banner-cta__content-inner {
    width: 75%;
  }

  /* Gradient */

  .stage-hero-slider .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__wrapper:not(.cmp-banner-cta__wrapper-video) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--left {
    background: linear-gradient(to right, hsl(0deg 0% 0% / 35%) 0%, hsl(0deg 0% 0% / 0%) 35%);
  }

  .stage-hero-slider .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__wrapper:not(.cmp-banner-cta__wrapper-video) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--right {
    background: linear-gradient(to left, hsl(0deg 0% 0% / 35%) 0%, hsl(0deg 0% 0% / 0%) 35%);
  }

  .stage-hero-slider .cmp-banner-cta__tall,
  .stage-hero-slider .cmp-banner-cta__tall .image,
  .stage-hero-slider .cmp-banner-cta__tall .cmp-image,
  .stage-hero-slider .cmp-banner-cta__tall .cmp-banner-cta__image,
  .stage-hero-slider .cmp-banner-cta__tall .cmp-banner-cta__video {
    height: var(--stagehero-height);
    max-height: var(--stagehero-max-height);
    min-height: var(--stagehero-min-height);
  }

  .stage-hero-slider .cmp-banner-cta__short,
  .stage-hero-slider .cmp-banner-cta__short .image,
  .stage-hero-slider .cmp-banner-cta__short .cmp-image,
  .stage-hero-slider .cmp-banner-cta__short .cmp-banner-cta__image,
  .stage-hero-slider .cmp-banner-cta__short .cmp-banner-cta__video {
    height: var(--stagehero-height);
    max-height: var(--stagehero-max-height);
    min-height: var(--stagehero-min-height);
  }

  .stage-hero-slider .cmp-banner-cta__image .cmp-responsive-image {
    height: 100%;
  }

  .stage-hero-slider .cmp-carousel__item {
    max-height: var(--stagehero-max-height);
    min-height: var(--stagehero-min-height);
  }
}

@media (--breakpoint-up-md) {
  /* Content width restriction */
  .stage-hero-slider .cmp-banner-cta__content-inner {
    width: 75%;
  }
}

/*
* home page stage hero style policy
*/
/* Only desktop - styles */
@media (--breakpoint-up-sm) {
  .cmp-stage-hero-slider--homepage {
    .cmp-banner-cta__tall,
    .cmp-banner-cta__tall .image,
    .cmp-banner-cta__tall .cmp-image,
    .cmp-banner-cta__tall .cmp-banner-cta__image,
    .cmp-banner-cta__tall .cmp-banner-cta__video {
      height: var(--stagehero-homepage-height);
      max-height: var(--stagehero-homepage-max-height);
      min-height: var(--stagehero-homepage-min-height);
    }

    .cmp-banner-cta__short,
    .cmp-banner-cta__short .image,
    .cmp-banner-cta__short .cmp-image,
    .cmp-banner-cta__short .cmp-banner-cta__image,
    .cmp-banner-cta__short .cmp-banner-cta__video {
      height: var(--stagehero-homepage-height);
      max-height: var(--stagehero-homepage-max-height);
      min-height: var(--stagehero-homepage-min-height);
    }

    .cmp-carousel__item {
      max-height: var(--stagehero-homepage-max-height);
      min-height: var(--stagehero-homepage-min-height);
    }
  }
}
