/**
 * Productimage -Component
 */
.productimage img,
.productimage .cmp-image--product-image img {
  margin: 0 auto;
}
/* ensure core cmp image width is overridden when we are placed on a page */
.cmp-container .productimage .cmp-image__image {
  width: 100%;
}

.cmp-image--product-image--small {
  width: 75%;
  float: right;
}

.productimage .cmp-image--product-image--hidden {
  display: none;
}
