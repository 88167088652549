@import '@react/common.scss';

.cmp-sitemap {
  &__list {
    padding: 0;

    &-item {
      list-style: none;
      margin-bottom: var(--spacing-4xl);
    }
  }

  &__columns {
    color: var(--color-black);
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    align-content: flex-start;

    @media #{$mq-mobile} {
      flex-direction: column;
    }
  }

  &__column {
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    max-width: calc(100vw / 3);
    margin-top: var(--spacing-xs);

    @media #{$mq-mobile} {
      max-width: 100%;
    }

    &:first-child:has(.cmp-navigation__overview-item) {
      display: none;
    }
  }

  &__level-1 {
    @include bold-2xl();

    width: 100%;
    display: inline-block;
    color: var(--color-primary-500);
    padding-bottom: var(--spacing-sm);
    border-bottom: var(--border-xs) solid var(--color-gray-100);
    text-align: left;
    margin: 0;

    &::after {
      font-family: var(--materialicons-font);
      content: var(--icon-keyboard_arrow_right);
      vertical-align: middle;
    }
  }

  &__level-2 {
    @include bold-lg();

    color: var(--color-primary-500);
    margin: var(--spacing-md) 0 0;
  }

  &__level-3 {
    @include regular-lg();

    margin-top: var(--spacing-xs);
  }
}
