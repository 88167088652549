.stock-ticker__market-price,
.stock-ticker__exchange-name {
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.stock-ticker__exchange-name {
  @include bold-md();
}

.stock-ticker__market-price,
.stock-ticker__currency {
  color: var(--color-primary-500);
}

.stock-ticker__percentage {
  font-weight: var(--font-weight-bold);
}

.stock-ticker__data {
  @include regular-md();

  display: inline-flex;
  align-items: center;
}

.stock-ticker__title {
  margin-bottom: var(--spacing-sm);
}

.stock-ticker__wrapper .cmp-button__icon {
  width: 1.125rem;
  height: 1.125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 0.188rem;
  border-radius: 50%;
}

.stock-ticker__wrapper .cmp-button__icon::before {
  margin-right: 0;
}

/* Stock Ticker Right Aligned */

.cmp-stock-ticket__text-right {
  text-align: right;
}

.cmp-stock-ticket__text-right .stock-ticker__wrapper {
  text-align: right;
  border-right: 0.375rem solid var(--color-primary-500);
  padding-right: var(--spacing-sm);
}

.cmp-stock-ticket__text-right .stock-ticker__market-price-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-start;
}

.cmp-stock-ticket__text-right .stock-ticker__currency {
  @include regular-md();

  margin-bottom: 0.313rem;
  margin-right: 0.188rem;
}

.cmp-stock-ticket__text-right .stock-ticker__data .cmp-button__icon {
  float: left;
  margin-right: 0.375rem;
  margin-top: 0.188rem;
}

/* Stock Ticker Left Aligned */

.cmp-stock-ticket__text-left {
  text-align: left;
  width: fit-content;
}

.cmp-stock-ticket__text-left .stock-ticker__wrapper {
  text-align: left;
  width: fit-content;
  border-left: 0.375rem solid var(--color-primary-500);
  padding-left: var(--spacing-sm);
}

.cmp-stock-ticket__text-left .stock-ticker__market-price-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.cmp-stock-ticket__text-left .stock-ticker__data {
  flex-flow: row-reverse;
}

.cmp-stock-ticket__text-left .stock-ticker__currency {
  @include regular-md();

  margin-bottom: 0.313rem;
  margin-left: 0.188rem;
}

.cmp-stock-ticket__text-left .stock-ticker__data .cmp-button__icon {
  margin-left: 0.375rem;
}

.icon--stock_increase {
  background-color: var(--color-success-500);
  transform: rotate(-45deg);
}

.icon--stock_decrease {
  background-color: var(--color-error-500);
  transform: rotate(45deg);
}

.icon--stock_neutral {
  background-color: var(--color-gray-500);
}
