.c-mapapp__map {
  height: 27rem;
  min-height: 27rem;
  margin-bottom: var(--spacing-md);
}

.c-mapapp__map .MicrosoftMap {
  height: 27rem;
  width: 100%;
  position: relative;
}

@media (--breakpoint-down-sm) {
  .c-mapapp__map .MicrosoftMap {
    height: 25rem;
  }
}
