@import '@react/common.scss';

.cmp-taglist {
  &__item {
    @include regular-sm();

    border: var(--border-xs) solid var(--color-gray-700);
    color: var(--color-gray-700);
    padding: var(--spacing-xxs) var(--spacing-sm);
    z-index: 1;
    list-style: none;
    height: fit-content;
  }

  &--is-teams-notifications {
    .cmp-taglist__item {
      @include regular-sm();

      border: none;
      background-color: var(--color-teams-450);
      color: var(--color-white);
      padding: var(--spacing-xxs) 0;
      list-style-type: none;

      &-link {
        padding: var(--spacing-xxs) var(--spacing-sm);
      }

      &:hover {
        background-color: var(--color-teams-700);
      }
    }
  }

  &--is-removable {
    .cmp-taglist__item {
      display: inline-flex;
      align-items: center;
      border: 0;
      padding: 0;
      color: var(--color-gray-900);
      background: var(--color-white);
      transition: 0.2s ease-in-out 0s;
      list-style: none;

      @media #{$mq-1} {
        background: var(--color-white);
      }

      &-button {
        border: var(--border-xs) solid var(--color-gray-300);
        padding: var(--spacing-xxs) var(--spacing-xs);

        &:hover {
          background: var(--color-white);
          text-decoration: underline;

          .cmp-taglist__item-icon {
            color: var(--color-primary-700);
          }
        }

        &:focus {
          transform: scale(0.9);
        }
      }

      &-text {
        @include regular-md;

        width: 100%;
      }

      &-icon {
        display: inline-block;
        color: var(--color-primary-500);
        margin-left: var(--spacing-xs);

        &::before {
          margin: 0;
        }
      }
    }
  }

  &--is-clickable {
    .cmp-taglist__item {
      @include regular-sm();

      color: var(--color-primary-500);
      background-color: var(--color-white);
      border: 0;
      list-style: none;
      padding: var(--spacing-xxs) 0;
      transition: 0.2s ease-in-out;

      &-link {
        border: var(--border-xs) solid var(--color-primary-500);
        padding: var(--spacing-xxs) var(--spacing-sm);

        &:hover {
          color: var(--color-primary-700);
          border: var(--border-xs) solid var(--color-primary-700);
          text-decoration: underline;
        }
      }
    }
  }
}
