@include remove-grid-content('.fullwidthhorizontalteaser');

.cmp-full-width-horizontal-teaser {
  width: 100%;
  position: relative;

  &--is-right-aligned {
    .cmp-full-width-horizontal-teaser__content {
      right: 0;

      &-top,
      &-bottom {
        align-self: end;

        @media #{$mq-6} {
          margin-right: grid-spacing(6, 1, 1, 1);
        }

        @media #{$mq-5} {
          margin-right: grid-spacing(5, 1, 1, 1);
        }

        @media #{$mq-4} {
          margin-right: grid-spacing(4, 1, 1, 1);
        }

        @media #{$mq-3} {
          margin-right: grid-spacing(3, 1, 1, 1);
        }
      }
    }
  }

  &--is-left-aligned {
    .cmp-full-width-horizontal-teaser__content {
      left: 0;

      &-top,
      &-bottom {
        @media #{$mq-6} {
          margin-left: grid-spacing(6, 1, 1, 1);
        }

        @media #{$mq-5} {
          margin-left: grid-spacing(5, 1, 1, 1);
        }

        @media #{$mq-4} {
          margin-left: grid-spacing(4, 1, 1, 1);
        }

        @media #{$mq-3} {
          margin-left: grid-spacing(3, 1, 1, 1);
        }
      }
    }
  }

  &__content-top,
  &__content-bottom {
    @media #{$mq-6} {
      width: grid-spacing(6, 4, 3);
    }

    @media #{$mq-5} {
      width: grid-spacing(5, 4, 3);
    }

    @media #{$mq-4} {
      width: grid-spacing(4, 4, 3);
    }

    @media #{$mq-3} {
      width: grid-spacing(3, 4, 3);
    }
  }

  @media #{$mq-desktop} {
    video,
    img {
      position: absolute;
      height: auto;
      min-height: 100%;
      min-width: 100%;
      background-size: cover;
      background-position: center;
    }

    &--crop-top video,
    &--crop-top img {
      top: 0;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    &--crop-middle video,
    &--crop-middle img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--crop-bottom video,
    &--crop-bottom img {
      bottom: 0;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    &__media {
      max-height: toRem(1500px);
      height: toRem(720px);
      position: relative;
      overflow: hidden;
    }

    &__video {
      video {
        object-fit: cover;
      }
    }

    &__content {
      position: absolute;
      background-color: var(--color-black-op-60);
      backdrop-filter: blur(3px);
      color: var(--color-white);
      top: 0;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: var(--spacing-xxxl) 0;
    }

    &__title {
      @include bold-5xl();
    }

    &__button {
      padding-top: var(--spacing-lg);
    }
  }

  @media #{$mq-mobile} {
    &__media {
      position: relative;
      aspect-ratio: 1/1;

      .cmp-responsive-image.replaced img {
        aspect-ratio: 1/1;
        position: relative;
      }
    }

    &__video {
      video {
        position: inherit;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    &__content-top {
      position: absolute;
      transform: translate(0, -100%);
      width: 100%;
      background-color: var(--color-black-op-60);
      backdrop-filter: blur(3px);
      color: var(--color-white);
      padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
    }

    &__content-bottom {
      background-color: var(--color-gray-900);
      color: var(--color-white);
      padding: var(--spacing-md) var(--spacing-lg) var(--spacing-xl);
      margin-bottom: var(--spacing-xxs);
    }

    &__title {
      padding-top: var(--spacing-xs);
      margin-bottom: 0;

      @include bold-3xl();
    }

    &__button {
      padding-top: var(--spacing-md);
    }
  }

  &__pattern {
    text-transform: uppercase;

    @include bold-lg();
  }

  &__text {
    @include regular-lg();
  }

  &__video {
    height: 100%;
    width: 100%;
    margin: 0;

    video {
      height: inherit;
    }
  }

  &__video > *,
  video {
    width: inherit;
  }

  &__button {
    .cmp-button {
      display: inline-flex;

      @include button-primary;
    }

    &-icon {
      width: var(--spacing-md);
    }
  }
}
