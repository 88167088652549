.modal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-black-transparent);
  z-index: 99;
  width: 100vw;
  height: 100vh;
}

.modal-wrapper--hidden {
  display: none;
}

.abb-credential-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-white);
  max-width: 45.625rem;
  padding: var(--spacing-xxxl) 3.375rem;
}

.abb-credential-modal__title {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-weight: var(--font-weight);
  margin-bottom: var(--spacing-sm);
}

.abb-credential-modal .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-white);
  background: rgb(77 77 77 / 70%);
  padding: 0.625rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--spacing-xxxl);
}

.abb-credential-modal .icon-close::before {
  margin-right: 0;
}

.abb-credential-modal__url {
  display: flex;
  justify-content: center;
}

.abb-credential-modal__link {
  @include regular-md();

  position: relative;
  white-space: nowrap;
  overflow: hidden;
  border: 0.063rem solid var(--color-black);
  padding: var(--spacing-sm);
}

.abb-credential-modal__copy-btn {
  background: var(--color-primary-500);
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 0.563rem 1.563rem 0.688rem 0.563rem;
  white-space: nowrap;
}

.abb-credential-modal .abb-credential-modal__text {
  margin-bottom: 2.25rem;
}

@keyframes fadeOut {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.abb-credential-modal__link--copied::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-author-highlight-dark);
  opacity: 0;
  animation: 1s ease-in fadeOut;
}
