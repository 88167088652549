@import '@site/styles/foundations/media_queries.scss';

.cmp-the-wall__teasers-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--spacing-lg);
  padding: 0;
  margin: 0;
}

@media #{$mq-1} {
  .cmp-the-wall__teasers-list {
    padding-right: var(--spacing-lg);
    display: flex;
    scroll-padding: var(--spacing-xxs);
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-bottom: var(--spacing-lg);
  }

  .cmp-the-wall__teasers-list::-webkit-scrollbar {
    display: none;
  }
}

