@import './../../site/styles/vendor/splide/splide-core.min.css';

.cmp-media-gallery--background-grey {
  margin-bottom: var(--spacing-4xl);
  padding-top: var(--spacing-xl);
  position: relative;
}

.cmp-media-gallery--background-grey::before {
  content: '';
  position: absolute;
  display: block;
  z-index: -1;
  height: 100%;
  margin-top: calc(-1 * var(--spacing-xl));
  background: var(--color-gray-50);
  left: -6.75rem;
  width: calc(100% + (6.75rem * 2));
}

@media (--breakpoint-down-xl) {
  .cmp-media-gallery--background-grey::before {
    left: -1.75rem;
    width: calc(100% + (1.75rem * 2));
  }
}

@media (--breakpoint-down-lg) {
  .cmp-media-gallery--background-grey::before {
    left: -1.125rem;
    width: calc(100% + (1.125rem * 2));
  }
}

@media (--breakpoint-down-sm) {
  .cmp-media-gallery--background-grey::before {
    left: calc(-1 * (var(--spacing-lg) - var(--spacing-sm)));
    width: calc(100% + ((var(--spacing-lg) - var(--spacing-sm)) * 2));
  }
}

/* show all items in the editor mode */
.aem-AuthorLayer-Edit .swiper-wrapper {
  display: block;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 1rem 0;
}

.splide__slide .slide__content {
  width: 100%;
}

.js-cmp-slider--events-carousel .splide__track {
  padding: 2rem 0;
}

.splide__arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-lg);
}

.splide__arrows--hidden {
  display: none;
}

.splide__pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0.125rem 0 0;
  margin: 0;
}

.splide__pagination__page {
  opacity: 1;
  cursor: pointer;
  margin: 0;
  width: 100%;
  height: 0.188rem;
  background: var(--color-gray-300);
}

.splide__pagination__page.is-active,
.splide__pagination__page.is-paginationactive {
  background: var(--color-primary-500);
  opacity: 1;
}

.splide__sr {
  display: none;
}

.splide__arrow {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 1.875rem;
  width: 1.875rem;
  background-color: var(--color-primary-500);
  color: var(--color-white);
  padding: 0.188rem;
  border: var(--border-xs);
  animation: splide-arrow-hover-reverse 0.15s ease-in-out 0s 1 forwards;
}

.splide__arrow.cmp-button {
  color: var(--color-white);
}

.splide__arrow::after {
  font-family: var(--icon-font);
  font-size: var(--spacing-lg);
}

.splide__arrow:has(.splide__arrow--next) {
  margin-left: var(--spacing-sm);
}

.splide__arrow:has(.splide__arrow--prev) {
  margin-right: var(--spacing-sm);
}

.splide__arrow:hover {
  cursor: pointer;
  animation: splide-arrow-hover 0.15s ease-in-out 0s 1 forwards;
}

.splide__arrow:active {
  animation: splide-arrow-hover-reverse 0.15s ease-in-out 0s 1 forwards;
}

.stage-hero-slider .splide__arrow:hover,
.splide__arrow:hover {
  border: var(--border-xs) solid var(--color-primary-500);
  background-color: var(--color-primary-700);
}

.stage-hero-slider .splide__arrow:active,
.splide__arrow:active {
  border: var(--border-xs) solid var(--color-primary-500);
  background-color: var(--color-primary-800);
}

.splide__arrow:hover .splide__arrow--next {
  animation: link-icon-right 0.15s ease-in-out 0s 1 forwards;
}

.splide__arrow .splide__arrow--next {
  animation: link-icon-right-reverse 0.15s ease-in-out 0s 1 forwards;
  margin-top: 0;
}

.splide__arrow:hover .splide__arrow--prev {
  animation: link-icon-left 0.15s ease-in-out 0s 1 forwards;
}

.splide__arrow .splide__arrow--prev {
  animation: link-icon-left-reverse 0.15s ease-in-out 0s 1 forwards;
  margin-top: 0;
}

@media (hover: none) {
  .splide__arrow:hover {
    background-color: var(--color-primary-500);
    color: var(--color-white);
    border: var(--border-xs) solid var(--color-primary-500);
    animation: splide-arrow-hover 0.15s ease-in-out 0s 1 forwards;
  }
}

.splide__pagination-placeholder {
  max-width: 38.25rem;
  width: 100%;
  margin: var(--spacing-lg) auto 0 auto;
  padding: 0 2.375rem;
}

/*
* Style policies / Slider types
*/
/* Cover Flow */
.js-cmp-slider--coverflow .splide__track {
  padding: 5% 0;
}

@media (--breakpoint-down-sm) {
  .js-cmp-slider--coverflow .splide__track,
  .js-cmp-slider--events-carousel .splide__track {
    padding: 15% 0;
  }

  .splide__pagination-placeholder {
    margin: 0 auto;
  }

  .media-gallery .splide__pagination-placeholder {
    display: none;
  }
}

.js-cmp-slider--coverflow .splide__slide .slide__content,
.js-cmp-slider--events-carousel .splide__slide .slide__content {
  transform: scale(1, 1);
  transition: scale 0.5s;
}

.js-cmp-slider--coverflow .splide__slide.is-active .slide__content,
.js-cmp-slider--events-carousel .splide__slide.is-active .slide__content {
  transform: scale(1.2, 1.2);
  transition: scale 0.5s;
}

/* image/embed aspect ratio 16:9 */
.cmp-slider--ratio\:16\/9 .cmp-embed,
.cmp-slider--ratio\:16\/9 .cmp-image,
.cmp-slider--gallery .cmp-embed,
.cmp-slider--gallery .cmp-image,
.cmp-slider--carousel .cmp-embed,
.cmp-slider--carousel .cmp-image {
  position: relative;
}

.cmp-slider--ratio\:16\/9 .cmp-embed::before,
.cmp-slider--ratio\:16\/9 .cmp-image::before,
.cmp-slider--gallery .cmp-embed::before,
.cmp-slider--gallery .cmp-image::before,
.cmp-slider--carousel .cmp-embed::before,
.cmp-slider--carousel .cmp-image::before {
  content: '';
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.cmp-slider--ratio\:16\/9 .cmp-embed iframe,
.cmp-slider--ratio\:16\/9 .cmp-image__image,
.cmp-slider--gallery .cmp-image__image,
.cmp-slider--gallery .cmp-embed iframe,
.cmp-slider--carousel .cmp-embed iframe,
.cmp-slider--carousel .cmp-image__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;
  object-fit: cover;
  object-position: center;
}

/*
* Multiple items
*/
.cmp-slider--multiple .splide {
  margin: 0 calc(var(--spacing-sm) * -1);
}

.cmp-slider--multiple .splide__slide {
  padding: 0 var(--spacing-sm);
}

/* 4 items in row */
.cmp-slider--multiple-4 .splide__slide {
  width: 25%;
}

@media (--breakpoint-down-md) {
  .cmp-slider--multiple-4 .splide__slide {
    width: 33.333%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--multiple-4 .splide__slide {
    width: 90%;
  }
}

/* 3 items in a row */
.cmp-slider--multiple-3 .splide__slide {
  width: 33.333%;
}

/* media gallery */
.cmp-media-gallery .splide__slide {
  width: 100%;
}

.cmp-media-gallery .splide__slide .image {
  position: relative;
}

.cmp-media-gallery .splide__slide .image .cmp-media-gallery__fullscreen-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: calc(var(--spacing-xxs) * 1.5);
  background: var(--color-white);
  color: var(--color-gray-500);
  font-size: 36px;
  text-align: center;
  z-index: 2;
  pointer-events: initial;
}

.cmp-media-gallery .splide__slide .image .cmp-media-gallery__fullscreen-btn:hover {
  color: var(--color-primary-500);
  cursor: pointer;
}

.cmp-media-gallery .splide__slide .cmp-media-gallery__description {
  padding: var(--spacing-xs);
  background: var(--color-white);
  border: var(--spacing-xxxs) solid var(--color-gray-50);
}

@media (--breakpoint-down-md) {
  .cmp-slider--multiple-3 .splide__slide {
    width: 50%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-slider--multiple-3 .splide__slide {
    width: 90%;
  }

  .cmp-media-gallery.cmp-slider--multiple-3 .swiper-slide,
  .cmp-media-gallery.cmp-slider--multiple-4 .swiper-slide,
  .cmp-media-gallery.cmp-slider--multiple-6 .swiper-slide {
    grid-template-columns: 1fr 1fr;
  }
}
