/**
 * Contact -Component
 */

:root {
  /* Contact image height - Desktop */
  --contact-image-height: 10rem; /* 160 */
}

@include remove-grid-content('.contact');

.cmp-contact {
  background: var(--color-gray-50);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-xl) var(--spacing-lg);
  text-align: center;
  height: 100%;
}

.cmp-contact__image {
  display: flex;
  justify-content: center;

  .image-wrapper {
    position: relative;
    height: var(--contact-image-height);
    width: var(--contact-image-height);
    margin-bottom: var(--spacing-lg);
  }

  .cmp-image,
  .cmp-responsive-image img {
    border-radius: 50%;
    height: var(--contact-image-height);
    width: var(--contact-image-height);
    object-fit: cover;
    transform: translateZ(0);
  }

  .cmp-image__image,
  .cmp-responsive-image img {
    height: var(--contact-image-height);
  }

  .cmp-responsive-image.replaced img {
    position: absolute;
  }
}

.cmp-contact__meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--centered {
    flex-grow: 0;
  }
}

.cmp-contact__title {
  margin: 0 0 var(--spacing-xs) 0;
}

.cmp-contact__description {
  margin-bottom: var(--spacing-md);
  flex: 1 1 auto;

  p {
    margin-bottom: 0;
    display: block;
  }
}

.cmp-contact__phone {
  margin-top: auto;
  margin-bottom: var(--spacing-xs);
}

.cmp-contact__link {
  display: inline-flex;
  align-items: center;

  @include transition-transform;

  color: var(--color-primary-500);

  .cmp-contact__link-icon {
    @include transition-transform;
  }

  &:hover {
    color: var(--color-primary-700);

    .cmp-contact__link-icon {
      @include link-icon-hover;
    }

    .cmp-contact__link-text {
      text-decoration: underline;
    }
  }

  &:active {
    color: var(--color-primary-800);
  }
}

/* Visual style - Policies */
.cmp-contact__image--left .cmp-contact {
  flex-direction: row;
  min-height: auto;
}

.cmp-contact__alignment--left .cmp-contact__image,
.cmp-contact__image--left .cmp-contact__image {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: var(--spacing-lg);
}

.cmp-contact__alignment--left .cmp-contact__meta,
.cmp-contact__image--left .cmp-contact__meta {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.cmp-contact__alignment--left .cmp-contact__meta {
  text-align: left;
}

@media (--breakpoint-down-md) {
  .cmp-contact {
    min-height: auto;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-contact.cmp-contact {
    flex-direction: column;
  }

  .cmp-contact .cmp-contact__meta {
    text-align: center;
  }

  .cmp-contact__alignment--left .cmp-contact__image,
  .cmp-contact__image--left .cmp-contact__image {
    margin-right: 0;
  }
}
