/* stylelint-disable selector-max-id */
#footnotes {
  /* Align the gap from the header */
  padding: var(--header-mobile-navigation-menu-height) var(--spacing-sm) var(--spacing-xxxl) var(--spacing-sm);
  margin-top: calc(-1 * var(--header-mobile-navigation-menu-height));
  scroll-margin-top: calc(.3rem + var(--header-mobile-navigation-menu-height));
}

#footnotes .cmp-text .cmp-footnote-sup {
  color: var(--base-color);
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: var(--color-primary-500);
}

#footnotes .cmp-footnote-container {
  display: flex;
}

#footnotes .cmp-footnote-title::after,
#footnotes .cmp-footnote-container::after {
  padding-top: unset;
}

#footnotes .cmp-footnote-title {
  @include regular-xl();

  padding-bottom: var(--spacing-sm);
}

#footnotes .cmp-footnote-counter {
  @include regular-sm();

  vertical-align: super;
  padding-right: var(--spacing-xxs);
}

#footnotes .cmp-footnote-description a {
  color: var(--color-primary-500);
  text-decoration: underline;
}

#footnotes .cmp-footnote-description a:hover {
  color: var(--base-color);
}

#footnotes .cmp-footnote-description {
  @include regular-md();

  padding-top: var(--spacing-xxs);
}

#footnotes .cmp-footnote-description p {
  margin-bottom: var(--spacing-xxxs);
}
