@import '@react/common.scss';

.MicrosoftMap .Infobox .infobox-title,
.MicrosoftMap .infobox__title {
  @include regular-xl();

  color: var(--color-gray-900);
  margin: 0 0 var(--spacing-xs);
}

.MicrosoftMap .Infobox .infobox-info {
  color: var(--color-gray-900);
}

.MicrosoftMap .infobox-info th {
  @include regular-xs();

  padding: 0 var(--spacing-md) var(--spacing-sm) 0;
  width: 25%;
  text-transform: uppercase;
  color: var(--color-gray-900);
}

.MicrosoftMap .infobox-info td {
  @include regular-md();

  color: var(--color-gray-900);
}
