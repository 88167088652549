.cmp-event-registration {
  &__title {
    @include bold-3xl;

    background-color: var(--color-primary-500);
    color: var(--color-white);
    padding: var(--spacing-md) var(--spacing-lg);
    margin: 0;
  }

  .cmp-form {
    background-color: var(--color-gray-50);
    padding: var(--spacing-lg);
    margin-left: 0;
    margin-right: 0;

    .cmp-form-button--wrapper {
      @media #{$mq-desktop} {
        /* stylelint-disable-next-line declaration-no-important */
        display: flex !important;
        justify-content: flex-end;
      }

      .cmp-form-button {
        @media #{$mq-mobile} {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .cmp-form-options--radio {
      flex-direction: row;
      align-items: baseline;
      margin-top: var(--spacing-xxl);

      .cmp-form-options__field-label {
        margin-right: var(--spacing-lg);
      }
    }

    .cmp-form-options--is-vertical {
      flex-direction: column;
      margin-top: 0;
    }

    .cmp-form-options--is-horizontal {
      @media #{$mq-mobile} {
        flex-direction: column;
      }
    }
  }
}
