.cmp-horizontal-teaser-wrapper {
  display: flex;
  flex-direction: row;
  box-shadow: var(--box-shadow);
  margin: 0 auto;
  max-width: 75rem; /* 1200px */
  height: 20.688rem;
  transition: box-shadow 0.3s ease-in-out;
}

.cmp-horizontal-teaser__media-left .cmp-horizontal-teaser-wrapper {
  flex-direction: row-reverse;
}

.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__content {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: var(--spacing-lg);
  margin-right: var(--spacing-lg);
}

.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__content .cmp-horizontal-teaser__title {
  @include bold-lg();

  padding: var(--spacing-xs) 0;
  margin-bottom: var(--spacing-xxs);
}

.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__content .cmp-horizontal-teaser__text {
  margin-bottom: var(--spacing-sm);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}

.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 36.75rem;
}

.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media .cmp-horizontal-teaser__image,
.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media .image-wrapper,
.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media .cmp-responsive-image {
  height: 100%;
}

.cmp-horizontal-teaser__media .cmp-horizontal-teaser__image .cmp-responsive-image img {
  position: initial;
}

.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media .video.videoembed,
.cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media .video.videoembed .cmp-videoembed {
  height: 100%;
  margin-bottom: 0;
}

.cmp-horizontal-teaser__media .dynamicmedia .cq-dd-image {
  height: 20.688rem;
}

.cmp-horizontal-teaser-wrapper .cmp-video-wrapper {
  height: 20.688rem;
}

.cmp-horizontal-teaser-wrapper .cmp-video-wrapper .cmp-video-player {
  object-fit: cover;
}

.cmp-horizontal-teaser-wrapper:hover {
  box-shadow: var(--box-shadow-md);
}

@media (--breakpoint-up-sm) and (--breakpoint-down-lg) {
  .cmp-horizontal-teaser__media .video.videoembed .cmp-videoembed .cmp-videoembed__poster {
    height: 100%;
  }
}

@media (--breakpoint-up-sm) and (--breakpoint-down-md) {
  .cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__content .cmp-horizontal-teaser__text {
    -webkit-line-clamp: 4;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-horizontal-teaser-wrapper,
  .cmp-horizontal-teaser__media-left .cmp-horizontal-teaser-wrapper {
    height: 100%;
    flex-direction: column-reverse;
  }

  .cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__media,
  .cmp-horizontal-teaser-wrapper .cmp-horizontal-teaser__content {
    width: 100%;
  }

  .cmp-horizontal-teaser__media .dynamicmedia .cq-dd-image {
    height: 12.375rem;
  }
}
