/**
 * Banner cta - Component
 */

:root {
  /* Banner CTA height - Desktop */
  --banner-tall-height: 26.875rem; /* 430 */
  --banner-fullwidth-tall-height: 22.5rem; /* 360 */
  --banner-3col-height: 21.875rem; /* 350 */
  --banner-short-height: 17.1875rem; /* 275 */
}

.cmp-banner-cta {
  position: relative;
}

.cmp-banner-cta__wrapper {
  background-color: var(--color-primary-500);
  color: var(--color-white);
  overflow: hidden;
  position: relative;
  width: 100%;
}

.cmp-banner-cta,
.cmp-banner-cta__inner {
  pointer-events: none;
}

.cmp-banner-cta .s7innercontainer {
  overflow: hidden;
}

.cmp-banner-cta__inner * {
  pointer-events: auto;
}

.cmp-banner-cta__tall--side-aligned,
.cmp-banner-cta__short--side-aligned {
  display: flex;
}

.cmp-banner-cta__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.cmp-banner-cta__content-inner {
  z-index: 3;
}

.cmp-pagestage-medium .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__wrapper:not(.cmp-banner-cta__wrapper-video) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape),
.stage .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__wrapper:not(.cmp-banner-cta__wrapper-video) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape) {
  background: linear-gradient(to top, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  width: 100%;
  height: 100%;
}

.cmp-banner-cta__title {
  @include bold-2xl();
}

.cmp-banner-cta__title .cmp-title__text {
  margin: 0;
}

.cmp-banner-cta__tall .cmp-banner-cta__title,
.cmp-banner-cta__content--side-aligned .cmp-banner-cta__title {
  @include bold-4xl();
}

.cmp-banner-cta__text,
.cmp-banner-cta__icon {
  @include regular-xl();
}

.cmp-banner-cta__button {
  line-height: 0;
}

.cmp-banner-cta__button .button {
  line-height: initial;
  min-width: 3.75rem; /* 60 */
}

.cmp-banner-cta__arrow {
  border-top-style: solid;
  border-top-color: var(--color-primary-500);
  height: 0;
  position: absolute;
}

.cmp-banner-cta__arrow::before {
  border-left-color: var(--color-primary-500);
  border-top-color: var(--color-primary-500);
  border-right-color: var(--color-primary-500);
}

.cmp-banner-cta__media.cmp-banner-cta__image,
.cmp-banner-cta__media.cmp-banner-cta__video {
  margin-left: auto;
  margin-right: 0;
  width: 100%;
}

.cq-Editable-dom .cmp-banner-cta__video {
  padding-top: 0;
}

.cmp-banner-cta__image .cmp-image__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.cmp-banner-cta .cmp-videocontainer {
  margin-bottom: 0;
  position: initial;
}

.cmp-banner-cta__short .cmp-banner-cta__video .cmp-videocontainer {
  height: var(--banner-short-height);
}

.cmp-banner-cta__tall .cmp-banner-cta__video .cmp-videocontainer {
  height: var(--banner-tall-height);
}

/* stylelint-disable declaration-no-important */
.cmp-banner-cta .cmp-videocontainer__dynamicmediaplayer .s7container,
.cmp-banner-cta .cmp-videocontainer__dynamicmediaplayer video,
.cmp-banner-cta .cmp-pagestage__dynamicmediaplayer .s7container,
.cmp-banner-cta .cmp-pagestage__dynamicmediaplayer video {
  width: 0 !important;
  min-width: 100% !important;
  object-fit: cover;
  object-position: center;
}

.cmp-banner-cta video {
  max-height: 100vh;
  object-fit: cover;
}

.cmp-banner-cta .cmp-videocontainer__dynamicmediaplayer video {
  top: 50% !important;
  left: 50% !important;
  transform: translate3d(-50%, -50%, 0) scale(1.05, 1.05);
}

.cmp-banner-cta__short .cmp-videocontainer__dynamicmediaplayer .s7container,
.cmp-banner-cta__short .cmp-videocontainer__dynamicmediaplayer video,
.cmp-banner-cta__tall .cmp-pagestage__dynamicmediaplayer .s7container,
.cmp-banner-cta__tall .cmp-pagestage__dynamicmediaplayer video {
  height: initial;
  min-height: var(--banner-short-height) !important;
}

.cmp-banner-cta__tall .cmp-videocontainer__dynamicmediaplayer .s7container,
.cmp-banner-cta__tall .cmp-videocontainer__dynamicmediaplayer video,
.cmp-banner-cta__tall .cmp-pagestage__dynamicmediaplayer .s7container,
.cmp-banner-cta__tall .cmp-pagestage__dynamicmediaplayer video {
  height: initial;
  min-height: var(--banner-tall-height) !important;
}

.cmp-banner-cta__short .cmp-videocontainer__dynamicmediaplayer,
.cmp-banner-cta__short .cmp-videocontainer__dynamicmediaplayer video,
.cmp-banner-cta__tall .cmp-videocontainer__dynamicmediaplayer,
.cmp-banner-cta__tall .cmp-videocontainer__dynamicmediaplayer video {
  height: 100%;
}

/* stylelint-enable declaration-no-important */

/* stylelint-disable selector-max-compound-selectors */
.cmp-banner-cta__image .image .cmp-responsive-image--eager img {
  position: absolute;
}

.cmp-banner-cta__image .image .cmp-responsive-image.replaced img {
  position: absolute;
}

/* stylelint-enable */

.cmp-banner-cta__video .cmp-embed,
.cmp-banner-cta__video iframe {
  height: 100%;
  width: 100%;
}

.cmp-banner-cta__wrapper-video.cmp-banner-cta__left-shape {
  background-color: var(--color-black);
}

/* is-side-aligned */
.cmp-banner-cta__content--side-aligned {
  padding: 0;
  position: absolute;
  width: 50%;
}

.cmp-banner-cta__content--side-aligned .cmp-text {
  margin-top: var(--spacing-xs);
}

.cmp-banner-cta__content--side-aligned .cmp-button {
  margin-top: var(--spacing-lg);
  min-height: toRem(40px);
}

.cmp-banner-cta__content--left-shape,
.cmp-banner-cta__content--redTrapezoid {
  background-color: var(--color-primary-500);
}

.cmp-banner-cta__content--right {
  padding-right: var(--spacing-4xl);
}

.stage .cmp-banner-cta__content--right .cmp-banner-cta__content-inner {
  position: absolute;
  right: 0;
}

.cmp-banner-cta__content--left,
.cmp-banner-cta__content--left-shape,
.cmp-banner-cta__content--redTrapezoid,
.cmp-banner-cta__content--darkTrapezoid {
  left: 0;
  padding-left: var(--spacing-4xl);
}

.cmp-banner-cta__tall .cmp-banner-cta__content--left-shape,
.cmp-banner-cta__tall .cmp-banner-cta__content--redTrapezoid,
.cmp-banner-cta__tall .cmp-banner-cta__content--darkTrapezoid {
  width: 37.2%;
}

.cmp-banner-cta__short .cmp-banner-cta__content--left-shape {
  width: 60%;
}

.cmp-banner-cta__content-inner .cmp-banner-cta__label-wrapper {
  display: flex;
  padding: var(--spacing-sm) 0;
}

.cmp-banner-cta__content-inner .cmp-banner-cta__label-wrapper .cmp-banner-cta__label {
  @include regular-md();

  padding: 0 var(--spacing-sm);
  background: var(--color-gray-700);
  color: var(--color-white);
}

.cmp-banner-cta__content-inner .cmp-banner-cta__label-wrapper .cmp-banner-cta__secondary-title {
  padding: var(--spacing-xxxs) var(--spacing-xs);
}

/* tall banner */
.cmp-banner-cta__tall {
  height: var(--banner-tall-height);
}

.slider-banner .cmp-banner-cta__tall {
  height: var(--banner-tall-height);
  max-height: none;
}

.cmp-banner-cta__tall .cmp-banner-cta__arrow {
  border-right: 25vw solid transparent;
  border-top-width: var(--banner-tall-height);
}

.cmp-banner-cta__tall .image,
.cmp-banner-cta__tall .cmp-image {
  height: var(--banner-tall-height);
}

/* tall & side aligned */
.cmp-banner-cta__tall--side-aligned .cmp-banner-cta__image,
.cmp-banner-cta__tall--side-aligned .cmp-banner-cta__video {
  height: var(--banner-tall-height);
}

.cmp-banner-cta__tall--side-aligned .cmp-banner-cta__arrow {
  left: 37.2%;
}

.cmp-banner-cta__tall--side-aligned .cmp-banner-cta__button {
  margin-bottom: var(--spacing-4xl);
}

.cmp-banner-cta__left-shape .cmp-banner-cta__video {
  width: 100%;
}

/* short banner */
.cmp-banner-cta__short {
  height: var(--banner-short-height);
}

.cmp-banner-cta__short .cmp-banner-cta__image,
.cmp-banner-cta__short .image,
.cmp-banner-cta__short .cmp-image,
.cmp-banner-cta__short .cmp-banner-cta__video {
  height: var(--banner-short-height);
}

.cmp-banner-cta__short .cmp-banner-cta__arrow {
  border-right: 16.25vw solid transparent;
  border-top-width: var(--banner-short-height);
}

/* short & trapez */
.cmp-banner-cta__short--side-aligned .cmp-banner-cta__button {
  margin-bottom: var(--spacing-xxl);
}

.cmp-banner-cta__short--left .cmp-banner-cta__video {
  width: 50%;
}

.cmp-banner-cta__short--side-aligned .cmp-banner-cta__arrow {
  left: 60%;
}

/* full width content */
.cmp-banner-cta__content--full-width {
  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  padding: 0 22%;
  z-index: 1;
}

.cmp-banner-cta__content--full-width .cmp-banner-cta__button {
  margin-top: var(--spacing-xl);
}

.stage .cmp-banner-cta__center .cmp-banner-cta__content-inner,
.stage .cmp-banner-cta__content--left-shape .cmp-banner-cta__content-inner {
  width: 100%;
}

/* Pattern Tripple Right */
.cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-right-triple .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
  width: 50%;
}

.cmp-banner-cta__pattern--top-right::before,
.cmp-banner-cta__pattern--top-left::before,
.cmp-banner-cta__pattern--bottom-right::before,
.slide-pattern--singleLeft::before,
.slide-pattern--singleRight::before {
  content: '';
  position: absolute;
  background-image: url('/src/main/webpack/resources/brandPatternSingle.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 37.5rem;
  height: 6rem;
  /* Branding pattern to display behind banner/carousel content inner text */
  z-index: 2;
}

.cmp-banner-cta__pattern--top-right-triple::before {
  content: '';
  position: absolute;
  background-image: url('/src/main/webpack/resources/brandPatternTripleLine.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 57rem;
  height: 18rem;
  /* Branding pattern to display behind banner/carousel content inner text */
  z-index: 2;
  top: var(--spacing-xxxl);
  right: -19.6875rem;
}

.slide-pattern--tripleRight::before {
  content: '';
  position: absolute;
  background-image: url('/src/main/webpack/resources/brandPatternTripleRight.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 2;
  right: 0;
}

.cmp-banner-cta__pattern--top-right::before {
  top: var(--spacing-xxxl);
  right: -3.125rem;
}

.cmp-banner-cta__pattern--top-left::before {
  top: var(--spacing-xxxl);
  left: -2.8125rem;
}

.cmp-banner-cta__pattern--bottom-right::before {
  bottom: var(--spacing-xxxl);
  right: -3.125rem;
}

@media (--breakpoint-down-lg) {
  .cmp-banner-cta__wrapper .cmp-banner-cta__image,
  .cmp-banner-cta__wrapper .cmp-banner-cta__image * {
    height: 100%;
  }
}

/* Tablet portrait up */
@media (--breakpoint-up-sm) {
  .cmp-banner-cta__content--right {
    text-align: right;
    right: 0;
  }

  /* Content width restriction */
  .stage .cmp-banner-cta__content-inner {
    width: 75%;
  }

  /* Gradient */

  .cmp-pagestage-medium .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--left,
  .stage .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--left {
    background: linear-gradient(to right, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  }

  .cmp-pagestage-medium .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--right,
  .stage .cmp-banner-cta:not(.cmp-banner-cta--bg-opacity) .cmp-banner-cta__content:not(.cmp-banner-cta__content--left-shape).cmp-banner-cta__content--right {
    background: linear-gradient(to left, hsl(0deg 0% 0% / 60%) 0%, hsl(0deg 0% 0% / 0%) 66%);
  }
}

/* 3-columns */
@media (--breakpoint-up-md) {
  /* Content width restriction */
  .stage .cmp-banner-cta__content-inner {
    width: 66%;
  }

  /* Patterns - text left - content inner container */
  .cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    padding-left: var(--spacing-xxxl);
  }

  /* Single top left */
  .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-left .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 75%;
  }

  /* Triple top right */
  .cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-right-triple .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 47%;
  }

  /* Single bottom right */
  .cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--bottom-right .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 50%;
  }

  /* Patterns - content inner button left */
  .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image .cmp-banner-cta__content--left .cmp-banner-cta__content-inner .cmp-banner-cta__button {
    margin-bottom: var(--spacing-xxxl);
  }

  .aem-GridColumn--default--3 .cmp-banner-cta__text {
    @include regular-md();
  }

  .aem-GridColumn--default--3 .cmp-banner-cta__title {
    font-size: var(--sm-font-size-h4);
    line-height: var(--line-height-md);
  }

  .aem-GridColumn--default--3 .cmp-banner-cta__image,
  .aem-GridColumn--default--3 .cmp-banner-cta__image *,
  .aem-GridColumn--default--3 .cmp-banner-cta__tall .image,
  .aem-GridColumn--default--3 .cmp-banner-cta__tall .cmp-image {
    height: 100%;
  }

  .aem-GridColumn--default--3 .cmp-banner-cta__short {
    height: var(--banner-3col-height);
  }
}

@media (--breakpoint-header-down-md) {
  .cmp-banner-cta__pattern--top-right::before,
  .cmp-banner-cta__pattern--top-left::before,
  .cmp-banner-cta__pattern--bottom-right::before,
  .cmp-banner-cta__pattern--top-right-triple::before {
    width: 17.25rem;
    height: var(--spacing-xxxl);
  }

  .cmp-banner-cta__pattern--top-right-triple::before,
  .slide-pattern--tripleRight::before {
    background-image: url('/src/main/webpack/resources/brandPatternTripleRight.svg');
  }

  .cmp-banner-cta__pattern--top-right::before,
  .cmp-banner-cta__pattern--bottom-right::before,
  .cmp-banner-cta__pattern--top-right-triple::before {
    top: var(--spacing-xxxl);
    right: -1.25rem;
  }

  .cmp-banner-cta__pattern--top-left::before {
    left: -1.25rem;
  }

  .cmp-banner-cta__title .cmp-title__text {
    font-size: 1.875rem;
    line-height: 2.625rem;
  }
}

@media (--breakpoint-header-down-md) and (orientation: portrait) {
  /* Pattern top left, text left */
  .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-left .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 100%;
  }

  /* Pattern top right, text left */
  .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-right .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 95%;
  }

  /* Pattern triple right */
  .cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-right-triple .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 100%;
  }
}

@media (--breakpoint-header-down-md) and (orientation: landscape) {
  /* Pattern top left, text left */
  .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-left .cmp-banner-cta__content--left .cmp-banner-cta__content-inner,
  .cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-right-triple .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    width: 75%;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-banner-cta .cmp-banner-cta__wrapper.cmp-banner-cta__wrapper-image.cmp-banner-cta__pattern--top-right-triple .cmp-banner-cta__content--left .cmp-banner-cta__content-inner {
    padding-left: var(--spacing-lg);
    width: 100%;
  }

  .cmp-banner-cta .cmp-video-wrapper,
  .cmp-banner-cta video {
    height: 100%;
  }
}
