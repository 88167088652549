@include remove-grid-content('.peekaboovideoteaser');

.peekaboovideoteaser,
.fullwidthhorizontalteaser {
  margin-bottom: var(--spacing-xxs);
}

.peekaboovideoteaser:not(:has(+ .peekaboovideoteaser, + .fullwidthhorizontalteaser)),
.fullwidthhorizontalteaser:not(:has(+ .peekaboovideoteaser, + .fullwidthhorizontalteaser)) {
  margin-bottom: var(--spacing-4xl);
}

.cmp-peekaboo-video-teasers {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xxs);
  color: var(--color-white);

  @media #{$mq-mobile} {
    flex-direction: column;
  }

  .cmp-peekaboo-video-teaser {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    cursor: default;
    max-height: toRem(1500px);

    &.cmp-peekaboo-video-teaser--crop-top {
      video {
        position: absolute;
        top: 0;
        bottom: auto;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        min-height: 100%;
      }
    }

    &.cmp-peekaboo-video-teaser--crop-middle {
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        min-height: 100%;
      }
    }

    &.cmp-peekaboo-video-teaser--crop-bottom {
      video {
        position: absolute;
        bottom: 0;
        top: auto;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        min-height: 100%;
      }
    }

    @media #{$mq-desktop} {
      height: toRem(720px);
      min-width: 30%;
      max-width: 50%;
    }

    &__img,
    .cmp-responsive-image {
      position: relative;
      height: 100%;
    }

    &__video-wrapper {
      height: 100%;
      display: flex;

      &--video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-size: cover;
        background-position: center;
      }
    }

    &__content {
      position: absolute;
      bottom: 0;
      background-color: var(--color-black-transparent-lighter);
      width: 100%;
      backdrop-filter: blur(toRem(3px));

      @media #{$mq-mobile} {
        padding: var(--spacing-lg);
      }

      @media #{$mq-desktop} {
        padding: var(--spacing-xxxl) var(--spacing-5xl);
        min-height: toRem(183px);
      }

      &--title {
        @include bold-3xl;

        margin-bottom: var(--spacing-sm);
      }

      &--text {
        @media #{$mq-mobile} {
          @include regular-lg;
        }

        @media #{$mq-desktop} {
          @include regular-xl;

          max-height: 0;
          opacity: 0;
          transition: all 0.3s ease-out;
        }
      }
    }

    &:hover {
      .cmp-peekaboo-video-teaser__content--text {
        @media #{$mq-desktop} {
          max-height: toRem(720px);
          opacity: 1;
          transition: all 0.3s ease-in;
        }
      }
    }
  }
}
