@import '@site/styles/foundations/typography.scss';
@import '@site/styles/foundations/media_queries.scss';

.cmp-taglist {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--spacing-xs);
  padding: var(--spacing-xxxs);
  margin: 0 0 var(--spacing-xs);

  &__item {
    border: var(--border-xs) solid var(--color-gray-700);
    padding: var(--spacing-xxs) var(--spacing-sm);

    @include regular-sm;

    color: var(--color-gray-700);
    list-style-type: none;
  }

  &--is-removable {
    .cmp-taglist__item {
      display: inline-flex;
      align-items: center;
      border: 0;
      padding: 0;
      color: var(--color-gray-900);
      background: var(--color-white);
      transition: 0.2s ease-in-out 0s;
      list-style: none;

      &-button {
        border: var(--border-xs) solid var(--color-gray-300);
        padding: var(--spacing-xxs) var(--spacing-xs);

        &:hover {
          background: var(--color-white);
          text-decoration: underline;

          .cmp-taglist__item-icon {
            color: var(--color-primary-700);
          }
        }

        &:focus {
          transform: scale(0.9);
        }
      }

      @media #{$mq-1} {
        background: var(--color-white);
      }

      &-text {
        @include regular-md;

        width: 100%;
      }

      &-icon {
        display: inline-block;
        color: var(--color-primary-500);
        margin-left: var(--spacing-xs);

        &::before {
          margin: 0;
        }
      }
    }
  }
}
