@import '@react/common.scss';

@include remove-grid-content('.teams-notifications');

.cmp-teams-notifications {
  &.teams-notifications-in-page {
    border-top: var(--spacing-xxxs) solid var(--color-gray-100);
    padding-top: var(--spacing-xl);

    & .teams-notifications__title__icon.icon-teams {
      @include regular-md();

      color: var(--color-white);
      background-color: var(--color-teams-450);
      border-radius: 0.125rem;
      padding: 0.313rem;
    }
  }

  &.teams-notifications-in-list {
    & .teams-notifications__channels {
      margin: var(--spacing-xxxs) 0;
      display: inline-flex;

      &__channel {
        padding: var(--spacing-xxs) var(--spacing-xxs);
        margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) 0;

        & .icon-teams {
          padding-right: var(--spacing-xxs);
          vertical-align: middle;
        }
      }
    }

    & .teams-notifications__title__icon.icon-teams {
      color: var(--color-teams-450);
    }

    & .teams-notifications__title {
      display: inline-flex;

      &__text {
        @include regular-md;

        margin: var(--spacing-xs);
      }
    }
  }

  & .teams-notifications__title {
    display: flex;
    align-items: center;

    &__icon.icon-teams::before {
      margin: 0 auto;
    }

    &__text {
      margin-left: var(--spacing-sm);
      white-space: nowrap;
    }
  }

  & .teams-notifications__setup {
    display: flex;
    align-items: center;

    &__link {
      @include regular-md;

      text-decoration: underline;
      padding-bottom: var(--spacing-xxs);
      line-height: var(--line-height-sm);
    }

    &__icon::before {
      @include regular-lg();

      width: var(--spacing-md);
      vertical-align: baseline;
    }
  }
}
