.cmp-button--disabled {
  opacity: 0.6;
  cursor: default;
}

.cmp-button--disabled:hover,
.cmp-button--disabled:focus {
  background-color: var(--color-primary-500);
}

.cmp-comments .cmp-button {
  padding: var(--spacing-xs) var(--spacing-md);
  height: var(--spacing-xxl);
}
