/**
 * Logout -Component
 */

.cmp-logout {
  text-align: center;
}

.cmp-logout__title {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  text-align: center;
  margin-bottom: var(--spacing-lg);
  margin-top: 0;
}

@media #{$mq-mobile} {
  .cmp-logout__title {
    @include regular-3xl();
  }
}
