:root {
  --teaser-horizontal-spacing: var(--spacing-lg);
  --teaser-vertical-spacing: var(--spacing-lg);

  --teaser-horizontal-spacing-md: var(--spacing-md);
  --teaser-vertical-spacing-md: var(--spacing-md);

  --teaser-horizontal-spacing-sm: var(--spacing-xs);
  --teaser-vertical-spacing-sm: var(--spacing-md);

  --teaser-horizontal-half-gap: calc(var(--teaser-horizontal-spacing) / 2);
  --teaser-horizontal-half-gap-sm: calc(var(--teaser-horizontal-spacing-sm) / 2);
  --teaser-horizontal-half-gap-md: calc(var(--teaser-horizontal-spacing-md) / 2);
}
