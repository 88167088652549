@include remove-grid-content('.teaser');

.cmp-teaser {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  position: relative;
}

.cmp-teaser .cmp-teaser__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.cmp-teaser:hover {
  box-shadow: var(--box-shadow-md);
}

.cmp-teaser__image .cmp-image {
  position: relative;
}

.cmp-teaser .image-wrapper {
  height: 100%;
}

.cmp-teaser__image .cmp-image__title {
  display: none;
}

.cmp-teaser__image .cmp-image:not(.cmp-image--responsive)::before {
  content: '';
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio (divide 9 by 16 = 0.5625) */
  width: 100%;
}

.cmp-teaser__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.cmp-teaser .cmp-image__image,
.cmp-teaser .page-teaser__image,
.cmp-teaser__image img {
  width: 100%;
}

.cmp-teasercontainer--image-hidden .cmp-teaser__image {
  display: none;
}

.cmp-teaser__content-link {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cmp-teaser__label {
  @include regular-md();

  background: var(--color-gray-700);
  color: white;
  padding: var(--spacing-xxs) var(--spacing-sm);
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.cmp-teaser__content {
  padding: var(--spacing-md);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cmp-teaser__action-link::before {
  padding-bottom: var(--spacing-md);
}

.cmp-teaser__content .cmp-teaser__action-link .icon-link {
  display: none;
}

.cmp-teaser__content.link_external .cmp-teaser__action-link .cmp-button__icon {
  display: none;
}

.cmp-teaser__content.link_external .cmp-teaser__action-link .icon-link {
  display: inline-flex;
}

.cmp-teaser__tag,
.cmp-teaser__meta {
  @include regular-xs();

  text-transform: uppercase;
  color: var(--color-gray-900);
}

/* placeholder when no meta content */
.cmp-teaser__meta {
  min-height: var(--line-height-xs);
}

/* separator between meta fragments */
.cmp-teaser__meta-tag:not(:first-child)::before {
  content: '';
  width: 0;
  height: 100%;
  margin: 0 var(--spacing-xs) 0 var(--spacing-xxs);
  border-left: var(--spacing-xxxs) solid var(--color-gray-300);
}

.cmp-teaser__meta-separator {
  @include regular-xs();

  margin: 0 var(--spacing-xxs);
}

.cmp-teaser__title {
  @include bold-lg();

  margin: var(--spacing-xs) 0 var(--spacing-sm) 0;
  align-items: center;
  display: flex;
  word-break: break-word;
}

.cmp-teaser__title-icon {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: var(--spacing-sm);
  margin-top: 0;
}

.cmp-teaser__title-text {
  @include bold-lg();

  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.cmp-teaser__description {
  @include regular-lg();

  overflow-wrap: break-word;
  hyphens: auto;
}

.cmp-teaser__action-container {
  padding: var(--spacing-sm) 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cmp-teaser__action-link {
  @include regular-md();

  color: var(--color-primary-500);
  display: inline-flex;
  align-self: flex-start;
  margin: 0;
  z-index: 3;

  &:hover {
    color: var(--color-primary-700);
  }

  &:active {
    color: var(--color-primary-800);
  }
}

/** Teaser Aspect Ratio for Standard Teaser **/
.cmp-teaser .cmp-responsive-image {
  position: relative;
  height: 100%;
}

.cmp-teaser .cmp-responsive-image::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.cmp-teasercontainer--pictogram .teaser .cmp-responsive-image::before {
  padding-top: 100%;
}

.cmp-teaser .cmp-responsive-image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/** teaser sizing in edit mode **/
.cq-placeholder.cmp-teaser {
  height: 100%;
}
