.c-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.c-pagination__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.c-pagination__link {
  display: block;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  height: 100%;
}

.c-pagination__truncate,
.c-pagination__prev,
.c-pagination__next,
.c-pagination__item {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
}

.c-pagination__item:hover {
  color: var(--color-primary-500);
}

.is-active.c-pagination__item {
  color: var(--color-primary-500);
  border-bottom: 0.125rem solid var(--color-primary-500);
}

.c-pagination__prev .c-pagination__link::before,
.c-pagination__next .c-pagination__link::before {
  content: '';
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: 50%;
  top: 50%;
  display: block;
}

.c-pagination__prev .c-pagination__link::before {
  border-bottom: 0.125rem solid black;
  border-left: 0.125rem solid black;
  transform: rotate(45deg) translate(-0.375rem, -0.125rem);
}

.c-pagination__next .c-pagination__link::before {
  border-top: 0.125rem solid black;
  border-right: 0.125rem solid black;
  transform: rotate(45deg) translate(-0.625rem, 0.125rem);
}

.is-disabled.c-pagination__prev .c-pagination__link,
.is-disabled.c-pagination__next .c-pagination__link {
  opacity: 0.5;
  cursor: initial;
}
