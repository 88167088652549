.c-mapapp__loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-white-transparent);
  z-index: 1;
  display: none;
}

.c-mapapp__loading-overlay::before {
  content: '';
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: absolute;
  display: block;
  border: 0.25rem solid transparent;
  border-left-color: var(--color-gray-700);
  animation-name: spin;
  animation-duration: 555ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.is-active.c-mapapp__loading-overlay {
  display: block;
}
