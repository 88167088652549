@include remove-grid-content('.findjobs');

.cmp-job-details {
  display: flex;
  flex-wrap: wrap;

  .sharing {
    width: 100%;
  }

  .cmp-sharing {
    width: 100%;

    @media (--breakpoint-down-md) {
      width: auto;
    }

    &__title {
      display: flex;
      justify-content: flex-end;
    }

    &__links {
      justify-content: flex-end;

      a:last-child {
        width: 1.5rem;

        .cmp-sharing__icon.cmp-button__icon {
          margin-right: 0;
        }
      }
    }

    &__link {
      .cmp-button__icon {
        animation: link-icon-hover-reverse 0.15s ease-in-out 0s 1 forwards;
      }

      &:hover .cmp-button__icon {
        animation: link-icon-hover 0.15s ease-in-out 0s 1 forwards;
      }
    }
  }

  @media (--breakpoint-up-md) {
    column-gap: 3%;
  }

  &__title {
    flex: 1 1 100%;
    margin-bottom: var(--spacing-xl);
  }

  &__subtitle {
    font-size: 1.625rem;
    line-height: 2.25rem;

    @media (--breakpoint-down-md) {
      text-align: center;
    }
  }

  &__description {
    flex: 1 1 63%;
    margin-bottom: 0;

    @media (--breakpoint-down-md) {
      order: 3;
    }

    @media (--breakpoint-header-down-md) {
      margin-bottom: var(--spacing-xxxl);
    }
  }

  &__description-block {
    ul {
      margin-left: var(--spacing-xs);
    }
  }

  &__description-block:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }

  &__list {
    padding-left: var(--spacing-lg);
  }

  &__table {
    font-size: var(--spacing-md);
    line-height: var(--spacing-lg);
    border: var(--spacing-xxxs) solid transparent;
    flex: 1 1 33%;
    width: 100%;

    .cmp-text__table--row {
      .cmp-text__table--heading,
      .cmp-text__table--cell:first-child {
        @include bold-lg();

        background-color: var(--color-gray-50);
      }
    }

    @media (--breakpoint-down-md) {
      margin: 0 0 var(--spacing-xl) 0;
      order: 2;
      flex: 1 1 100%;

      .cmp-text table {
        min-width: toRem(350px);
      }
    }
  }

  .cmp-button--primary-positive,
  .cmp-button--primary-positive .cmp-button {
    @media (--breakpoint-down-sm) {
      width: 100%;
    }
  }

  @media (--breakpoint-down-md) {
    .cmp-job-details {
      display: flex;
      flex-direction: column;
    }

    .cmp-job-details__description {
      order: 1;
    }

    .cmp-job-details__table {
      order: 3;
    }
  }
}
