.cmp-modal-container--content-loaded {
  overflow: hidden;
}

.cmp-modal-container--content-not-loaded {
  display: none;
}

.cmp-modal-container-content__header {
  padding: 0 var(--spacing-xxxl);
  margin-bottom: var(--spacing-xl);

  .cmp-modal-container-content__title {
    color: var(--color-gray-900);
    font-size: var(--font-size-h3);
    line-height: var(--sm-line-height-h2);
  }
}

// Iframe specific class
.overlaypage {
  padding: 0 var(--spacing-xxxl);

  .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
    width: 100%;
    margin-left: 0;
  }

  .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3 {
    width: 100%;
    margin-left: 0;
  }

  @media (--breakpoint-up-sm) {
    .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3 {
      width: 50%;
    }
  }
}

@mixin modal-full-width-height {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.cmp-modal-container-content {
  @include modal-full-width-height;

  display: flex;
  flex-direction: column;
  padding: var(--spacing-xxxl) 0;
  border: none;
  background: var(--color-white);
  // Higher z-index from sticky button
  z-index: 10;

  &--wrapper {
    height: 100%;
  }

  &--iframe {
    position: relative;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &--small {
    @media (--breakpoint-up-sm) {
      height: 31.25rem;
      width: 29.75rem;
    }

    @media (--breakpoint-up-md) {
      height: 31.25rem;
      width: 24rem;
    }

    @media (--breakpoint-up-lg) {
      height: 31.25rem;
      width: 32rem;
    }

    @media (--breakpoint-down-md) and (orientation: landscape) {
      @include modal-full-width-height;
    }
  }

  &--medium {
    @media (--breakpoint-up-sm) {
      height: 52.5rem;
      width: 39.875rem;
    }

    @media (--breakpoint-up-md) {
      height: 52.5rem;
      width: 36.75rem;
    }

    @media (--breakpoint-up-lg) {
      height: 52.5rem;
      width: 49rem;
    }

    @media (--breakpoint-down-md) and (orientation: landscape) {
      @include modal-full-width-height;
    }
  }

  &--large {
    @media (--breakpoint-up-sm) {
      height: 56.25rem;
      width: 50.125rem;
    }

    @media (--breakpoint-up-md) {
      height: 56.25rem;
      width: 62.25rem;
    }

    @media (--breakpoint-up-lg) {
      height: 56.25rem;
      width: 66rem;
    }

    @media (--breakpoint-down-md) and (orientation: landscape) {
      @include modal-full-width-height;
    }
  }

  &::backdrop {
    border: none;
    background: rgb(0 0 0 / 60%);
  }

  &__close-button {
    position: absolute;
    height: 3rem;
    width: 3rem;
    top: 0;
    right: 0;
    background: rgb(0 0 0 / 60%);
    color: var(--color-white);

    &::after {
      content: var(--icon-close);
      font-family: var(--icon-font);
      font-size: var(--font-size-icon-lg);
    }
  }
}
