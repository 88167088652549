:root {
  --specialevent-image-height: 8rem;
  --specialevent-image-margin: 11rem;
  --specialevent-image-height-tablet: 7rem;
  --specialevent-image-margin-tablet: 10.5rem;
}

.cmp-specialevent-speakers {
  align-self: center;
  display: flex;
  flex-direction: row;
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
  flex-wrap: wrap;
  justify-content: space-between;
  flex-basis: 40%;

  &--slider {
    display: none;
  }

  &-author {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & .cmp-container {
      display: flex;
      flex-wrap: wrap;
    }

    & .speaker {
      width: 28%;
      margin-right: var(--spacing-md);
    }

    & .cmp-specialevent-speaker {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  @media only screen and (--breakpoint-down-sm) {
    flex-basis: 100%;
    position: relative;
    align-self: center;
    padding-top: var(--spacing-xxxl);
    padding-bottom: 0;
    flex-wrap: nowrap;
    height: 0;
    width: 100%;
    margin-bottom: var(--spacing-xxl);

    &--slider {
      display: block;
    }
  }
}

.cmp-specialevent-speaker {
  margin-bottom: var(--spacing-xxl);
  align-self: auto;
  text-align: center;
  width: 50%;

  &__image {
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    border: var(--spacing-xxxs) solid var(--color-gray-50);

    & .image-wrapper {
      position: relative;
      height: var(--specialevent-image-height);
      width: var(--specialevent-image-height);
      margin-bottom: 0;

      @media only screen and (--breakpoint-down-lg) {
        position: relative;
        height: var(--specialevent-image-height-tablet);
        width: var(--specialevent-image-height-tablet);
        margin-bottom: 0;
      }
    }
  }

  &__name {
    @include bold-lg;

    margin-bottom: var(--spacing-lg);
  }

  &__title {
    @include regular-lg;

    max-width: 80%;
    margin-left: 10%;
  }

  @media only screen and (--breakpoint-down-sm) {
    &__image .image-wrapper {
      position: relative;
      margin-bottom: 0;
    }
  }

  @media only screen and (--breakpoint-down-sm) {
    width: 100%;
    margin-bottom: 0;
    align-self: center;
    flex: 1 0 100%;

    &__image .image-wrapper {
      position: relative;
      margin-bottom: 0;
    }

    &__title {
      max-width: 100%;
      margin-left: 0;
    }
  }
}

.keySpeaker {
  .cmp-specialevent-speaker {
    &__image {
      border: 3px solid transparent;
      background-image: linear-gradient(120deg, #eb0023 0%, #760012 94.5%);
    }
  }
}

.specialevent--fullwidth {
  &-plain .cmp-specialevent-speaker:nth-child(odd) {
    margin-left: var(--specialevent-image-margin);
  }

  @media only screen and (--breakpoint-down-lg) {
    &-gradient .cmp-specialevent-speaker:nth-child(even) {
      margin-left: var(--specialevent-image-margin-tablet);
    }

    &-plain .cmp-specialevent-speaker:nth-child(odd) {
      margin-left: var(--specialevent-image-margin-tablet);
    }
  }

  @media only screen and (--breakpoint-down-sm) {
    &-gradient .cmp-specialevent-speaker:nth-child(even) {
      margin-left: 0;
    }

    &-plain .cmp-specialevent-speaker:nth-child(odd) {
      margin-left: 0;
    }
  }
}
