@import '../common.scss';

@mixin tooltip {
  position: absolute;
  width: var(--spacing-10xl);
  height: auto;
  padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md);
  background-color: var(--color-white);
  will-change: filter;
  filter: drop-shadow(0 1px 3px rgb(0 0 0 / 40%));
  z-index: 1;
  visibility: hidden;
}

@mixin tooltip-triangle {
  content: '';
  position: absolute;
  border-bottom: var(--spacing-sm) solid var(--color-white);
  border-left: var(--spacing-md) solid transparent;
  border-right: var(--spacing-md) solid transparent;
  z-index: 2;
}

.cmp-abbreviation {
  position: relative;
  text-decoration: underline dotted var(--color-primary-700);
  cursor: default;

  &[title] {
    @include regular-lg;

    cursor: default;
  }

  &-tooltip__text {
    @include bold-md;
    // to prevent italic style from rich text editor
    font-style: normal;
    display: flex;
  }

  &-tooltip__value {
    display: flex;
  }

  &-tooltip--is-below-center {
    @include tooltip;

    left: 50%;
    top: calc((var(--spacing-xl)) + (var(--spacing-xxs)));
    transform: translateX(-50%);

    &::after {
      @include tooltip-triangle;

      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
    }
  }

  &-tooltip--is-below-left {
    @include tooltip;

    left: calc(var(--spacing-10xl) / 2);
    top: calc((var(--spacing-xl)) + (var(--spacing-xxs)));
    transform: translateX(-50%);

    &::after {
      @include tooltip-triangle;

      left: 0;
      bottom: 100%;
    }
  }

  &-tooltip--is-below-right {
    @include tooltip;

    right: calc(var(--spacing-10xl) / -2);
    top: calc((var(--spacing-xl)) + (var(--spacing-xxs)));
    transform: translateX(-50%);

    &::after {
      @include tooltip-triangle;

      right: 0;
      bottom: 100%;
    }
  }

  &-tooltip--is-above-center {
    @include tooltip;

    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: var(--spacing-md);

    &::after {
      @include tooltip-triangle;

      left: 50%;
      bottom: calc(var(--spacing-sm) * (-1));
      transform: translateX(-50%) rotate(180deg);
    }
  }

  &-tooltip--is-above-left {
    @include tooltip;

    bottom: 100%;
    left: calc(var(--spacing-10xl) / 2);
    transform: translateX(-50%);
    margin-bottom: var(--spacing-md);

    &::after {
      @include tooltip-triangle;

      bottom: calc(var(--spacing-sm) * (-1));
      transform: rotate(180deg);
      left: 0;
    }
  }

  &-tooltip--is-above-right {
    @include tooltip;

    bottom: 100%;
    right: calc(var(--spacing-10xl) / -2);
    transform: translateX(-50%);
    margin-bottom: var(--spacing-md);

    &::after {
      @include tooltip-triangle;

      bottom: calc(var(--spacing-sm) * (-1));
      transform: rotate(180deg);
      right: 0;
    }
  }

  &-tooltip--is-visible {
    visibility: visible;
  }
}

.cmp-tooltip--fixed {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: fit-content;
}
