@import '../../site/styles/settings/functions';

.cmp-specialevent-stage {
  position: relative; /* create a new stacking context */
  z-index: 1;
  background: var(--color-white);
  overflow: hidden;
  margin-bottom: var(--spacing-xl);

  &__image-not-configured {
    height: toRem(362px);
    width: 100%;
    background-color: white;
  }

  &__image {
    width: 100%;
    overflow: hidden;

    img {
      height: toRem(362px);
      overflow: hidden;
    }
  }

  &__logo {
    position: absolute;
    top: toRem(21px);
    right: toRem(24px);
    width: 11.5rem;
    height: 5rem;
    z-index: 20;
  }

  &__content {
    clip-path: polygon(0 0, 100% 133px, 100% 100%, 0% 100%);
    margin-top: toRem(-133px);
    padding: toRem(133px) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
    z-index: 20;
    background-color: var(--color-primary-500);
    shape-outside: polygon(0 0, 100% 133px, 100% 100%, 0% 100%);

    & > *:last-child {
      margin-bottom: 0;
    }

    &-title {
      @include bold-4xl();

      color: var(--color-white);

      &--large {
        @include bold-5xl();
      }
    }

    & &-tagline {
      @include bold-xl();

      color: var(--color-white);
      margin-bottom: var(--spacing-lg);
    }

    &-button {
      @include bold-lg();

      background-color: var(--color-white);
      color: var(--color-primary-500);
      padding: var(--spacing-xs) toRem(20px);
      border: none;
    }
  }

  &__details {
    @include bold-lg();

    padding: var(--spacing-md) var(--spacing-lg);

    &-date {
      align-self: start;
    }
  }
}

.cmp-specialevent-stage.u-full-width {
  margin-bottom: var(--spacing-xl);
}

@media (--breakpoint-up-md) {
  .cmp-specialevent-stage {
    &__image-not-configured {
      height: toRem(601px);
      width: 100%;
      background-color: white;
    }

    &__image {
      overflow: hidden;
      padding-bottom: var(--spacing-4xl);

      img {
        height: toRem(601px);
        overflow: hidden;
      }
    }

    &__image:has(~ .cmp-specialevent-stage__details) {
      padding-bottom: 0;
    }

    &__logo {
      top: toRem(45px);
      right: toRem(48px);
      width: 11.5rem;
      height: 5rem;
      z-index: 20;
    }

    &__content-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      height: toRem(600px);
      max-width: toRem(980px);
      float: right;
      clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
      background-color: transparent;
      width: 75%;
    }

    &__content {
      background-color: var(--color-primary-500);
      clip-path: none;
      margin-top: 0;
      padding: var(--spacing-4xl);
      z-index: 10;
      position: absolute;
      left: 0;
      bottom: toRem(16px);
      top: auto;
      overflow: hidden;
      width: 100%;

      &-shape-outside {
        float: right;
        width: 50%;
        min-height: toRem(170px);
        max-height: toRem(280px);
        shape-outside: polygon(49.56% -16px, 3.06% 101.71%, 71.55% 99.28%);
      }

      &-title {
        @include bold-6xl();

        &--large {
          @include bold-7xl();
        }
      }

      & &-tagline {
        @include regular-4xl();

        max-width: 50%;
        margin-bottom: var(--spacing-xl);
      }
    }

    &__details {
      @include regular-2xl();

      padding: var(--spacing-md) 0;
    }
  }
}

/*
From 960px VW up until 1307px the.cmp-specialevent-stage is growing until it gets 980px as maw-width.
From 1308px VW .cmp-specialevent-stage is 980px and from there we have static margin-left for the cmp-specialevent-stage__details
*/
@media (--breakpoint-up-md) and (max-width: 1307px) {
  .cmp-specialevent-stage {
    &__details {
      margin-left: 41%;
    }
  }
}

@media (min-width: 1308px) {
  .cmp-specialevent-stage {
    &__details {
      margin-left: toRem(536px);
    }
  }
}
