.cmp-teasercontainer .cmp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
}

.cmp-teasercontainer .cmp-container > .aem-Grid {
  min-width: 100%;
}

.cmp-teasercontainer.container.responsivegrid.aem-GridColumn.aem-GridColumn--default--12 {
  padding: 0;
}

.cmp-teasercontainer--is-on-last-page {
  margin-bottom: var(--spacing-lg);
}

@media #{$mq-1} {
  .cmp-teasercontainer .cmp-container {
    display: block;
  }
}

.cmp-teasercontainer .teaser,
.cmp-teasercontainer .basicpage {
  display: flex;
  margin: 0 var(--teaser-horizontal-half-gap) var(--teaser-vertical-spacing);
}

@media #{$mq-2} {
  .cmp-teasercontainer .teaser,
  .cmp-teasercontainer .basicpage {
    margin: 0 var(--teaser-horizontal-half-gap-md) var(--teaser-vertical-spacing-md);
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer .teaser,
  .cmp-teasercontainer .basicpage {
    margin: 0 var(--teaser-horizontal-half-gap-sm) var(--teaser-vertical-spacing-sm);
  }
}

.cmp-teasercontainer--1-columns .teaser,
.cmp-teasercontainer--1-columns .basicpage {
  flex-basis: 100%;
}

.cmp-teasercontainer--2-columns .teaser,
.cmp-teasercontainer--2-columns .basicpage {
  flex-basis: calc(50% - var(--teaser-horizontal-spacing));
}

@media #{$mq-2} {
  .cmp-teasercontainer--2-columns .teaser,
  .cmp-teasercontainer--2-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer--2-columns .teaser,
  .cmp-teasercontainer--2-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-teasercontainer--3-columns .teaser,
.cmp-teasercontainer--3-columns .basicpage {
  flex-basis: calc(33.3333% - var(--teaser-horizontal-spacing));
}

@media #{$mq-2} {
  .cmp-teasercontainer--3-columns .teaser,
  .cmp-teasercontainer--3-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer--3-columns .teaser,
  .cmp-teasercontainer--3-columns .basicpage {
    flex-basis: calc(33.3333% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-teasercontainer--4-columns .teaser,
.cmp-teasercontainer--4-columns .basicpage {
  flex-basis: calc(25% - var(--teaser-horizontal-spacing));
}

@media #{$mq-2} {
  .cmp-teasercontainer--4-columns .teaser,
  .cmp-teasercontainer--4-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer--4-columns .teaser,
  .cmp-teasercontainer--4-columns .basicpage {
    flex-basis: calc(25% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-teasercontainer--5-columns .teaser,
.cmp-teasercontainer--5-columns .basicpage {
  flex-basis: calc(20% - var(--teaser-horizontal-spacing));
}

@media #{$mq-2} {
  .cmp-teasercontainer--5-columns .teaser,
  .cmp-teasercontainer--5-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer--5-columns .teaser,
  .cmp-teasercontainer--5-columns .basicpage {
    flex-basis: calc(20% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-teasercontainer--6-columns .teaser,
.cmp-teasercontainer--6-columns .basicpage {
  flex-basis: calc(16.666% - var(--teaser-horizontal-spacing));
}

@media #{$mq-2} {
  .cmp-teasercontainer--6-columns .teaser,
  .cmp-teasercontainer--6-columns .basicpage {
    flex-basis: calc(50% - var(--teaser-horizontal-spacing-md));
  }
}

@media #{$mq-1} {
  .cmp-teasercontainer--6-columns .teaser,
  .cmp-teasercontainer--6-columns .basicpage {
    flex-basis: calc(16.666% - var(--teaser-horizontal-spacing-sm));
  }
}

.cmp-teasercontainer .teaser.cmp-teaser--hidden,
.cmp-teasercontainer .basicpage.cmp-teaser--hidden {
  display: none;
}

.cmp-teasercontainer-wrapper {
  display: flex;
  flex-direction: column;
}

.cmp-teasercontainer__button {
  @include button-secondary;

  margin-bottom: var(--spacing-lg);
  display: flex;
  width: fit-content;
  align-self: center;

  .icon-keyboard_arrow_down {
    @include transition-transform;
  }

  &:last-child {
    margin-bottom: var(--spacing-xxxl);
  }

  &:hover {
    .icon-keyboard_arrow_down {
      @include link-icon-down;
    }
  }

  &--hidden {
    display: none;
  }
}

/*
* fullwidth grey stylepolicy
*/
.cmp-teasercontainer--fullwidth-grey {
  margin-bottom: var(--spacing-4xl);
  padding-top: var(--spacing-xl);
  position: relative;
}

.cmp-teasercontainer--fullwidth-grey::before {
  content: '';
  position: absolute;
  display: block;
  z-index: -1;
  height: 100%;
  margin-top: calc(-1 * var(--spacing-xl));
  background: var(--color-gray-50);
  left: -6.75rem;
  width: calc(100% + (6.75rem * 2));
}

@media #{$mq-4} {
  .cmp-teasercontainer--fullwidth-grey::before {
    left: -1.75rem;
    width: calc(100% + (1.75rem * 2));
  }
}

@media #{$mq-3} {
  .cmp-teasercontainer--fullwidth-grey::before {
    left: -1.125rem;
    width: calc(100% + (1.125rem * 2));
  }
}

@media #{$mq-mobile} {
  .cmp-teasercontainer--fullwidth-grey::before {
    left: calc(-1 * (var(--spacing-lg) - var(--spacing-sm)));
    width: calc(100% + ((var(--spacing-lg) - var(--spacing-sm)) * 2));
  }
}

@media #{$mq-5} {
  .cmp-teasercontainer--fullwidth-grey::before {
    @include fullWidthBackgroundColor($position: relative);
  }
}
