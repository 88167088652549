@media #{$mq-mobile} {
  .cmp-navigation {
    padding: 0 var(--spacing-md);

    &__column {
      display: flex;
      flex-direction: column;
    }
  }

  .cmp-navigation__link {
    display: none;
  }

  .cmp-header.cmp-header-rebrand .cmp-navigation__link {
    display: flex;
    background: var(--color-gray-50);
    padding: 0.625rem;
    font-weight: bold;
    box-shadow: var(--box-shadow);
  }

  .cmp-navigation__title {
    @include bold-xl();

    margin: 0 var(--spacing-md);
    padding-bottom: var(--spacing-md);
    color: var(--color-black);
    border-bottom: var(--spacing-xxxs) solid var(--color-gray-50);
  }

  .cmp-navigation > .cmp-navigation__title {
    margin: 0;
    padding: var(--spacing-md);
  }

  .cmp-navigation--selected > .cmp-navigation__title {
    display: none;
  }

  .cmp-navigation__back-button {
    @include bold-md();

    color: var(--color-primary-500);
    display: none;
    padding: var(--spacing-md) 0;
    cursor: pointer;
    align-items: center;
  }

  .cmp-navigation__back-button-text br {
    display: none;
  }

  .cmp-navigation--selected .cmp-navigation__back-button {
    display: flex;
  }

  .cmp-navigation__back-button .cmp-navigation__back-button-icon {
    color: var(--color-black);
    margin-left: calc(-1 * var(--spacing-xs));
  }

  .cmp-navigation__group {
    flex-direction: column;
  }

  .cmp-navigation__item {
    display: flex;
    border-top: var(--spacing-xxxs) solid var(--color-gray-100);
    border-left: 0.125rem solid transparent;
    position: relative;
    padding: var(--spacing-md);

    &__title-manual {
      pointer-events: none;
    }
  }

  .cmp-navigation__overview-item.cmp-navigation__item {
    border-top: 0;
  }

  .cmp-navigation__item.cmp-navigation__item--active {
    border-left-color: var(--color-primary-500);
  }

  /* stylelint-disable-next-line */
  li.cmp-navigation__item::after,
  .cmp-navigation__item--has-children::after {
    content: var(--icon-keyboard_arrow_right);
    font-family: 'custom_materialicons', sans-serif;
    position: absolute;
    right: var(--spacing-xs);
    font-size: var(--spacing-lg);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .cmp-navigation__item--level-0 .cmp-navigation__item-link {
    color: var(--color-black);
  }

  .cmp-navigation--selected .cmp-navigation__group,
  .cmp-navigation--selected .cmp-navigation__item,
  .cmp-navigation--selected .cmp-navigation__item-link {
    display: none;
  }

  .cmp-navigation--selected .cmp-navigation__item.cmp-navigation__item--child-of-current-item {
    display: block;
  }

  .cmp-navigation--selected .cmp-navigation__item.cmp-navigation__item--child-of-current-item.cmp-navigation__overview-item--is-hidden {
    display: none;
  }

  .cmp-navigation .cmp-navigation__item-link:only-child {
    display: inline-block;
    position: relative;
    background: var(--color-gray-50);
    z-index: 2;
    width: 100%;
  }

  .cmp-navigation__item--level-0 > .cmp-navigation__item-link:only-child {
    width: 100%;
  }

  .cmp-navigation__item--has-children .cmp-navigation__item-link:only-child {
    width: inherit;
  }

  .cmp-navigation--selected .cmp-navigation__group.cmp-navigation__group--selected-parent,
  .cmp-navigation--selected .cmp-navigation__group--selected-child {
    display: flex;
  }

  .cmp-navigation--selected .cmp-navigation__item.cmp-navigation__item--selected-parent,
  .cmp-navigation--selected .cmp-navigation__item.cmp-navigation__item--selected {
    display: list-item;
    padding: 0;
    border: none;
  }

  .cmp-navigation--selected .cmp-navigation__item.cmp-navigation__item--selected-parent::after,
  .cmp-navigation--selected .cmp-navigation__item.cmp-navigation__item--selected::after {
    content: '';
  }

  .cmp-navigation--selected .cmp-navigation__item--selected > .cmp-navigation__item-link {
    display: block;
    margin: 0;
  }

  .cmp-navigation__group--selected-child > .cmp-navigation__item > .cmp-navigation__item-link {
    display: inline;
  }

  .cmp-navigation__group--selected-child > .cmp-navigation__item > .cmp-navigation__item-link:only-child {
    display: block;
  }

  .cmp-navigation--selected .cmp-navigation__group--selected-child > .cmp-navigation__item {
    display: list-item;
  }

  /* stylelint-disable */
  /* disabling due to false error about indentation - stylelint/other rules to be adjusted */
  .cmp-navigation
    .cmp-navigation__item--level-0.cmp-countryselector-mobile-menu__li.cmp-navigation__item--selected
    .cmp-countryselector_mobile__region-title {
    display: block;
    margin-top: var(--spacing-md);
  }

  /* stylelint-enable */
}
