.mosaic-container {
  display: grid;
  grid-template-columns: 3fr;
}

.mosaic-container.has-side-content {
  grid-template-columns: 1fr 2fr;
}

.mosaic-container .side-content {
  margin-right: 1.5rem;
}

.mosaic-container .tiles__container {
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 1.5rem;
  justify-content: center;
}

.mosaic-container .tiles__container .mosaic-rectangle,
.mosaic-container .tiles__container .mosaic-square {
  overflow: hidden;
}

.mosaic-container--large .tiles__container {
  grid-template-columns: repeat(3, minmax(0, 17.5rem));
}

.mosaic-container--medium .tiles__container {
  grid-template-columns: repeat(2, minmax(0, 17.5rem));
}

.tiles__container .mosaic-square {
  aspect-ratio: 1;
}

.tiles__container .mosaic-square,
.tiles__container .mosaic-rectangle {
  color: var(--color-white);
  position: relative;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.tiles__container .cmp-responsive-image.replaced img,
.tiles__container .cmp-responsive-bg-image.replaced img {
  top: 0;
  left: 0;
  z-index: -1;
}

.tiles__container .mosaic-square .cmp-responsive-image img,
.tiles__container .mosaic-rectangle .cmp-responsive-image img {
  width: 100%;
  height: 100%;
}

.tiles__container .mosaic-square .cmp-video-wrapper,
.tiles__container .mosaic-rectangle .cmp-video-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.tiles__container .mosaic-square .cmp-video-wrapper video,
.tiles__container .mosaic-rectangle .cmp-video-wrapper video {
  height: 100%;
  object-fit: cover;
}

.tiles__container .mosaic-rectangle {
  grid-column: span 2;
  aspect-ratio: 2;
}

.tiles__container .mosaic-square .cmp-bignumbers,
.tiles__container .mosaic-rectangle .cmp-bignumbers {
  text-align: left;
}

.tiles__container .mosaic-square .cmp-bignumbers__number,
.tiles__container .mosaic-rectangle .cmp-bignumbers__number {
  @include bold-6xl();

  color: var(--color-white);
}

/*
  Modifier variations
*/

.mosaic-container .mosaic--red-tile {
  background: var(--color-primary-500);
}

.mosaic--gradient-tile {
  background: linear-gradient(to top right, var(--specialevent-color-medium), var(--specialevent-color-light));
  color: var(--color-gray-900);
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.mosaic-square .mosaic--gradient-tile::before,
.mosaic-rectangle .mosaic--gradient-tile::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 6.25rem;
  z-index: -1;
  transform-origin: right;
  transform: skew(-40deg) translate(100%);
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, var(--specialevent-color-dark), var(--specialevent-color-light));
}

.tiles__container .mosaic-square .mosaic--textonimagedark-tile,
.tiles__container .mosaic-square .mosaic--gradient-tile .cmp-bignumbers__number,
.tiles__container .mosaic-square .mosaic--textonimagedark-tile .cmp-bignumbers__number,
.tiles__container .mosaic-square .mosaic--textonvideodark-tile .cmp-bignumbers__number,
.tiles__container .mosaic-square .mosaic--textonvideodark-tile .cmp-bignumbers,
.tiles__container .mosaic-rectangle .mosaic--textonimagedark-tile,
.tiles__container .mosaic-rectangle .mosaic--gradient-tile .cmp-bignumbers__number,
.tiles__container .mosaic-rectangle .mosaic--textonimagedark-tile .cmp-bignumbers__number,
.tiles__container .mosaic-rectangle .mosaic--textonvideodark-tile .cmp-bignumbers__number,
.tiles__container .mosaic-rectangle .mosaic--textonvideodark-tile .cmp-bignumbers {
  color: var(--color-gray-900);
}

.tiles__container .mosaic-square .mosaic--textonimagelight-tile .cmp-bignumbers__number,
.tiles__container .mosaic-rectangle .mosaic--textonimagelight-tile .cmp-bignumbers__number {
  color: var(--color-white);
}

.mosaic-container.mosaic-container--medium .tiles__container .cmp-bignumbers,
.mosaic-container.mosaic-container--large .tiles__container .cmp-bignumbers {
  margin-bottom: 0;
}

/* Size of tiles */
.tiles__container .mosaic-square .mosaic--red-tile,
.tiles__container .mosaic-square .mosaic--gradient-tile,
.tiles__container .mosaic-square .mosaic--textonimagedark-tile,
.tiles__container .mosaic-square .mosaic--textonimagelight-tile,
.tiles__container .mosaic-square .mosaic--textonvideolight-tile,
.tiles__container .mosaic-square .mosaic--textonvideodark-tile,
.tiles__container .mosaic-rectangle .mosaic--red-tile,
.tiles__container .mosaic-rectangle .mosaic--gradient-tile,
.tiles__container .mosaic-rectangle .mosaic--textonimagedark-tile,
.tiles__container .mosaic-rectangle .mosaic--textonimagelight-tile,
.tiles__container .mosaic-rectangle .mosaic--textonvideolight-tile,
.tiles__container .mosaic-rectangle .mosaic--textonvideodark-tile {
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  overflow: hidden;
}

.mosaic-container.mosaic-container--medium .cmp-bignumbers figcaption,
.mosaic-container.mosaic-container--large .cmp-bignumbers figcaption {
  @include regular-3xl();
}

@media #{$mq-mobile} {
  .mosaic-container.has-side-content {
    grid-template-columns: 1fr;
  }

  .mosaic-container .side-content {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  .tiles__container .cmp-bignumbers {
    position: relative;
    z-index: 3;
  }

  .tiles__container .mosaic--video-disabled-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

@media #{$mq-1} {
  .tiles__container,
  .mosaic-container.mosaic-container--medium .tiles__container,
  .mosaic-container.mosaic-container--large .tiles__container {
    grid-template-columns: none;
    grid-template-rows: none;
    justify-content: unset;
  }

  .tiles__container .mosaic-square,
  .tiles__container .mosaic-rectangle {
    height: 12.5rem;
    aspect-ratio: unset;
  }

  .tiles__container .mosaic-rectangle {
    grid-column: initial;
  }

  .mosaic-container.mosaic-container--medium .cmp-bignumbers figcaption,
  .mosaic-container.mosaic-container--large .cmp-bignumbers figcaption {
    @include regular-3xl();
  }
}

/* Author mode alignment */

.mosaic-square .tile,
.mosaic-rectangle .tile {
  height: 100%;
}
