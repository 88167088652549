.cmp-media-gallery .splide__arrows {
  display: flex;
}

.cmp-media-gallery .js-gallery-button {
  display: none;
}

.cmp-media-gallery .splide__track {
  padding: 0;
}

.cmp-media-gallery .swiper-controls--visible {
  display: flex;
}

.cmp-media-gallery .image-wrapper {
  cursor: pointer;
}

.media-gallery-mobile__box-shadow {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - var(--spacing-lg));
  width: 10%;
  z-index: 1;
  background-image: linear-gradient(to right, transparent, rgb(0 0 0 / 50%));
}

.media-gallery-mobile__box-shadow-btn {
  height: calc(100% - 4.0625rem);
}

.mobile-gallery__modal {
  display: none;
}

.mobile-gallery__modal--visible {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100%;
  background: var(--color-white);
  overflow-y: scroll;
}

.mobile-gallery-header {
  position: sticky;
  top: 0;
  z-index: 99;
  min-height: 6.1875rem;
  padding: 2.0625rem 1.5625rem 0.375rem;
  background-color: var(--color-gray-50);
}

.mobile-gallery-header__close-btn {
  text-align: right;
}

.mobile-gallery-header__title {
  color: var(--color-gray-900);
  font-weight: normal;
  font-size: var(--font-size-h2);
  text-align: center;
  line-height: var(--line-height-h3);
  word-break: break-word;
}

.mobile-gallery__modal .mobile-gallery-header__images .image {
  position: relative;
}

.mobile-gallery__modal .mobile-gallery-header__images .image .cmp-media-gallery__fullscreen-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 0.375rem;
  background: var(--color-white);
  color: var(--color-gray-500);
  font-size: 2.25rem; /* 36 px */
  text-align: center;
  z-index: 2;
  pointer-events: initial;
}

.mobile-gallery-header__text {
  text-align: center;
  padding: var(--spacing-sm);
}

.mobile-gallery-header__images {
  display: grid;
  grid-column-gap: 0.375rem;
  grid-row-gap: 0.375rem;
  padding: 0 0.375rem;
}

@media (--breakpoint-down-md) and (orientation: landscape) {
  .media-gallery__mobile .splide__slide {
    width: 45%;
  }

  .mobile-gallery-header__images {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .mobile-gallery-header__close-btn {
    position: absolute;
    right: 3.125rem;
  }

  .mobile-gallery-header__title {
    max-width: 80%;
    margin: auto;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-media-gallery .js-gallery-button {
    display: block;
  }

  .cmp-media-gallery .splide__arrows {
    display: none;
  }

  .media-gallery-mobile__box-shadow {
    display: block;
  }

  .mobile-gallery-header__images {
    grid-template-columns: 1fr 1fr;
  }

  .media-gallery .splide__track {
    margin-bottom: var(--spacing-lg);
  }

  .media-gallery .splide__slide {
    max-width: 90%;
  }
}
