.cmp-navigation__group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.cmp-navigation__item--level-0 {
  @include regular-lg();

  font-family: var(--font-family);
}

.cmp-navigation__item--level-0 > .cmp-navigation__item-link:not(:only-child) {
  pointer-events: none;
}

.cmp-navigation__item--level-0 .cmp-navigation__group {
  display: none;
}

.cmp-navigation .cmp-navigation__item--level-0.cmp-countryselector-mobile-menu__li .cmp-countryselector_mobile__region-title {
  display: none;
}

.cmp-header--no-meta-nav {
  border-bottom: 1px solid var(--color-gray-100);
}

@media (--breakpoint-up-xl) {
  /* Align menu items only at top level */
  .cmp-header.cmp-header-rebrand .cmp-navigation__group > .cmp-navigation__item--level-0 {
    @include regular-md();

    align-items: center;
    justify-content: center;
  }
}
