.cmp-videoembed {
  padding-top: 56.25%;
  overflow: hidden;
  height: 0;
  position: relative;
  margin-bottom: var(--spacing-xl);
}

/* media text component specific style for the cta */
.media-text-block__container .cmp-videoembed {
  margin-bottom: 0;
}

.cmp-videoembed .absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.cmp-videoembed__poster {
  width: 100%;
}

.cmp-videoembed__poster-overlay {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 50%) 18%, rgb(0 0 0 / 40%) 36%, rgb(0 0 0 / 20%) 100%);
}

.cmp-videoembed__frame {
  width: 100%;
  height: 100%;
}

.cmp-videoembed__action-link.cmp-button {
  box-shadow: var(--box-shadow);
  background: var(--color-primary-500);
  color: var(--color-white);
  border-radius: 50%;
  line-height: 4rem;
  height: 4rem;
  width: 4rem;
}

.cmp-videoembed__action-link.cmp-button .cmp-button__icon {
  font-size: 1.8rem;
  padding-left: 0.3rem;
}

.cmp-videoembed--active .cmp-videoembed__action-link,
.cmp-videoembed--active .cmp-videoembed__poster,
.cmp-videoembed--active .cmp-videoembed__poster-overlay {
  display: none;
}

.cmp-videoembed__action-link:hover,
.cmp-videoembed:hover .cmp-videoembed__action-link {
  box-shadow: var(--box-shadow-md);
}

.cmp-videoembed__action-link .cmp-button__icon::before {
  content: var(--icon-play);
}

.cmp-videoembed__action-link .cmp-button__text {
  margin-right: var(--spacing-sm);
  white-space: pre;
}

.media-text-blocks__container .videoembed {
  flex-basis: 100%;
}
